import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Row, Space, Spin, Switch, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PageLayout from '../../SharedComponents/PageLayout';
import RSTable from '../../SharedComponents/RSTable';
import { DEFAULT_PAGE_SIZE } from '../../Utils/constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showAddNewUser } from '../../store/app-ui.store';
import { getAllUsers, getUserList, getUserListsLoading, updateUserState } from '../../store/users.store';
import { User } from '../../Types/UsersAndCustomer';

const getColumns = (updateStatus: any) => [
  { title: 'Name', dataIndex: 'name', rowKey: 'id' },
  { title: 'Username', dataIndex: 'username', rowKey: 'id' },
  {
    title: 'Email',
    dataIndex: 'email',
    rowKey: 'id',
  },
  { title: 'Telephone', dataIndex: 'tele', rowKey: 'id' },
  { title: 'Address', dataIndex: 'address', rowKey: 'id' },
  {
    title: 'Active/Inactive',
    render: (data: any, record: User) => (
      <Space size="middle">
        <Tooltip title="Active/Inactive Customer" placement="bottomRight">
          <Switch
            checked={record.status === 'active'}
            onChange={(checked) => {
              const status = checked ? 'active' : 'inactive';
              updateStatus(record.username, status);
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];

const UsersManagement = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [pageStart, setPageStart] = useState(0);

  const users = useAppSelector(getUserList);
  const loading = useAppSelector(getUserListsLoading);

  const [filteredUsers, setFilteredUsers] = useState<any>([]);

  const showAddNewUserModal = () => {
    dispatch(showAddNewUser(true));
  };

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    dispatch(getAllUsers({ pageStart, limit: DEFAULT_PAGE_SIZE }));
  }, [pageStart]);

  const filter = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim()) {
      setFilteredUsers(users.filter((d) => String(d.fname).startsWith(e.target.value)));
    } else {
      filteredUsers(users);
    }
  };

  const changePage = (increment: boolean) => {
    if (!increment) {
      setPageStart(pageStart > 0 ? pageStart - DEFAULT_PAGE_SIZE : 0);
    } else {
      setPageStart(pageStart + DEFAULT_PAGE_SIZE);
    }
  };

  const updateStatus = (username: string, status: string) => {
    dispatch(updateUserState({ username, status }));
  };

  return (
    <PageLayout
      title="Users"
      actions={[
        <Button key="add" onClick={showAddNewUserModal}>
          Add
        </Button>,
      ]}
    >
      <Spin spinning={loading} size="large">
        <Row>
          <RSTable
            loading={loading}
            onSearch={filter}
            headerTitle="Users"
            columns={getColumns(updateStatus)}
            dataSource={filteredUsers}
            textSearch={false}
            footer={(pageData) => (
              <Row justify="end">
                <Space size="small">
                  <Button icon={<LeftOutlined />} onClick={() => changePage(false)} />
                  <Button
                    icon={<RightOutlined />}
                    onClick={() => changePage(true)}
                    disabled={pageData.length < DEFAULT_PAGE_SIZE}
                  />
                </Space>
              </Row>
            )}
            rowKey="plateNumber"
          />
        </Row>
      </Spin>
    </PageLayout>
  );
};

export default UsersManagement;
