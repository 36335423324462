import React, { ReactNode } from 'react';
import { ConfigProvider } from 'antd';

type AntdConfigsProvider = {
  children: ReactNode;
};

const AntdConfigsProvider = ({ children }: AntdConfigsProvider): JSX.Element => (
  <ConfigProvider form={{ requiredMark: false }}>{children}</ConfigProvider>
);

export default AntdConfigsProvider;
