import React from 'react';
import { Input, Modal, Form, Row, Col } from 'antd';
import { IdcardOutlined, PhoneOutlined, SafetyCertificateOutlined, UserOutlined } from '@ant-design/icons';
import to from 'await-to-js';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getShowAddNewCustomerModal, showAddNewCustomer } from '../../store/app-ui.store';
import { addNewCustomer } from '../../store/users.store';
import { Customer } from '../../Types/UsersAndCustomer';

const AddNewCustomer = (): JSX.Element => {
  const addNewCustomerModalVisibility = useAppSelector(getShowAddNewCustomerModal);
  const dispatch = useAppDispatch();
  const [formRef] = Form.useForm();

  const handleOk = () => {
    formRef.submit();
  };

  const handleCancel = () => {
    dispatch(showAddNewCustomer(false));
  };

  const handleSubmit = async (values: Customer) => {
    const [err] = await to(dispatch(addNewCustomer(values)));
    if (!err) {
      formRef.resetFields();
    }
  };

  return (
    <Modal
      visible={addNewCustomerModalVisibility}
      onOk={handleOk}
      title="Add New Customer"
      destroyOnClose
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={formRef} name="login-form" onFinish={handleSubmit} initialValues={initValue}>
        <Form.Item label="Username :" name="username" rules={validations.email}>
          <Input placeholder="Username" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item label="Email :" name="email" rules={validations.email}>
          <Input type="email" placeholder="Email" prefix={<UserOutlined />} />
        </Form.Item>
        <Row justify="space-between">
          <Col>
            <Form.Item label="First Name :" name="fname" rules={validations.fname}>
              <Input placeholder="First Name" prefix={<UserOutlined />} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Last Name :" name="lname" rules={validations.lname}>
              <Input placeholder="Last Name" prefix={<UserOutlined />} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Description :" name="description">
          <Input placeholder="description" prefix={<IdcardOutlined />} />
        </Form.Item>
        <Form.Item label="Address 1 :" name="address1" rules={validations.address1}>
          <Input placeholder="Street" prefix={<IdcardOutlined />} />
        </Form.Item>
        <Form.Item label="Address 2 :" name="address2">
          <Input placeholder="City" prefix={<IdcardOutlined />} />
        </Form.Item>

        <Row justify="space-between">
          <Col>
            <Form.Item label="Telephone Number 1 :" name="tele1" rules={validations.tele1}>
              <Input placeholder="Telephone Number 1" prefix={<PhoneOutlined />} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Telephone Number 2 :" name="tele2" rules={validations.tele2}>
              <Input placeholder="Telephone Number 2" prefix={<PhoneOutlined />} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Password :" name="password" rules={validations.password}>
          <Input.Password placeholder="Password" prefix={<SafetyCertificateOutlined />} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const initValue: Customer = {
  username: '',
  email: '',
  password: '',
  fname: '',
  lname: '',
  description: '',
  address1: '',
  address2: '',
  tele1: '',
  tele2: '',
};

const validations = {
  username: [
    { required: true, message: 'Please enter the Username' },
    { min: 5, message: 'Username should be at least 5 chars' },
  ],
  email: [{ required: true, message: 'Please enter the Email' }],
  password: [
    { required: true, message: 'Please enter the Password' },
    { min: 5, message: 'Password should have at least 5 chars' },
  ],
  fname: [{ required: true, message: 'Please enter the First Name' }],
  lname: [{ required: true, message: 'Please enter the Last Name' }],
  address1: [{ required: true, message: 'Please enter the address' }],
  tele1: [
    { required: true, message: 'Please enter the Telephone Number' },
    { pattern: /^([0-9]{1,3}|0)[0-9]{3}( )?[0-9]{7,8}\b/, message: 'Please enter valid phone number' },
  ],
  tele2: [{ pattern: /^([0-9]{1,3}|0)[0-9]{3}( )?[0-9]{7,8}\b/, message: 'Please enter valid phone number' }],
};

export default AddNewCustomer;
