import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';
import { useAppSelector } from '../store/hooks';
import { getSidebarCollapsed } from '../store/app-ui.store';
import ModalsContainer from './Models';

type LayoutProp = {
  children: ReactNode;
  sideMenu: ReactNode;
};

const AppLayout = ({ children, sideMenu }: LayoutProp): JSX.Element => {
  const sidebarCollapsed = useAppSelector(getSidebarCollapsed);

  return (
    <Row className="h-100" wrap={false}>
      <Col flex={sidebarCollapsed ? '60px' : '250px'} className="sidebar-content">
        {sideMenu}
      </Col>
      <Col flex="auto" className="app-content">
        {children}
      </Col>
      <ModalsContainer />
    </Row>
  );
};

export default AppLayout;
