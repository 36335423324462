import React from 'react';
import { Select, SelectProps } from 'antd';

type SearchableSelectorProps = SelectProps<string> & {
  loading: boolean;
  width?: number | string;
  placeholder: string;
  children: any;
};

const SearchableSelector: React.FC<SearchableSelectorProps> = ({
  loading,
  width,
  placeholder,
  value,
  onChange,
  children,
}) => (
  <Select
    loading={loading}
    showSearch
    style={{ width }}
    placeholder={placeholder}
    optionFilterProp="children"
    value={value}
    onChange={onChange}
    filterOption={(input, option: any) => `${option!.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0}
  >
    {children}
  </Select>
);

SearchableSelector.defaultProps = {
  width: undefined,
};

export default SearchableSelector;
