import React from 'react';
import { Select } from 'antd';
import SearchableSelector from './SearchableSelector';
import { useAppSelector } from '../../store/hooks';
import { getMinimalLocations } from '../../store/location.store';

const { Option } = Select;

type Props = {
  loading: boolean;
  value: string;
  onChange: (locationId: string, locationName: string, address: string, location: any) => void;
};

export const LocationSearchById = ({ loading, value, onChange }: Props) => {
  const locations = useAppSelector(getMinimalLocations);

  return (
    <SearchableSelector
      width="100%"
      loading={loading}
      placeholder="Search to Select"
      value={value}
      onChange={(id) => {
        const location = locations.filter(({ location_id: locationId }) => locationId === id)[0]!;
        onChange(location.location_id, location.location_name, location.address, {
          lat: location.latitude,
          lng: location.longitude,
        });
      }}
    >
      {locations.map(({ location_id: locationId }) => (
        <Option key={locationId} value={locationId}>
          {locationId}
        </Option>
      ))}
    </SearchableSelector>
  );
};

export const LocationSearchByName = ({ loading, value, onChange }: Props) => {
  const locations = useAppSelector(getMinimalLocations);
  return (
    <SearchableSelector
      loading={loading}
      placeholder="Search to Select"
      value={value}
      onChange={(id) => {
        const location = locations.filter(({ location_id: locationId }) => locationId === id)[0]!;
        onChange(location.location_id, location.location_name, location.address, {
          lat: location.latitude,
          lng: location.longitude,
        });
      }}
    >
      {locations.map(({ location_name: name, location_id: locationId }) => (
        <Option key={locationId} value={locationId}>
          {name}
        </Option>
      ))}
    </SearchableSelector>
  );
};
