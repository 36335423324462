import React, { useState } from 'react';
import { Button, Input, Table, TableProps } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';

type AntEditingTableProps<T> = TableProps<T> & {
  columns: {
    title?: string;
    dataIndex?: string;
    key: string;
    isEditable?: boolean;
    render?: (value: any, data: { key: string }) => React.ReactElement;
    onValueChange?: (key: string, value: any) => void;
    editingElement?: (value: any, data: any) => JSX.Element;
    editingElementProps?: any;
    width?: number | string;
  }[];
  extraButtons: (key: string, rowData: any) => React.ReactElement | boolean;
  dataSource: any;
  styles: any;
};

const AntEditingTable = ({ columns, dataSource, extraButtons, ...props }: AntEditingTableProps<any>) => {
  const [editingRowKey, setEditingRowKey] = useState(null);

  const finalColumns = columns.map((columnData: any) => ({
    ...columnData,
    render: (value: any, data: any) => {
      if (columnData.isEditable && editingRowKey === data.key) {
        const onChange = (val: any) => {
          if (columnData.onValueChange) {
            columnData.onValueChange(data.key, val);
          }
        };
        if (columnData.editingElement) {
          return columnData.editingElement(value, data);
        }
        return <Input type="text" value={value} onChange={onChange} />;
      }
      if (columnData.render) {
        return columnData.render(value, data);
      }
      return value;
    },
  }));

  finalColumns.push({
    key: 'editor',
    title: '',
    width: 150,
    className: 'editor-btn',
    render: (value: any, rowData: { key: any; readonlyRow: boolean }) => {
      const { key, readonlyRow } = rowData;
      if (readonlyRow) {
        return <div>{extraButtons(key, rowData)}</div>;
      }
      if (key && key === editingRowKey) {
        return (
          <div>
            <Button type="primary" onClick={() => setEditingRowKey(null)} icon={<CheckOutlined />} />
            {extraButtons(key, rowData)}
          </div>
        );
      }
      return (
        <>
          <Button onClick={() => setEditingRowKey(key)} icon={<EditOutlined />} />
          {extraButtons(key, rowData)}
        </>
      );
    },
  });
  return (
    <Table
      className="w-100"
      columns={finalColumns}
      rowClassName={(r) => {
        if (editingRowKey === r.key) {
          return 'editing-row';
        }
        if (r.error) {
          return 'error-row';
        }
        return '';
      }}
      dataSource={dataSource}
      {...props}
    />
  );
};

export default AntEditingTable;
