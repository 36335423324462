import React, { useEffect, useState } from 'react';
import { Button, Col, Empty, Row, Select, Spin, Radio } from 'antd';
import PageLayout from '../../SharedComponents/PageLayout';
import Summery from '../../SharedComponents/AnalyticsCard/Summery';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  downloadAnalyticsData,
  fetchAnalyticsData,
  getRoutesAnalyticsData,
  getRoutesLoading,
} from '../../store/routes.store';
import {
  fetchMinimalUserAccountsForCustomer,
  getUserAccountForCustomers,
  getUserAccountForCustomersLoading,
  getUserType,
} from '../../store/auth.store';
import SearchableSelector from '../../SharedComponents/Forms/SearchableSelector';

const { Option } = Select;

const Analytics = (): JSX.Element => {
  const [selectedUser, setSelectedUser] = useState('all');
  const [frequency, setFrequency] = useState('daily');

  const dispatch = useAppDispatch();
  const data = useAppSelector(getRoutesAnalyticsData);
  const loading = useAppSelector(getRoutesLoading);
  const userType = useAppSelector(getUserType);
  const userNamesLoading = useAppSelector(getUserAccountForCustomersLoading);
  const userNames = useAppSelector(getUserAccountForCustomers);

  const isCustomer = userType === 'customer';
  const isUser = userType === 'user';

  useEffect(() => {
    if (!isUser) {
      dispatch(fetchMinimalUserAccountsForCustomer(null));
    } else {
      dispatch(fetchAnalyticsData({ username: '' }));
    }
  }, [userType]);

  useEffect(() => {
    if (selectedUser) {
      dispatch(fetchAnalyticsData({ username: selectedUser }));
    }
  }, [selectedUser]);

  const downloadAnalytics = () => {
    dispatch(downloadAnalyticsData({ username: selectedUser }));
  };

  return (
    <PageLayout
      title="Analytics Dashboard"
      actions={[
        <Button key="download" onClick={downloadAnalytics}>
          Download
        </Button>,
      ]}
    >
      {!isUser && (
        <div className="mg-b-20">
          Select User:
          <SearchableSelector
            loading={userNamesLoading}
            width={200}
            placeholder="Search to Select"
            value={selectedUser}
            onChange={(value: any) => setSelectedUser(value)}
          >
            <Option key="all" value="all">
              All
            </Option>
            {userNames.map(({ name, username }) => (
              <Option key={username} value={username}>
                {name}
              </Option>
            ))}
          </SearchableSelector>
        </div>
      )}
      <Radio.Group
        defaultValue="daily"
        buttonStyle="solid"
        className="mg-5"
        onChange={(e) => setFrequency(e.target.value)}
        value={frequency}
      >
        <Radio.Button value="daily">Daily</Radio.Button>
        <Radio.Button value="weekly">Weekly</Radio.Button>
        <Radio.Button value="monthly">Monthly</Radio.Button>
      </Radio.Group>
      <Spin spinning={loading}>
        <Row gutter={8} justify="center">
          {isCustomer && selectedUser === '' && (
            <Col>
              <Empty description="Select a user to get the analytics information!" />
            </Col>
          )}
          {!loading && data && (
            <Col span={24}>
              <Summery data={data[frequency].metrics} locations={data[frequency].locations} />
            </Col>
          )}
        </Row>
      </Spin>
    </PageLayout>
  );
};

export default Analytics;
