import React, { useEffect } from 'react';
import { Input, Modal, Form, Row, Col, FormInstance } from 'antd';
import {
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  SafetyCertificateOutlined,
  UserOutlined,
} from '@ant-design/icons';
import to from 'await-to-js';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getShowAddNewDriverModal,
  getShowEditDriverModal,
  showAddNewDriver,
  showEditDriver,
} from '../../store/app-ui.store';
import Driver from '../../Types/Driver';
import { addNewDriver, editDriver } from '../../store/drivers.store';

const DriverForm = ({
  formRef,
  handleSubmit,
  isEditing,
}: {
  formRef: FormInstance;
  handleSubmit: (values: Driver) => void;
  isEditing: boolean;
}) => (
  <Form layout="vertical" form={formRef} name="login-form" onFinish={handleSubmit} initialValues={initValue}>
    <Form.Item label="Email" name="email" rules={validations.email}>
      <Input placeholder="sample@email.com" prefix={<MailOutlined />} />
    </Form.Item>
    <Row justify="space-between">
      <Col>
        <Form.Item label="First Name" name="fname" rules={validations.fname}>
          <Input placeholder="First Name" prefix={<UserOutlined />} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="Last Name" name="lname" rules={validations.lname}>
          <Input placeholder="Last Name" prefix={<UserOutlined />} />
        </Form.Item>
      </Col>
    </Row>

    <Form.Item
      label="License Number (This would be the drivers login username in drivers app)"
      name="licenseNumber"
      rules={validations.licenseNumber}
    >
      <Input placeholder="License Number" prefix={<IdcardOutlined />} disabled={isEditing} />
    </Form.Item>
    <Form.Item label="Telephone" name="tele" rules={validations.tele}>
      <Input placeholder="94xxxxxxxxx" prefix={<PhoneOutlined />} />
    </Form.Item>
    {!isEditing && (
      <Form.Item
        label="Password (This would be the driver's login password): "
        name="password"
        rules={validations.password}
      >
        <Input.Password placeholder="Password" prefix={<SafetyCertificateOutlined />} />
      </Form.Item>
    )}
  </Form>
);

const AddNewDriver = (): JSX.Element => {
  const addNewDriverModalVisibility = useAppSelector(getShowAddNewDriverModal);
  const dispatch = useAppDispatch();
  const [formRef] = Form.useForm();

  const handleOk = () => {
    formRef.submit();
  };

  const handleCancel = () => {
    dispatch(showAddNewDriver(false));
  };

  const handleSubmit = async (values: Driver) => {
    const [err] = await to(dispatch(addNewDriver(values)));
    if (!err) {
      formRef.resetFields();
    }
  };

  return (
    <Modal
      visible={addNewDriverModalVisibility}
      onOk={handleOk}
      title="Add New Driver"
      destroyOnClose
      onCancel={handleCancel}
    >
      <DriverForm isEditing={false} formRef={formRef} handleSubmit={handleSubmit} />
    </Modal>
  );
};

export const EditDriver = () => {
  const showEditDriverData = useAppSelector(getShowEditDriverModal);
  const dispatch = useAppDispatch();
  const [formRef] = Form.useForm();

  const handleOk = () => {
    formRef.submit();
  };

  const handleCancel = () => {
    dispatch(showEditDriver(null));
  };

  const handleSubmit = async (values: Driver) => {
    const [err] = await to(dispatch(editDriver(values)));
    if (!err) {
      formRef.resetFields();
    }
  };

  useEffect(() => {
    formRef.setFieldsValue({
      ...showEditDriverData,
    });
  }, [showEditDriverData]);

  return (
    <Modal visible={!!showEditDriverData} onOk={handleOk} title="Edit Driver" destroyOnClose onCancel={handleCancel}>
      {showEditDriverData && <DriverForm isEditing formRef={formRef} handleSubmit={handleSubmit} />}
    </Modal>
  );
};

const initValue: Driver = {
  email: '',
  password: '',
  fname: '',
  lname: '',
  licenseNumber: '',
  tele: '',
};

const validations = {
  email: [{ required: true, message: 'Please enter the Email' }],
  password: [{ required: true, message: 'Please enter the Password' }],
  fname: [{ required: true, message: 'Please enter the First Name' }],
  lname: [{ required: true, message: 'Please enter the Last Name' }],
  licenseNumber: [{ required: true, message: 'Please enter the License Number' }],
  tele: [
    { required: true, message: 'Please enter the Telephone Number' },
    { pattern: /^([0-9]{1,3}|0)[0-9]{3}( )?[0-9]{7,8}\b/, message: 'Please enter valid phone number' },
  ],
};

export default AddNewDriver;
