import React, { useState } from 'react';
import { Checkbox, Modal } from 'antd';
import to from 'await-to-js';
import { toast } from 'react-toastify';
import { agreedToTermsAndConditions, LS_KEYS } from '../../store/auth.store';
import { useAppDispatch } from '../../store/hooks';

const TocModal = () => {
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(!!localStorage.getItem(LS_KEYS.AGREED_TOC));
  const dispatch = useAppDispatch();

  const handleOK = async () => {
    if (checked) {
      const [err, data] = await to(dispatch(agreedToTermsAndConditions({})).unwrap());
      if (data) {
        localStorage.removeItem(LS_KEYS.AGREED_TOC);
        setShowModal(false);
      }
    } else {
      toast.warn('You will have to agreed to TOC to use the application');
    }
  };

  return (
    <Modal visible={showModal} title="Terms of Use" onOk={handleOK} closable={false} cancelText={() => null}>
      <p>Please agree to the Terms and Conditions in order to proceed</p>
      <p>
        <a href="https://www.routesonar.com" target="_blank" rel="noreferrer">
          Go to Terms and Conditions
        </a>
      </p>
      <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
        I have read and agreed to the RouteSONAR terms and conditions.
      </Checkbox>
    </Modal>
  );
};

export default TocModal;
