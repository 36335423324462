import React, { useEffect } from 'react';
import { Input, Modal, Form, Row, Col, Switch, Select, Button } from 'antd';
import { CloseOutlined, PaperClipOutlined } from '@ant-design/icons';
import to from 'await-to-js';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getShowAddNewLocationModal, showAddNewLocationModal, showLocationPicker } from '../../store/app-ui.store';
import {
  addLocation,
  getEditingLocation,
  getLocationCoords,
  getPageStart,
  setEditingLocation,
  setLocationCoords,
  updateLocation,
} from '../../store/location.store';
import Location from '../../Types/Location';
import useGooglePlaces from '../../hooks/useGooglePlaces';

interface ILocation {
  name: string;
  address: string;
  status: boolean;
  location_id?: string;
}

const AddNewLocation = (): JSX.Element => {
  const { options, setQuery, getPlaceDetails } = useGooglePlaces();
  const addNewLocationModalVisibility = useAppSelector(getShowAddNewLocationModal);
  const editingLocation = useAppSelector(getEditingLocation);
  const pageStart = useAppSelector(getPageStart);
  const dispatch = useAppDispatch();
  const coords = useAppSelector(getLocationCoords);

  const [formRef] = Form.useForm();

  const handleOk = () => {
    formRef.submit();
  };

  const handleCancel = () => {
    dispatch(showAddNewLocationModal(false));
    dispatch(setEditingLocation(null));
    dispatch(setLocationCoords(null));
    formRef.resetFields();
  };

  const handleSubmit = async (values: ILocation) => {
    const locationData: Location = {
      locationName: values.name,
      address: values.address,
      latitude: coords!.lat.toString(),
      longitude: coords!.lng.toString(),
      status: values.status ? 'ACTIVE' : 'INACTIVE',
    };

    if (editingLocation) {
      locationData.location_id = editingLocation.location_id;
    }

    const data = {
      locationData,
      pageStart,
    };

    const [err] = await to(dispatch(editingLocation ? updateLocation(data) : addLocation(data)));
    if (!err && !editingLocation) {
      formRef.resetFields();
      dispatch(setLocationCoords(null));
    }
  };

  useEffect(() => {
    if (editingLocation) {
      formRef.setFieldsValue({
        name: editingLocation.locationName,
        address: editingLocation.address,
        lat: editingLocation.latitude,
        lng: editingLocation.longitude,
        status: editingLocation.status === 'ACTIVE',
      });
    }
  }, [addNewLocationModalVisibility]);

  return (
    <Modal
      visible={addNewLocationModalVisibility}
      onOk={handleOk}
      title={editingLocation ? 'Edit Location' : 'Add New Location'}
      destroyOnClose
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={formRef} name="location-form" onFinish={handleSubmit} initialValues={initValue}>
        <Form.Item label="Location Name: " name="name" rules={validations.name}>
          <Input placeholder="Location Name" prefix={<PaperClipOutlined />} />
        </Form.Item>
        <Form.Item label="Address :" name="address" rules={validations.address}>
          <Select
            showSearch
            placeholder="Address"
            onSearch={setQuery}
            options={options}
            filterOption={false}
            className="w-100"
            onSelect={async (v: any, o: any) => {
              const placeDetails = await getPlaceDetails(v?.toString() || '');

              if (placeDetails?.geometry) {
                const lat = +String(placeDetails.geometry.location?.lat());
                const lng = +String(placeDetails.geometry.location?.lng());
                dispatch(setLocationCoords({ lat, lng }));
                formRef.setFieldsValue({ address: o.label?.toString() });
              }
            }}
            allowClear
            clearIcon={<CloseOutlined />}
            onClear={() => {
              dispatch(setLocationCoords(null));
            }}
          />
        </Form.Item>
        <br />
        {coords ? (
          <>
            <p style={{ textAlign: 'center' }}>Lat : {coords.lat}</p>
            <p style={{ textAlign: 'center' }}>Lng : {coords.lng}</p>
            <Row justify="center">
              <Button key="add" type="primary" onClick={() => dispatch(showLocationPicker({ show: true }))}>
                Edit Location
              </Button>
            </Row>
            <br />
          </>
        ) : null}
        <Row justify="center">
          <Col>
            <Row justify="center" align="middle">
              <span>Inactive</span>
              <Form.Item valuePropName="checked" name="status" style={{ margin: '0 10px' }}>
                <Switch />
              </Form.Item>
              <span>Active</span>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const initValue: ILocation = {
  name: '',
  address: '',
  status: true,
};

const validations = {
  name: [{ required: true, message: 'Please enter location name' }],
  address: [{ required: true, message: 'Please enter location address' }],
};

export default AddNewLocation;
