import { Action, ThunkAction, configureStore, combineReducers } from '@reduxjs/toolkit';
import uiReducer from './app-ui.store';
import authReducer from './auth.store';
import routeReducer from './routes.store';
import driverReducer from './drivers.store';
import vehicleReducer from './vehicle.store';
import userReducer from './users.store';
import billingReducer from './billing.store';
import locationReducer from './location.store';

const rootReducer = combineReducers({
  UI: uiReducer,
  auth: authReducer,
  route: routeReducer,
  driver: driverReducer,
  vehicle: vehicleReducer,
  user: userReducer,
  billing: billingReducer,
  location: locationReducer,
});

export const store = configureStore({
  reducer: (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
