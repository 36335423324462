import React, { ChangeEvent } from 'react';
import { Col, Row, Table, Input, TableProps } from 'antd';
import noop from 'lodash.noop';

import './styles.scss';

const { Search } = Input;

type RSTableProps = {
  headerTitle?: string;
  onSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  textSearch?: boolean;
  filterElements?: any;
};

const RSTable = ({
  headerTitle,
  onSearch,
  textSearch,
  filterElements,
  ...props
}: TableProps<any> & RSTableProps): JSX.Element => {
  const tableTitleContent = (
    <>
      <Row justify="space-between">
        <Col className="table-header">{headerTitle}</Col>
        {textSearch && (
          <Col>
            <Search placeholder="input search text" onChange={onSearch} style={{ width: 300 }} />
          </Col>
        )}
      </Row>
      <Row justify="end">{filterElements}</Row>
    </>
  );

  return <Table {...props} title={() => tableTitleContent} className="w-100 rs-table mg-20" pagination={false} />;
};

RSTable.defaultProps = {
  headerTitle: '',
  onSearch: noop,
  textSearch: false,
  filterElements: null,
};

export default RSTable;
