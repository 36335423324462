import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import to from 'await-to-js';
import { toast } from 'react-toastify';
import { RootState } from './store';
import APIService from '../Utils/APIService';
import { Customer, User } from '../Types/UsersAndCustomer';
import { showAddNewCustomer, showAddNewUser } from './app-ui.store';
import { DEFAULT_PAGE_SIZE } from '../Utils/constants';

export interface UserStore {
  loading: boolean;
  userList: User[];
  customerList: Customer[];
  editingUser: User | null;
  editingCustomer: Customer | null;
}

const initialState = {
  customerList: [],
  loading: false,
  userList: [],
  editingUser: null,
  editingCustomer: null,
} as UserStore;

export const getAllUsers = createAsyncThunk<any, { pageStart: number; limit: number }>(
  'users/get-users',
  async (arg, { rejectWithValue }) => {
    const [err, data] = await to(APIService.get('customer/user/all-users', { params: arg }));
    if (err) {
      toast.error('Something went wrong!. Could not fetch the users');
      return rejectWithValue(err);
    }
    return data?.data;
  },
);

export const getAllCustomers = createAsyncThunk<any, any>('users/get-customers', async (arg, { rejectWithValue }) => {
  const [err, data] = await to(APIService.get('admin/customers', { params: arg }));
  if (err) {
    toast.error('Something went wrong!. Could not fetch the customers');
    return rejectWithValue(err);
  }
  return data?.data;
});

export const addNewUser = createAsyncThunk<any, User>(
  'users/create-new-user',
  async (user, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.post('/customer/user', user));
    if (err) {
      toast.error(`${err.message || 'Something went wrong!'}. Could not create the user account`);
      return rejectWithValue(err);
    }
    toast.success('Successfully added the user account');
    dispatch(showAddNewUser(false));
    dispatch(getAllUsers({ pageStart: 0, limit: DEFAULT_PAGE_SIZE }));
    return data?.data;
  },
);

export const addNewCustomer = createAsyncThunk<any, Customer>(
  'users/create-new-customer',
  async (user, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.post('admin/customers', user));
    if (err) {
      toast.error(`${err.message || 'Something went wrong!'}. Could not create the customer account`);
      return rejectWithValue(err);
    }
    toast.success('Successfully added the customer account');
    dispatch(showAddNewCustomer(false));
    dispatch(getAllCustomers({ pageStart: 0, limit: DEFAULT_PAGE_SIZE }));
    return data?.data;
  },
);

export const updateUserState = createAsyncThunk<any, { username: string; status: string }>(
  'users/update-user-status',
  async ({ username, status }, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.post('/customer/user/change-status', { username, status }));
    if (err) {
      toast.error(`${err.message || 'Something went wrong!'}. Could not update the status`);
      return rejectWithValue(err);
    }
    toast.success('Successfully updated');
    dispatch(getAllUsers({ pageStart: 0, limit: DEFAULT_PAGE_SIZE }));
    return data?.data;
  },
);

export const updateCustomerState = createAsyncThunk<any, { username: string; status: string }>(
  'users/update-customer-status',
  async ({ username, status }, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.post('/admin/customer/change-status', { username, status }));
    if (err) {
      toast.error(`${err.message || 'Something went wrong!'}. Could not update the status`);
      return rejectWithValue(err);
    }
    toast.success('Successfully updated');
    dispatch(getAllCustomers({ pageStart: 0, limit: DEFAULT_PAGE_SIZE }));
    return data?.data;
  },
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (store) => {
      store.loading = true;
    });
    builder.addCase(getAllUsers.rejected, (store) => {
      store.loading = false;
    });
    builder.addCase(getAllUsers.fulfilled, (store, { payload }) => {
      store.loading = false;
      store.userList = payload;
    });
    builder.addCase(getAllCustomers.pending, (store) => {
      store.loading = true;
    });
    builder.addCase(getAllCustomers.rejected, (store) => {
      store.loading = false;
    });
    builder.addCase(getAllCustomers.fulfilled, (store, { payload }) => {
      store.loading = false;
      store.customerList = payload;
    });
    builder.addCase(updateUserState.pending, (store) => {
      store.loading = true;
    });
    builder.addCase(updateUserState.rejected, (store) => {
      store.loading = false;
    });
    builder.addCase(updateUserState.fulfilled, (store) => {
      store.loading = false;
    });
  },
});

export const userStore = (store: RootState): UserStore => store.user;

export const getUserListsLoading = createSelector(userStore, (store) => store.loading);
export const getUserList = createSelector(userStore, (store) => store.userList);
export const getCustomersList = createSelector(userStore, (store) => store.customerList);

export const getEditingUserDetails = createSelector(userStore, (store) => store.editingUser);
export const getEditingCustomerDetails = createSelector(userStore, (store) => store.editingCustomer);

export default usersSlice.reducer;
