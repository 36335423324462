import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import to from 'await-to-js';
import { toast } from 'react-toastify';
import { Coordinates } from '../Types/DraftOperation';
import Location from '../Types/Location';
import APIService from '../Utils/APIService';
import { DEFAULT_PAGE_SIZE } from '../Utils/constants';
import { RootState } from './store';

export interface LocationState {
  loading: boolean;
  locations: Location[];
  minimalLocations: any[];
  editingLocation: Location | null;
  locationCoords: Coordinates | null;
  pageStart: number;
}

const initialState = {
  loading: false,
  locations: [],
  editingLocation: null,
  locationCoords: null,
  pageStart: 0,
  minimalLocations: [],
} as LocationState;

export const addLocation = createAsyncThunk<any, { locationData: Location; pageStart: number }>(
  'locations/add',
  async (data, { rejectWithValue, dispatch }) => {
    const [err, res]: any = await to(
      APIService.post('user/saved-locations', data.locationData, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
    if (err) {
      toast.error('Something went wrong with data. Please try again!');
      return rejectWithValue(err);
    }
    dispatch(
      fetchLocations({
        start: data.pageStart,
        limit: DEFAULT_PAGE_SIZE,
        locationName: '',
        activeOnly: false,
      }),
    );
    toast.success('Successfully Added the Location');
    return res;
  },
);

export const updateLocation = createAsyncThunk<any, { locationData: Location; pageStart: number }>(
  'locations/update',
  async (data, { rejectWithValue, dispatch }) => {
    const [err, res]: any = await to(
      APIService.put(
        'user/saved-locations',
        { ...data.locationData, locationId: data.locationData.location_id },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ),
    );

    if (err) {
      toast.error('Something went wrong with data. Please try again!');
      return rejectWithValue(err);
    }
    dispatch(
      fetchLocations({
        start: data.pageStart,
        limit: DEFAULT_PAGE_SIZE,
        locationName: '',
        activeOnly: false,
      }),
    );
    toast.success('Successfully Updated the Location');
    return res;
  },
);

export const fetchLocations = createAsyncThunk<
  any,
  { start: number; limit: number; locationName: string; activeOnly: boolean }
>('locations/get', async ({ start, limit, locationName, activeOnly }, { rejectWithValue }) => {
  const [err, data] = await to(
    APIService.get('user/saved-locations', {
      params: { pageStart: start, locationName, limit, status: activeOnly ? 'ACTIVE' : 'ALL' },
    }),
  );
  if (err) {
    toast.error('Locations not loaded. Please try again!');
    rejectWithValue(err);
  }
  return data?.data;
});

export const fetchMinimalLocations = createAsyncThunk<any, any>(
  'locations/minimal/get',
  async (_, { rejectWithValue }) => {
    const [err, data] = await to(APIService.get('user/saved-locations-minimal'));
    if (err) {
      toast.error('Location not loaded');
      rejectWithValue(err);
    }
    return data?.data;
  },
);

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setEditingLocation: (state, { payload }) => {
      state.editingLocation = payload;
    },
    setLocationCoords: (state, { payload }) => {
      state.locationCoords = payload;
    },
    setPageStart: (state, { payload }) => {
      state.pageStart = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocations.pending, (store) => {
      store.loading = true;
    });
    builder.addCase(fetchLocations.rejected, (store) => {
      store.loading = false;
    });
    builder.addCase(fetchLocations.fulfilled, (store, { payload }) => {
      store.loading = false;
      store.locations = payload;
    });
    builder.addCase(fetchMinimalLocations.fulfilled, (state, { payload }) => {
      state.minimalLocations = payload;
    });
  },
});

export const { setEditingLocation, setLocationCoords, setPageStart } = locationSlice.actions;

export const locationStore = (store: RootState): LocationState => store.location;

export const getLocations = createSelector(locationStore, (location) => location.locations);
export const getLocationsLoading = createSelector(locationStore, (location) => location.loading);
export const getEditingLocation = createSelector(locationStore, (location) => location.editingLocation);
export const getLocationCoords = createSelector(locationStore, (location) => location.locationCoords);
export const getPageStart = createSelector(locationStore, (location) => location.pageStart);
export const getMinimalLocations = createSelector(locationStore, (location) =>
  location.minimalLocations.filter((l) => l.status === 'ACTIVE'),
);

export default locationSlice.reducer;
