import React, { useEffect, useRef } from 'react';
import { Button, Modal, Space, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { read, utils } from 'xlsx';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getShowUploadOperationCSVModal, showUploadOperationCSVModal } from '../../store/app-ui.store';
import { getExcelUploading, setDraftOperations } from '../../store/routes.store';
import { fetchMinimalLocations, getMinimalLocations } from '../../store/location.store';

const { Dragger } = Upload;

const UploadOperationCsv = (): JSX.Element => {
  const showModal = useAppSelector(getShowUploadOperationCSVModal);
  const uploading = useAppSelector(getExcelUploading);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const minimalLocations = useAppSelector(getMinimalLocations);

  useEffect(() => {
    dispatch(fetchMinimalLocations(0));
  }, []);

  const uploaderRef = useRef();

  const handleCancel = () => {
    dispatch(showUploadOperationCSVModal(false));
  };

  const handleOk = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const file = uploaderRef.current?.fileList[0]?.originFileObj;
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = e.target?.result;
        const workBook = read(data, { type: 'binary' });
        const firstSheet = workBook.SheetNames[0];
        const content = utils.sheet_to_json(workBook.Sheets[firstSheet], { raw: false, rawNumbers: true });

        dispatch(
          setDraftOperations({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            operations: content.map((d, key) => ({ ...d, key: (key + 1).toString() })),
            minimalLocations,
          }),
        );

        dispatch(showUploadOperationCSVModal(false));
        await history.push('/operations/edit-operation');
      } catch (error) {
        toast.error('Something went wrong');
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleEmpty = async () => {
    localStorage.setItem('draft-operations', '[]');
    dispatch(setDraftOperations({ operations: [], minimalLocations }));
    dispatch(showUploadOperationCSVModal(false));
    await history.push('/operations/edit-operation');
  };

  return (
    <Modal
      title="Upload CSV"
      visible={showModal}
      destroyOnClose
      footer={
        <div className="d-flex jc-space-between">
          <div>
            <Button onClick={handleEmpty}>Start With Empty Records</Button>
          </div>
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" onClick={handleOk} loading={uploading}>
              OK
            </Button>
          </Space>
        </div>
      }
    >
      {/* Had to ignore here because Dragger Component doesn't accept ref props */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Dragger accept=".csv" beforeUpload={() => Promise.reject()} maxCount={1} ref={uploaderRef}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag Excel file to this area to upload</p>
        <p className="ant-upload-hint">Upload single xlsx document at a time.</p>
      </Dragger>
    </Modal>
  );
};

export default UploadOperationCsv;
