import React, { useEffect, useState } from 'react';
import { Button, Row, Radio, Col, Divider, Typography, Card, Slider, InputNumber, Table, Tooltip, Spin } from 'antd';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLayout from '../../SharedComponents/PageLayout';
import { useAppDispatch } from '../../store/hooks';
import {
  fetchBillingSummary,
  getBillSummary,
  getBillSummaryLoading,
  subscribeByLocation,
  subscribeByVehicle,
} from '../../store/billing.store';

const LocationCard = (): JSX.Element => {
  const [amount, setAmount] = useState(1000);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleSubscribe = async () => {
    try {
      await dispatch(subscribeByLocation({ numberOfLocations: amount }));
      toast.success('Your Subscription requires is success. This will affect from next month onward');
      history.push('/dashboard/billing');
    } catch (e) {
      toast.error('Something went wrong when updating your subscription request');
    }
  };

  return (
    <Row justify="center">
      <Col span={18}>
        <Card hoverable title="Select your plan for location">
          <Row justify="center">
            <Col span={18}>
              <Slider defaultValue={1000} value={amount} min={1000} max={60000} step={1000} onChange={setAmount} />
            </Col>
            <Col span={6}>
              <InputNumber
                value={amount}
                onChange={setAmount}
                addonAfter={`$ ${Number((amount * 40) / 1000).toLocaleString()}`}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col className="mg-t-20">
              <Button type="primary" onClick={handleSubscribe}>
                Subscribe
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const ChangeVehicle = ({ value, defaultValue, onChange }: { value: any; onChange: any; defaultValue: number }) => (
  <InputNumber
    defaultValue={defaultValue}
    addonAfter={<Tooltip title="Difference">{value - defaultValue}</Tooltip>}
    onChange={onChange}
    style={{ width: 100 }}
    min={0}
  />
);

const VehicleCard = () => {
  const billSummary = useSelector(getBillSummary);
  const billSummaryLoading = useSelector(getBillSummaryLoading);
  const dispatch = useAppDispatch();
  const [values, setValues] = useState({});
  const history = useHistory();
  useEffect(() => {
    if (!billSummary || !billSummary.by_vehicle || !billSummary.by_vehicle.breakdown_by_user) {
      dispatch(fetchBillingSummary(0));
    }
  }, [billSummary]);

  if (!billSummary || !billSummary.by_vehicle || !billSummary.by_vehicle.breakdown_by_user) {
    return null;
  }

  const handleSubscribe = async () => {
    const vehicleAssignment = billSummary.by_vehicle.breakdown_by_user?.map(({ username, Vehicles_assigned }) => ({
      username,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      number_of_vehicles: values[username] || Vehicles_assigned,
    }));
    try {
      await dispatch(subscribeByVehicle({ vehicleAssignment }));
      toast.success('Your Subscription requires is success. This will affect from next month onward');
      history.push('/dashboard/billing');
    } catch (e) {
      toast.error('Something went wrong when updating your subscription request');
    }
  };

  return (
    <Row justify="center">
      <Col span={18}>
        <Spin spinning={billSummaryLoading}>
          <Card hoverable title="Select your plan for location">
            <Row justify="center">
              <Table
                columns={[
                  { title: 'Name', dataIndex: 'name' },
                  { title: 'Active Vehicle', dataIndex: 'active_vehicles' },
                  {
                    title: 'Total Vehicle',
                    dataIndex: 'Vehicles_assigned',

                    render: (value, record) => (
                      <ChangeVehicle
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        value={values[record.username] ?? value}
                        onChange={(v: any) => setValues({ ...values, [record.username]: v })}
                        defaultValue={value}
                      />
                    ),
                  },
                ]}
                pagination={false}
                dataSource={billSummary.by_vehicle.breakdown_by_user}
              />
            </Row>
            <Row justify="center">
              <Col className="mg-t-20">
                <Button type="primary" onClick={handleSubscribe}>
                  Subscribe
                </Button>
              </Col>
            </Row>
          </Card>
        </Spin>
      </Col>
    </Row>
  );
};

const Subscription = (): JSX.Element => {
  const [type, setType] = useState('v');
  return (
    <PageLayout title="Subscriptions">
      <Row justify="center">
        <Radio.Group defaultValue="v" buttonStyle="solid" value={type} onChange={(e) => setType(e.target.value)}>
          <Radio.Button value="v">By Vehicles</Radio.Button>
          <Radio.Button value="l">By Locations</Radio.Button>
        </Radio.Group>
      </Row>
      <Divider />
      <Typography.Title level={2} className="ta-center">
        Pay only for what you use
      </Typography.Title>
      {type === 'v' ? <VehicleCard /> : <LocationCard />}
      <Row justify="center" className="mg-t-20">
        <Col span={18}>
          <Typography.Title level={4}>By Vehicle</Typography.Title>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium deserunt eaque impedit ipsum itaque
            laudantium nobis, non, odit quisquam ratione reprehenderit sed, sequi sit! Aliquam architecto culpa ex
            pariatur veniam?
          </p>

          <Typography.Title level={4}>By Location</Typography.Title>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium deserunt eaque impedit ipsum itaque
            laudantium nobis, non, odit quisquam ratione reprehenderit sed, sequi sit! Aliquam architecto culpa ex
            pariatur veniam?
          </p>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default Subscription;
