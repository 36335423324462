import React from 'react';
import { Select } from 'antd';

import noop from 'lodash.noop';
import SearchableSelector from './SearchableSelector';

const { Option } = Select;

const MinimalVehicles = ({
  minimalVehicle,
  value,
  onChange,
}: {
  minimalVehicle: any[];
  value?: any;
  onChange?: any;
}) => (
  <SearchableSelector loading={false} placeholder="Search to Select" value={value} onChange={onChange}>
    {minimalVehicle.map(({ name, plateNumber }) => (
      <Option key={plateNumber} value={plateNumber}>
        {name}
      </Option>
    ))}
  </SearchableSelector>
);

MinimalVehicles.defaultProps = {
  value: null,
  onChange: noop,
};

export default MinimalVehicles;
