import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Input, message, Modal, Row, Space, Switch, Tooltip } from 'antd';
import { DeleteOutlined, LeftOutlined, RightOutlined, EditOutlined } from '@ant-design/icons';
import noop from 'lodash.noop';
import PageLayout from '../../SharedComponents/PageLayout';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showAddNewVehicle, showEditVehicle } from '../../store/app-ui.store';
import { DEFAULT_PAGE_SIZE } from '../../Utils/constants';
import RSTable from '../../SharedComponents/RSTable';
import Vehicle from '../../Types/Vehicle';
import { getAllVehicles, getVehicleLoading, getVehicles, updateVehicleStatus } from '../../store/vehicle.store';
import { useFeature } from '../../Utils/features';

const vehicleTypes = {
  '2wheels': '2 Wheels',
  '3wheels': '3 Wheels',
  '4wheels-van': '4 Wheels Lorry',
  '4wheels-lorries': '4 Wheels Van',
  carrier: 'Carrier',
};

const getColumns = (updateStatus: any, dispatch: any) => [
  { title: 'Plate Number', dataIndex: 'plateNumber', rowKey: 'id' },
  {
    title: 'Type',
    dataIndex: 'type',
    rowKey: 'id',
    render: (data: keyof typeof vehicleTypes) => <span>{vehicleTypes[data]}</span>,
  },
  { title: 'Mileage (KM)', dataIndex: 'mileage', rowKey: 'id' },
  { title: 'Fuel Efficiency (KM/L)', dataIndex: 'fuelEfficiency', rowKey: 'id' },
  { title: 'Fuel Type', dataIndex: 'fuelType', rowKey: 'id' },
  { title: 'Capacity (KG)', dataIndex: 'capacity', rowKey: 'id' },
  { title: 'Volume (m^3)', dataIndex: 'volume', rowKey: 'id' },
  {
    title: 'Active/Inactive',
    render: (data: any, record: Vehicle) => (
      <Space size="middle">
        <Tooltip title="Active/Inactive Vehicles" placement="bottomRight">
          <Switch
            checked={record.status === 'active'}
            onChange={(checked) => {
              const status = checked ? 'active' : 'inactive';
              updateStatus(record.plateNumber, status);
            }}
          />
        </Tooltip>
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            dispatch(showEditVehicle(record));
          }}
        />
        <Tooltip title="Delete Vehicle" placement="bottomRight">
          <Button
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              Modal.confirm({
                title: (
                  <p>
                    Are you sure you want to delete?
                    <br />
                    If yes, please enter the <strong>Plate Number</strong> in the below input and press OK
                  </p>
                ),
                icon: <DeleteOutlined />,
                okText: 'OK',
                onCancel: noop,
                content: <Input id="plateNumber" placeholder={record.plateNumber} />,
                onOk: async () => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const input = document.getElementById('plateNumber')! as HTMLInputElement;
                  if (input.value === record.plateNumber) {
                    await updateStatus(record.plateNumber, 'deleted');
                    return false;
                  }
                  message.warn('Plate Number not matched!');
                  return true;
                },
              });
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];

const FleetPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [pageStart, setPageStart] = useState(0);

  const vehicles = useAppSelector(getVehicles);
  const loading = useAppSelector(getVehicleLoading);

  const [filteredVehicles, setFilteredVehicles] = useState<any>([]);

  const showAddNewVehicleModal = () => {
    dispatch(showAddNewVehicle(true));
  };

  useEffect(() => {
    setFilteredVehicles(vehicles);
  }, [vehicles]);

  useEffect(() => {
    dispatch(getAllVehicles({ pageStart, limit: DEFAULT_PAGE_SIZE }));
  }, [pageStart]);

  const filter = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim()) {
      setFilteredVehicles(vehicles.filter((d) => String(d.plateNumber).startsWith(e.target.value)));
    } else {
      setFilteredVehicles(vehicles);
    }
  };

  const changePage = (increment: boolean) => {
    if (!increment) {
      setPageStart(pageStart > 0 ? pageStart - DEFAULT_PAGE_SIZE : 0);
    } else {
      setPageStart(pageStart + DEFAULT_PAGE_SIZE);
    }
  };

  const updateStatus = (plateNumber: string, status: string) => {
    dispatch(updateVehicleStatus({ plateNumber, status }));
  };

  const canAddVehicle = useFeature('add_new_vehicle');

  return (
    <PageLayout
      title="Fleet Management"
      actions={[
        canAddVehicle && (
          <Button key="add" onClick={showAddNewVehicleModal}>
            Add
          </Button>
        ),
      ]}
    >
      <Row>
        <RSTable
          loading={loading}
          onSearch={filter}
          headerTitle="Vehicles"
          columns={getColumns(updateStatus, dispatch)}
          dataSource={filteredVehicles}
          textSearch
          footer={(pageData) => (
            <Row justify="end">
              <Space size="small">
                <Button icon={<LeftOutlined />} onClick={() => changePage(false)} />
                <Button
                  icon={<RightOutlined />}
                  onClick={() => changePage(true)}
                  disabled={pageData.length < DEFAULT_PAGE_SIZE}
                />
              </Space>
            </Row>
          )}
          rowKey="plateNumber"
        />
      </Row>
    </PageLayout>
  );
};

export default FleetPage;
