import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Row, Space, Spin, Switch, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PageLayout from '../../SharedComponents/PageLayout';
import RSTable from '../../SharedComponents/RSTable';
import { DEFAULT_PAGE_SIZE } from '../../Utils/constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showAddNewCustomer } from '../../store/app-ui.store';
import { getAllCustomers, getCustomersList, getUserListsLoading, updateCustomerState } from '../../store/users.store';
import { Customer } from '../../Types/UsersAndCustomer';

const getColumns = (updateStatus: any) => [
  {
    title: 'Customer Name',
    dataIndex: 'name',
    rowKey: 'id',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    rowKey: 'id',
  },
  {
    title: 'Telephones',
    dataIndex: 'tele1',
    rowKey: 'id',
    render: (data: string, row: Customer) => (row.tele2 ? `${row.tele1} / ${row.tele2}` : row.tele1),
  },
  {
    title: 'Active/Inactive',
    render: (data: any, record: Customer) => (
      <Space size="middle">
        <Tooltip title="Active/Inactive Customer" placement="bottomRight">
          <Switch
            checked={record.status === 'active'}
            onChange={(checked) => {
              const status = checked ? 'active' : 'inactive';
              updateStatus(record.username, status);
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];

const CustomersManagement = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [pageStart, setPageStart] = useState(0);

  const customers = useAppSelector(getCustomersList);
  const loading = useAppSelector(getUserListsLoading);

  const [filteredUsers, setFilteredUsers] = useState<any>([]);

  const showAddNewCustomerModal = () => {
    dispatch(showAddNewCustomer(true));
  };

  useEffect(() => {
    setFilteredUsers(customers);
  }, [customers]);

  useEffect(() => {
    dispatch(getAllCustomers({ pageStart, limit: DEFAULT_PAGE_SIZE }));
  }, [pageStart]);

  const filter = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim()) {
      setFilteredUsers(customers.filter((d) => String(d.fname).startsWith(e.target.value)));
    } else {
      filteredUsers(customers);
    }
  };

  const changePage = (increment: boolean) => {
    if (!increment) {
      setPageStart(pageStart > 0 ? pageStart - DEFAULT_PAGE_SIZE : 0);
    } else {
      setPageStart(pageStart + DEFAULT_PAGE_SIZE);
    }
  };

  const updateStatus = (username: string, status: string) => {
    dispatch(updateCustomerState({ username, status }));
  };

  return (
    <PageLayout
      title="Customers"
      actions={[
        <Button key="add" onClick={showAddNewCustomerModal}>
          Add
        </Button>,
      ]}
    >
      <Spin spinning={loading} size="large">
        <Row>
          <RSTable
            loading={loading}
            onSearch={filter}
            headerTitle="Customers"
            columns={getColumns(updateStatus)}
            dataSource={filteredUsers}
            textSearch={false}
            footer={(pageData) => (
              <Row justify="end">
                <Space size="small">
                  <Button icon={<LeftOutlined />} onClick={() => changePage(false)} />
                  <Button
                    icon={<RightOutlined />}
                    onClick={() => changePage(true)}
                    disabled={pageData.length < DEFAULT_PAGE_SIZE}
                  />
                </Space>
              </Row>
            )}
            rowKey="plateNumber"
          />
        </Row>
      </Spin>
    </PageLayout>
  );
};

export default CustomersManagement;
