import { useAppSelector } from '../store/hooks';
import { isFeatureEnabled } from '../store/auth.store';

export const ROLE = {
  A: 'admin',
  C: 'customer', // AKA client
  U: 'user',
};

const FEATURES = {
  create_new_client: [ROLE.A],
  set_client_access: [ROLE.A], // stop access, change plan, change #users
  reset_password_for_clients: [ROLE.A],
  admin_analytics: [ROLE.A],
  client_billing: [ROLE.A],

  create_new_dispatcher: [ROLE.C],
  set_dispatcher_access: [ROLE.C],
  reset_password_for_dispatcher: [ROLE.C],
  client_analytics: [ROLE.C],

  upload_csv: [ROLE.U],
  optimize_routes: [ROLE.U],
  user_analytics: [ROLE.U],

  add_new_driver: [ROLE.U],
  add_new_vehicle: [ROLE.U],
  upload_image: [ROLE.C],
  billing_page: [ROLE.C],
  update_profile: [ROLE.U, ROLE.C],

  user_management: [ROLE.C],
  customer_management: [ROLE.A],
  fleet_management: [ROLE.U],
  driver_management: [ROLE.U],
  operation_dashboard: [ROLE.C, ROLE.U],
  add_operation: [ROLE.U],
  location_management: [ROLE.A, ROLE.U],
};

export type FeaturesType = keyof typeof FEATURES;

// eslint-disable-next-line
export const fromFeatureMatrix = (user: string, key: FeaturesType) => {
  if (FEATURES[key] && FEATURES[key].length > 0) {
    return FEATURES[key].indexOf(user) >= 0;
  }
  return true;
};

// eslint-disable-next-line
export const useFeature = (key: FeaturesType) => useAppSelector(isFeatureEnabled)(key);

export const URLConfig = {
  get_recent_routes: {
    user: '/user/recent-routes',
    customer: '/customer/recent-routes',
    admin: '',
  },
  get_drivers: {
    user: '/user/driver',
    customer: '/customer/driver',
    admin: '',
  },
  getKPI: {
    user: '/user/get-KPI',
    customer: '/customer/user/get-KPI',
    admin: '/admin/get-KPI',
  },
  downloadKPI: {
    user: 'user/download-KPI',
    customer: '/customer/user/download-KPI',
    admin: '',
  },
  getMinimalDrivers: {
    user: '/user/driver/minimal',
    customer: '/user/driver/minimal',
    admin: '',
  },
  getMinimalVehicle: {
    user: '/user/vehicle/minimal',
    customer: '/user/vehicle/minimal',
    admin: '',
  },
  getUserProfile: {
    user: '/user',
    customer: '/customer',
    admin: '',
  },
  updateUserProfile: {
    user: '/user/update-profile',
    customer: '/customer/update-profile',
    admin: '',
  },
};

/*
Features (From User Categories Slide: https://docs.google.com/presentation/d/17ZHUCbmxo-65SkZn0svB9Om1Dzh9ptVziAu-qZCNCSE/edit#slide=id.g10755a3199e_0_1)

Admin - RouteSonar Admin
  create new Clients
  stop access to clients
  Reset password for clients
  Change the plan for clients
  See the number of active clients
  See client specific analytics / usage
  See client specific billings

Client (Example Keels Admin)
  create new dispatcher / managers
  remove access to dispatcher
  Reset password for dispatcher
  See overall / aggregate of dispatcher related highlights / analytics
  See dispatcher specific analytics / usage
  All these to be able to filter by column or search

User (Example Keels Dispatcher Nugegoda Branch)
  Optimize route manually and csv upload & add fleet
  Change password
  See overall highlights / analytics (refers to the dashboard explained in the other word document)
  See dispatcher specific analytics / usage
  All these to be able to filter by column or search

*** Not in the web app scope ***
Driver
  Receives the notification to app or mobile as a url sms
  In app able to mark the locations as completed
  Skip locations if required
  See summaries of recent trips (distance, handled packages, skipped)
*/

/*

APIs

Common APIs:
  Login
  Logout
  Update passwords

admin:
  username: admin
  password: *
  APIs:
    Add customer
    Get all customers
    All payments

customer:
  username: test
  password: *

  APIs:
    Update customer details
    Add user
    Update user details
    User profile view by customer
    Delete user
    Get all users for customer
    Add driver
    Get all drivers for customer
    Update driver tele
    Delete driver
    Add vehicle
    Get all vehicles for customer
    Delete vehicle
    Billing history
    Number of deliveries
    Trip summary by customer
    Travel log
    Get recent routes
    Locations remaining
    Get KPI
    Customer/ unfinished trips
    Subscribe
    forecast

user
  username: Rupasinghe
  password: *

  APIs:
    Get own profile for user
    Trip summary user
    Track driver
    user/unfinished trips-driver
    Excel to route
    Pickup and delivery
    Add trip manually

driver
  username: 1234567z
  password: *

  API:
  Confirm delivery
  driver/track-driver(update location)
*/
