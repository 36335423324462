import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface AppUIState {
  sidebarCollapsed: boolean;
  showAddNewOperationModal: boolean;
  showUploadOperationCSVModal: boolean;
  showRouteDetailsModal: boolean;
  showAddNewDriverModal: boolean;
  showEditDriverModal: any;
  showAddNewVehicleModal: boolean;
  showEditVehicleModal: any;
  showAddNewUserModal: boolean;
  showUpdateUserModal: boolean;
  showAddNewCustomerModal: boolean;
  showUpdateCustomerModal: boolean;
  showAddDraftOperationModal: boolean;
  showAddNewLocationModal: boolean;
  showMapView: boolean;
  showLocationPicker: boolean;
  showSelectVehicles: boolean;
  showSelectDrivers: boolean;
  mapLocations: { lng: number; lat: number; mapColor?: string }[];
}

const initialState: AppUIState = {
  sidebarCollapsed: false,
  showAddNewOperationModal: false,
  showUploadOperationCSVModal: false,
  showRouteDetailsModal: false,
  showAddNewDriverModal: false,
  showEditDriverModal: null,
  showAddNewVehicleModal: false,
  showEditVehicleModal: null,
  showAddNewCustomerModal: false,
  showAddNewUserModal: false,
  showUpdateCustomerModal: false,
  showUpdateUserModal: false,
  showAddDraftOperationModal: false,
  showAddNewLocationModal: false,
  showMapView: false,
  showLocationPicker: false,
  showSelectVehicles: false,
  showSelectDrivers: false,
  mapLocations: [],
};

export const appUISlice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    toggleSidebarCollapsed: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    showAddNewOperationModal: (state, { payload }) => {
      state.showAddNewOperationModal = payload;
    },
    showUploadOperationCSVModal: (state, { payload }) => {
      state.showUploadOperationCSVModal = payload;
    },
    showRouteDetailsModal: (state, { payload }) => {
      state.showRouteDetailsModal = payload;
    },
    showAddNewDriver: (state, { payload }) => {
      state.showAddNewDriverModal = payload;
    },
    showEditDriver: (state, { payload }) => {
      state.showEditDriverModal = payload;
    },
    showEditVehicle: (state, { payload }) => {
      state.showEditVehicleModal = payload;
    },
    showAddNewVehicle: (state, { payload }) => {
      state.showAddNewVehicleModal = payload;
    },
    showAddNewUser: (state, { payload }) => {
      state.showAddNewUserModal = payload;
    },
    showAddNewCustomer: (state, { payload }) => {
      state.showAddNewCustomerModal = payload;
    },
    showAddNewLocationModal: (state, { payload }) => {
      state.showAddNewLocationModal = payload;
    },
    showMapView: (state, { payload }) => {
      if (payload.locations) {
        state.showMapView = payload.show;
        state.mapLocations = payload.locations;
      }
    },
    showLocationPicker: (state, { payload }) => {
      state.showLocationPicker = payload.show;
    },
    showSelectVehicleModal: (state, { payload }) => {
      state.showSelectVehicles = payload;
    },
    showSelectDriversModal: (state, { payload }) => {
      state.showSelectDrivers = payload;
    },
    showAddDraftOperationModal: (state, { payload }) => {
      state.showAddDraftOperationModal = payload;
    },
  },
});

export const {
  toggleSidebarCollapsed,
  showAddNewOperationModal,
  showUploadOperationCSVModal,
  showRouteDetailsModal,
  showAddNewDriver,
  showEditDriver,
  showEditVehicle,
  showAddNewVehicle,
  showAddNewCustomer,
  showAddNewUser,
  showAddNewLocationModal,
  showMapView,
  showLocationPicker,
  showSelectVehicleModal,
  showAddDraftOperationModal,
  showSelectDriversModal,
} = appUISlice.actions;

export const uiStore = (store: RootState): AppUIState => store.UI;

export const getSidebarCollapsed = createSelector(uiStore, (ui) => ui.sidebarCollapsed);
export const getShowAddNewOperationModal = createSelector(uiStore, (ui) => ui.showAddNewOperationModal);
export const getShowUploadOperationCSVModal = createSelector(uiStore, (ui) => ui.showUploadOperationCSVModal);
export const getShowRouteDetailsModal = createSelector(uiStore, (ui) => ui.showRouteDetailsModal);
export const getShowAddNewDriverModal = createSelector(uiStore, (ui) => ui.showAddNewDriverModal);
export const getShowEditDriverModal = createSelector(uiStore, (ui) => ui.showEditDriverModal);
export const getShowAddNewVehicleModal = createSelector(uiStore, (ui) => ui.showAddNewVehicleModal);
export const getShowEditVehicleModal = createSelector(uiStore, (ui) => ui.showEditVehicleModal);
export const getShowAddNewUserModal = createSelector(uiStore, (ui) => ui.showAddNewUserModal);
export const getShowAddNewCustomerModal = createSelector(uiStore, (ui) => ui.showAddNewCustomerModal);
export const getShowAddNewLocationModal = createSelector(uiStore, (ui) => ui.showAddNewLocationModal);
export const getShowMapViewModal = createSelector(uiStore, (ui) => ui.showMapView);
export const getMapLocations = createSelector(uiStore, (ui) => ui.mapLocations);
export const getShowLocationPicker = createSelector(uiStore, (ui) => ui.showLocationPicker);
export const getShowSelectVehicleModal = createSelector(uiStore, (ui) => ui.showSelectVehicles);
export const getShowSelectDriversModal = createSelector(uiStore, (ui) => ui.showSelectDrivers);
export const getShowAddDraftOperationModal = createSelector(uiStore, (ui) => ui.showAddDraftOperationModal);

export default appUISlice.reducer;
