import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Row, Space, Switch, Tooltip } from 'antd';
import { EditOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import PageLayout from '../../SharedComponents/PageLayout';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showAddNewDriver, showEditDriver } from '../../store/app-ui.store';
import RSTable from '../../SharedComponents/RSTable';
import { getAllDrivers, getDriverLoading, getDrivers, updateDriverStatus } from '../../store/drivers.store';
import { DEFAULT_PAGE_SIZE } from '../../Utils/constants';
import { useFeature } from '../../Utils/features';
import Driver from '../../Types/Driver';

const getColumns = (updateStatus: any, dispatch: any) => [
  { title: 'License Number', dataIndex: 'licenseNumber', rowKey: 'id' },
  {
    title: 'Name',
    dataIndex: 'fname',
    rowKey: 'id',
    render: (data: any, row: any) => `${row.fname || ''} ${row.lname || ''}`,
  },
  { title: 'Email', dataIndex: 'email', rowKey: 'id' },
  { title: 'Telephone', dataIndex: 'tele', rowKey: 'id' },
  {
    title: 'Active/Inactive',
    render: (data: any, record: Driver) => (
      <Space size="middle">
        <Tooltip title="Active/Inactive Vehicles" placement="bottomRight">
          <Switch
            checked={record.status === 'active'}
            onChange={(checked) => {
              const status = checked ? 'active' : 'inactive';
              updateStatus(record.licenseNumber, status);
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
  {
    title: '',
    render: (data: any, record: Driver) => (
      <Button
        icon={<EditOutlined />}
        onClick={() => {
          dispatch(showEditDriver(record));
        }}
      />
    ),
  },
];

const DriversPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [pageStart, setPageStart] = useState(0);

  const drivers = useAppSelector(getDrivers);
  const loading = useAppSelector(getDriverLoading);

  const [filteredDrivers, setFilteredDrivers] = useState(drivers);

  const canAddDriver = useFeature('add_new_driver');

  const showAddNewDriverModal = () => {
    dispatch(showAddNewDriver(true));
  };

  useEffect(() => {
    setFilteredDrivers(drivers);
  }, [drivers]);

  useEffect(() => {
    dispatch(getAllDrivers({ pageStart, limit: DEFAULT_PAGE_SIZE }));
  }, [pageStart]);

  const filter = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim()) {
      setFilteredDrivers(drivers.filter((d) => String(d.licenseNumber).startsWith(e.target.value)));
    } else {
      setFilteredDrivers(drivers);
    }
  };

  const changePage = (increment: boolean) => {
    if (!increment) {
      setPageStart(pageStart > 0 ? pageStart - DEFAULT_PAGE_SIZE : 0);
    } else {
      setPageStart(pageStart + DEFAULT_PAGE_SIZE);
    }
  };

  const changeStatus = (licenseNumber: string, status: string) => {
    dispatch(updateDriverStatus({ licenseNumber, status }));
  };

  return (
    <PageLayout
      title="Drivers Management"
      actions={[
        canAddDriver && (
          <Button key="add" onClick={showAddNewDriverModal}>
            Add
          </Button>
        ),
      ]}
    >
      <Row>
        <RSTable
          loading={loading}
          onSearch={filter}
          headerTitle="Trips Summary"
          columns={getColumns(changeStatus, dispatch)}
          dataSource={filteredDrivers}
          footer={(pageData) => (
            <Row justify="end">
              <Space size="small">
                <Button icon={<LeftOutlined />} onClick={() => changePage(false)} />
                <Button
                  icon={<RightOutlined />}
                  onClick={() => changePage(true)}
                  disabled={pageData.length < DEFAULT_PAGE_SIZE}
                />
              </Space>
            </Row>
          )}
          rowKey="licenseNumber"
        />
      </Row>
    </PageLayout>
  );
};

export default DriversPage;
