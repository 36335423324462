import { FormLabelAlign } from 'antd/lib/form/interface';

export const BasicFormLayout = {
  labelAlign: 'left' as FormLabelAlign,
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const FullWidthInputFormLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

export const BasicFormTailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};
