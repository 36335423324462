import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';

export type TAutoCompleteOption = {
  key?: string;
  label: React.ReactNode;
  value: string | number;
};

export type TUseAutoComplete = (query: string) => Promise<TAutoCompleteOption[]>;

// eslint-disable-next-line
const useAutoComplete = (autocompleteFn: TUseAutoComplete) => {
  const [options, setOptions] = useState<TAutoCompleteOption[]>([]);

  const fetchOptions = useCallback(autocompleteFn, []);

  const setQuery = debounce(async (value: string) => {
    setOptions(await fetchOptions(value));
  }, 400);

  return { options, setQuery };
};

export default useAutoComplete;
