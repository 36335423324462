import React from 'react';
import { Modal } from 'antd';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getMapLocations, getShowMapViewModal, showMapView } from '../../store/app-ui.store';
import RZMap from '../RZMap';

const MapView = () => {
  const showModal = useAppSelector(getShowMapViewModal);
  const locations = useAppSelector(getMapLocations);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(showMapView({ show: false, locations: [] }));
  };

  return (
    <Modal visible={showModal} title="Locations" onCancel={handleClose} closeIcon onOk={handleClose} destroyOnClose>
      <div style={{ height: 500, width: '100%' }}>
        <RZMap markerPositions={locations} />
      </div>
    </Modal>
  );
};

export default MapView;
