import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import to from 'await-to-js';
import { toast } from 'react-toastify';
import moment from 'moment';
import randomColor from 'randomcolor';
import { RootState } from './store';
import APIService from '../Utils/APIService';
import OperationData from '../Types/OperationData';
import { getUserType, LS_KEYS } from './auth.store';
import { showRouteDetailsModal } from './app-ui.store';
import { TTripData } from '../SharedComponents/Models/AddNewOperation';
import { URLConfig } from '../Utils/features';
import { DEFAULT_PAGE_SIZE } from '../Utils/constants';
import DraftOperation, { Coordinates } from '../Types/DraftOperation';
import { clearSelectedDriversAndVehicles, getMinimalVehicles, getSelectedVehicles } from './drivers.store';

const getRandomColor = (index: number) => {
  if (index < 10) {
    return [
      '#F31559',
      '#6528F7',
      '#539165',
      '#F2BE22',
      '#090580',
      '#0079FF',
      '#0079FF',
      '#C07F00',
      '#9A208C',
      '#AA77FF',
    ][index];
  }
  return randomColor({ luminosity: 'light' });
};

export type OperationSummary = {
  skipped: number;
  complete: number;
  inprogress: number;
  notStarted: number;
  notDelivered: number;
  delivered: number;
};

export interface RouteState {
  loading: boolean;
  routes: OperationData[];
  addXlslLoading: boolean;
  draftOperations: DraftOperation[];
  groupedDraftOperations: DraftOperation[];
  optimizedVehicles: any[];
  selectedRoute: any;
  selectedRouteLoading: boolean;
  analyticsData: any;
  summaryLoading: boolean;
  fetchingGeoCoding: boolean;
  summary: { locations?: OperationSummary; route?: OperationSummary };
  submitLoading: boolean;
}

const initialState = {
  addXlslLoading: false,
  routes: [],
  loading: false,
  summaryLoading: false,
  selectedRoute: {},
  selectedRouteLoading: false,
  analyticsData: null,
  draftOperations: [],
  optimizedVehicles: [],
  groupedDraftOperations: [],
  summary: {},
  fetchingGeoCoding: false,
  submitLoading: false,
} as RouteState;

export const uploadExcel = createAsyncThunk<any, { file: File }>(
  'upload/excel',
  async ({ file }, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    formData.append('file', file);
    const [err, data] = await to(
      APIService.post('user/excel-to-route', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    );
    if (err) {
      toast.error('Something went wrong with your file. Please try again!');
      return rejectWithValue(err);
    }
    toast.success('Successfully Uploaded the Excel File');
    dispatch(
      fetchRoutes({
        start: 0,
        limit: DEFAULT_PAGE_SIZE,
        licenseNo: '',
        startDate: '',
        endDate: '',
        tripName: '',
      }),
    );
    return data;
  },
);

export const addRouteManually = createAsyncThunk<any, TTripData>(
  'routes/add-manually',
  async (routeData, { rejectWithValue, dispatch }) => {
    // convert lng, lat to numbers
    const body = {
      ...routeData,
      locations: routeData.locations.map((value) => ({
        ...value,
        latitude: parseFloat(value.latitude),
        longitude: parseFloat(value.longitude),
      })),
    };
    const [err, data] = await to(
      APIService.post('user/upload-trip-manually/simplified', body, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
    if (err) {
      toast.error('Something went wrong with data. Please try again!');
      return rejectWithValue(err);
    }
    dispatch(
      fetchRoutes({
        start: 0,
        limit: DEFAULT_PAGE_SIZE,
        licenseNo: '',
        startDate: '',
        endDate: '',
        tripName: '',
      }),
    );
    toast.success('Successfully Added the Route');
    return data;
  },
);

export const fetchRoutes = createAsyncThunk<
  any,
  { start: number; limit: number; licenseNo: string; startDate: string; endDate: string; tripName: string }
>('routes/get', async ({ start, limit, licenseNo, startDate, endDate, tripName }, { getState, rejectWithValue }) => {
  const userType = getUserType(getState() as RootState);
  const url = URLConfig.get_recent_routes[userType];

  const [err, data] = await to(
    APIService.get(url, {
      params: { pageStart: start, limit, startDate, endDate, tripName, licenseNo },
    }),
  );
  if (err) {
    toast.error('Routes not loaded. Please try again!');
    rejectWithValue(err);
  }
  return data?.data;
});

export const fetchRouteDetails = createAsyncThunk<any, { tripID: number; licenseNo: string }>(
  'routes/get-details',
  async ({ tripID, licenseNo }, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.get('single-trip-info', { params: { tripID, licenseNo } }));
    if (err) {
      toast.error('Routes not loaded. Please try again!');
      return rejectWithValue(err);
    }
    dispatch(showRouteDetailsModal(true));
    return data?.data;
  },
);

export const sendSMS = createAsyncThunk<any, { telephone: string; tripId: string; licenseNo: string }>(
  'routes/send-sms',
  async ({ telephone, tripId, licenseNo }, { rejectWithValue }) => {
    const [err, data] = await to(
      APIService.post('user/send-sms', {}, { params: { telephone, tripID: tripId, licenseNo } }),
    );
    if (err) {
      toast.error('Unable to send the SMS. Please try again');
      return rejectWithValue(err);
    }
    toast.success('SMS sent successfully!');
    return data?.data;
  },
);

export const updateRouteName = createAsyncThunk<
  any,
  { name: string; tripId: string; plateNumber: string; licenseNo: string }
>('routes/update-name', async ({ name, tripId, licenseNo, plateNumber }, { rejectWithValue }) => {
  const [err] = await to(
    APIService.put('user/recent-routes', {}, { params: { tripName: name, tripID: tripId, licenseNo, plateNumber } }),
  );
  if (err) {
    toast.error('Unable to update trip name. Please try again');
    return rejectWithValue(err);
  }
  toast.success('Update the name successfully!');
  return { name, tripId, licenseNo, plateNumber };
});

export const copyOperations = createAsyncThunk<any, { tripId: string }>(
  'route/copy-operation',
  async ({ tripId }, { rejectWithValue }) => {
    const [err, data] = await to(APIService.get(`user/summary-locations?tripID=${tripId}`));
    if (err) {
      toast.error('Unable to fetch the operation details');
      return rejectWithValue(err);
    }
    return data?.data;
  },
);

export const fetchAnalyticsData = createAsyncThunk<any, { username?: string }>(
  'routes/analytics',
  async ({ username }, { rejectWithValue, getState }) => {
    const userType = getUserType(getState() as RootState);
    const url = URLConfig.getKPI[userType];

    const [err, data] = await to(
      APIService.get(url, { params: userType === 'admin' ? { customerUsername: username } : { username } }),
    );
    if (err) {
      toast.error('Analytics Data is not loaded. Please try again!');
      rejectWithValue(err);
    }
    return data?.data;
  },
);

export const downloadAnalyticsData = createAsyncThunk<any, { username?: string }>(
  'routes/download',
  async ({ username }, { rejectWithValue, getState }) => {
    const userType = getUserType(getState() as RootState);
    const url = URLConfig.downloadKPI[userType];
    const params: { username?: string } = {};
    if (userType === 'customer') {
      params.username = username;
    }
    const [err, data] = await to(APIService.get(url, { params }));
    if (err) {
      toast.error('Analytics Data cannot be downloaded. Please try again!');
      rejectWithValue(err);
    }
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${data?.data}`);

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);

    const fileName = `${localStorage.getItem(LS_KEYS.DISPLAY_NAME)} - ${moment(new Date().toString()).format(
      'DD-MM-YYYY HH-mm-ss',
    )}`;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    setTimeout(() => {
      link.click();
    });
    return data?.data;
  },
);

export const fetchOperationSummary = createAsyncThunk<
  any,
  { licenseNo: string; startDate: string; endDate: string; tripName: string }
>('operations/summary', async ({ startDate, endDate, licenseNo, tripName }, { rejectWithValue }) => {
  const [err, data] = await to(
    APIService.get('user/operations/summary?', {
      params: { startDate, endDate, tripName, licenseNo },
    }),
  );
  if (err) {
    toast.error('Fetching Operation Summary Failed. Please try again');
    return rejectWithValue(err);
  }
  return data?.data;
});

export const fetchGeoCodingForDraftOperations = createAsyncThunk<any, any>(
  'operations/geocoding',
  async (_, { rejectWithValue, getState }) => {
    let draftOperations = getDraftOperations(getState());
    if (draftOperations.length === 0) {
      const localDraftOperations = JSON.parse(localStorage.getItem('draft-operations') || '[]');
      if (localDraftOperations.length !== 0) {
        draftOperations = localDraftOperations;
      }
    }
    if (draftOperations.length === 0) {
      return [];
    }
    const request = draftOperations.map(({ key, address, name, locationId }) => ({
      address: address || null,
      name: name || null,
      location_id: locationId || null,
      location_key: key,
    }));
    const [err, data] = await to(APIService.post('user/geocoding', { address_arr: request }));
    if (err) {
      return rejectWithValue(err);
    }
    try {
      const resultMap = data!.data.reduce((p: any, c: any) => {
        p[c.key] = c;
        return p;
      }, {});

      return draftOperations.map(({ key, ...rest }) => {
        const resultData = resultMap[key!];
        if (resultData) {
          return {
            ...rest,
            key,
            location: {
              lng: Number(resultData.lon),
              lat: Number(resultData.lat),
            },
            status: resultData.status,
          };
        }
        return { key, ...rest, error: true };
      });
    } catch (e) {
      console.log(e);
    }
    return draftOperations;
  },
);

export const submitDraftOperations = createAsyncThunk<any, any>(
  'operation/submit',
  async (startRow, { rejectWithValue, getState, dispatch }) => {
    const draftOperations = getDraftOperations(getState());
    const selectedVehicles = getSelectedVehicles(getState());

    const request = {
      Operations: draftOperations.map((o) => ({
        address: o.address,
        locationId: o.locationId,
        name: o.name,
        capacity: Number(o.capacity),
        value: Number(o.value),
        isStartingRow: o.key === startRow,
        longitude: Number(o.location?.lng),
        latitude: Number(o.location?.lat),
        minWindow: null,
        maxWindow: null,
      })),
      Trips: [
        {
          selectedVehicles: Object.keys(selectedVehicles) || null,
        },
      ],
    };

    const [err, data] = await to(APIService.post('user/operations/bulk-add-multi-route', request));

    if (err) {
      return rejectWithValue(err);
    }
    dispatch(clearSelectedDriversAndVehicles());
    return data!.data;
  },
);

export const submitGroupedOperations = createAsyncThunk<any, any>(
  'operation/submit2',
  async (_, { rejectWithValue, getState }) => {
    const operations = getGroupedDraftOperation(getState());

    const request = operations.map((o) => ({
      address: o.address,
      locationID: o.locationId,
      name: o.name,
      capacity: Number(o.capacity),
      value: Number(o.value),
      longitude: Number(o.location?.lng),
      latitude: Number(o.location?.lat),
      minWindow: null,
      maxWindow: null,

      plateNumber: o.plateNumber,
      wayPointIndex: o.wayPointIndex,
      isStartingRow: o.isStart,
    }));

    const [err, data] = await to(APIService.post('user/operations/bulk-add-individual-route', request));
    if (err) {
      return rejectWithValue(err);
    }
    localStorage.removeItem('draft-operations');
    return data?.data;
  },
);

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setDraftOperations: (state, { payload: { operations, minimalLocations } }) => {
      const locationIdMap = minimalLocations.reduce((p: any, c: any) => {
        p[c.location_id.toString()] = c;
        return p;
      }, {});
      const locationNameMap = minimalLocations.reduce((p: any, c: any) => {
        p[c.location_name && c.location_name.toLowerCase()] = c;
        return p;
      }, {});
      state.draftOperations = operations.map((data: any) => {
        try {
          if (data.Address) {
            data.LocationName = 'N/A';
            data.LocationID = 'N/A';
          } else if (data.LocationID && locationIdMap[data.LocationID]) {
            data.LocationName = locationIdMap[data.LocationID].location_name;
            data.Address = locationIdMap[data.LocationID].address;
          } else if (data.LocationName && locationNameMap[data.LocationName.toLowerCase()]) {
            data.LocationID = locationNameMap[data.LocationName.toLowerCase()].location_id;
            data.Address = locationNameMap[data.LocationName.toLowerCase()].address;
          }
        } catch (e) {
          console.log(e);
        }

        return {
          address: data.Address,
          locationId: data.LocationID,
          name: data.LocationName,
          postalCode: data.PostalCode,
          capacity: data.Capacity,
          value: data.Value,
          minWindow: data.MinWindow,
          maxWindow: data.MaxWindow,
          isStart: false,
          key: data.key,
        };
      });
    },
    updateDraftOperation: (state, { payload }) => {
      const { index, key, data, clearErrors } = payload;

      const operationIndex = state.draftOperations.findIndex((o) => index === o.key);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.draftOperations[operationIndex][key] = data;
      if (clearErrors) {
        state.draftOperations[operationIndex].status = 'OK';
      }
    },
    updateGroupedDraftOperations: (state, { payload }) => {
      const { index, key, data } = payload;
      const operationIndex = state.groupedDraftOperations.findIndex((o) => index === o.key);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.groupedDraftOperations[operationIndex][key] = data;
    },
    clearError: (state, { payload: { index } }) => {
      const operationIndex = state.draftOperations.findIndex((o) => index === o.key);
      state.draftOperations[operationIndex].status = 'OK';
    },
    addDraftOperation: (state, { payload }) => {
      const { address, locationId, name, value, capacity, location } = payload;

      state.draftOperations = [
        ...state.draftOperations,
        {
          address,
          locationId,
          location,
          name,
          value,
          capacity,
          isStart: false,
          status: 'OK',
        },
      ].map((data, index) => ({ ...data, key: `${index + 1}` }));
    },
    addGroupedDraftOperation: (state, { payload }) => {
      const { address, locationId, name, value, capacity, location, plateNumber } = payload;

      state.groupedDraftOperations = [
        ...state.groupedDraftOperations,
        {
          address,
          locationId,
          location,
          name,
          value,
          capacity,
          isStart: false,
          status: 'OK',
          plateNumber,
        },
      ].map((data, index) => ({ ...data, key: `${index + 1}` }));
    },
    removeDraftOperation: (state, { payload }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.draftOperations = state.draftOperations
        .filter((data) => data.key !== payload)
        .map((data, index) => ({
          ...data,
          key: `${index + 1}`,
        }));
    },
    removeGroupedDraftOperation: (state, { payload }) => {
      state.groupedDraftOperations = state.groupedDraftOperations
        .filter((data) => data.key !== payload)
        .map((data, index) => ({
          ...data,
          key: `${index + 1}`,
        }));
    },
    removeGroup: (state, { payload }) => {
      state.groupedDraftOperations = state.groupedDraftOperations
        .filter((data) => data.plateNumber !== payload)
        .map((data, index) => ({
          ...data,
          key: `${index + 1}`,
        }));
    },
    clearDraftOperations: (state) => {
      state.draftOperations = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadExcel.pending, (store) => {
      store.addXlslLoading = true;
    });
    builder.addCase(uploadExcel.rejected, (store) => {
      store.addXlslLoading = false;
    });
    builder.addCase(uploadExcel.fulfilled, (store) => {
      store.addXlslLoading = false;
    });
    builder.addCase(fetchRoutes.pending, (store) => {
      store.loading = true;
    });
    builder.addCase(fetchRoutes.rejected, (store) => {
      store.loading = false;
    });
    builder.addCase(fetchRoutes.fulfilled, (store, { payload }) => {
      store.loading = false;
      store.routes = payload;
    });
    builder.addCase(fetchRouteDetails.pending, (store) => {
      store.selectedRouteLoading = true;
      store.selectedRoute = {};
    });
    builder.addCase(fetchRouteDetails.rejected, (store) => {
      store.selectedRouteLoading = false;
    });
    builder.addCase(fetchRouteDetails.fulfilled, (store, { payload }) => {
      store.selectedRouteLoading = false;
      store.selectedRoute = payload;
    });
    builder.addCase(fetchAnalyticsData.pending, (store) => {
      store.loading = true;
    });
    builder.addCase(fetchAnalyticsData.rejected, (store) => {
      store.loading = false;
    });
    builder.addCase(fetchAnalyticsData.fulfilled, (store, { payload }) => {
      store.loading = false;
      store.analyticsData = payload;
    });
    builder.addCase(updateRouteName.fulfilled, (store, { payload }) => {
      store.routes = store.routes.map((x) =>
        x.trip_id === payload.tripId
          ? { ...x, trip_name: payload.name, license_no: payload.licenseNo, vehicle_number: payload.plateNumber }
          : x,
      );
    });

    builder.addCase(copyOperations.fulfilled, (store, { payload }) => {
      store.draftOperations = payload.map((data: any, index: number) => ({
        error: false,
        address: data.address,
        locationId: data.locationId,
        name: '',
        postalCode: '',
        capacity: '',
        location: {
          lng: data.longitude,
          lat: data.latitude,
        },
        isStart: false,
        value: 0,
        status: 'OK',
        key: `${index + 1}`,
      }));
    });

    builder.addCase(fetchOperationSummary.pending, (store) => {
      store.summaryLoading = true;
    });
    builder.addCase(fetchOperationSummary.rejected, (store) => {
      store.summaryLoading = false;
    });
    builder.addCase(fetchOperationSummary.fulfilled, (store, { payload }) => {
      store.summaryLoading = false;
      store.summary = payload;
    });

    builder.addCase(fetchGeoCodingForDraftOperations.pending, (store) => {
      store.fetchingGeoCoding = true;
    });
    builder.addCase(fetchGeoCodingForDraftOperations.rejected, (store) => {
      store.fetchingGeoCoding = false;
    });
    builder.addCase(fetchGeoCodingForDraftOperations.fulfilled, (store, { payload }) => {
      store.draftOperations = payload;
      store.fetchingGeoCoding = false;
    });
    builder.addCase(submitDraftOperations.pending, (store) => {
      store.submitLoading = true;
    });
    builder.addCase(submitDraftOperations.fulfilled, (store, { payload }) => {
      store.submitLoading = false;
      const groupColors = payload.reduce((p: any, c: any, index: number) => {
        if (!p[c.plateNumber!]) {
          p[c.plateNumber!] = getRandomColor(index);
        }
        return p;
      }, {} as { [key: string]: boolean });
      store.groupedDraftOperations = payload.map((data: any, index: number) => ({
        error: false,
        address: data.address,
        locationId: data.locationID,
        plateNumber: data.plateNumber,
        wayPointIndex: data.wayPointIndex,
        name: data.name,
        capacity: data.capacity,
        location: {
          lng: Number(data.longitude),
          lat: Number(data.latitude),
          mapColor: groupColors[data.plateNumber],
        },
        isStart: data.isStartingRow,
        value: data.value,
        status: 'OK',
        key: `${index + 1}`,
      }));
      const plateNumbers = payload.reduce((p: any, c: any) => {
        p[c.plateNumber] = true;
        return p;
      }, {});
      store.optimizedVehicles = Object.keys(plateNumbers);
    });
    builder.addCase(submitDraftOperations.rejected, (store) => {
      store.submitLoading = false;
    });

    builder.addCase(submitGroupedOperations.pending, (state) => {
      state.submitLoading = true;
    });
    builder.addCase(submitGroupedOperations.rejected, (state) => {
      state.submitLoading = false;
    });

    builder.addCase(submitGroupedOperations.fulfilled, (state) => {
      state.draftOperations = [];
      state.groupedDraftOperations = [];
    });
  },
});

export const {
  setDraftOperations,
  updateDraftOperation,
  removeDraftOperation,
  addDraftOperation,
  clearDraftOperations,
  clearError,
  updateGroupedDraftOperations,
  addGroupedDraftOperation,
  removeGroupedDraftOperation,
  removeGroup,
} = routeSlice.actions;

export const routeStore = (store: RootState): RouteState => store.route;

export const getRoutesLoading = createSelector(routeStore, (routes) => routes.loading);
export const getOperationSubmitLoading = createSelector(routeStore, (routes) => routes.submitLoading);
export const getExcelUploading = createSelector(routeStore, (routes) => routes.addXlslLoading);
export const getRoutes = createSelector(routeStore, (routes) => routes.routes);
export const getSelectedRouteDetails = createSelector(routeStore, (routes) => routes.selectedRoute);
export const getSelectedRouteDetailsLoading = createSelector(routeStore, (routes) => routes.selectedRouteLoading);
export const getRoutesAnalyticsData = createSelector(routeStore, (routes) => routes.analyticsData);
export const getDraftOperations = createSelector(routeStore, (routes) =>
  routes.draftOperations.map((d) => {
    const { location } = d;
    let hasError = false;
    if (!location || !location.lat || !location.lng) {
      hasError = true;
    } else if (d.status !== 'OK') {
      hasError = true;
    }
    return { ...d, error: hasError };
  }),
);

export const getGroupedDraftOperation = createSelector(routeStore, (store) => store.groupedDraftOperations);

export const getGroupedDraftOperations = createSelector(routeStore, getMinimalVehicles, (routes, vehicles) => {
  const groupedByPlateNumber = routes.groupedDraftOperations.reduce((p, c) => {
    if (p[c.plateNumber!]) {
      p[c.plateNumber!].push(c);
    } else {
      p[c.plateNumber!] = [c];
    }
    return p;
  }, {} as { [key: string]: DraftOperation[] });

  const result = Object.keys(groupedByPlateNumber).map((key) => {
    const children = groupedByPlateNumber[key];
    const totalCapacity = children.reduce((p, c) => p + Number(c.capacity), 0);
    return {
      address: key,
      children,
      capacity: totalCapacity,
      value: '',
      type: 'parent',
      readonlyRow: true,
      parentCapacity: vehicles.filter((a) => a.plateNumber === key)[0].vehicleCapacity,
      mapColor: children[0].location?.mapColor,
      key,
    };
  });
  return result;
});
export const getOptimizedVehicles = createSelector(routeStore, (r) => r.optimizedVehicles);
export const getOperationSummaryLoading = createSelector(routeStore, (routes) => routes.summaryLoading);
export const getOperationSummary = createSelector(routeStore, (routes) => routes.summary);

export const isGeoCodingFetching = createSelector(routeStore, (routes) => routes.fetchingGeoCoding);

export const getDraftOperationSummary = createSelector(getDraftOperations, (operations) => {
  const summary = {
    errors: 0,
    capacity: 0,
    locations: 0,
    allLocations: [] as Coordinates[],
  };
  operations.forEach((o) => {
    summary.locations += 1;
    summary.capacity += Number(o.capacity);
    summary.errors += o.error ? 1 : 0;
    summary.allLocations.push(o.location!);
  });
  return summary;
});

export default routeSlice.reducer;
