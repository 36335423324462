import React from 'react';
import { Tag } from 'antd';
import AddressPicker from './Forms/AddressPicker';
import { LocationSearchById, LocationSearchByName } from './Forms/LocationSearch';
import { updateGroupedDraftOperations } from '../store/routes.store';
import MapViewAndErrorTrigger from './MapViewAndErrorTrigger';
import VehicleSwitcher from './VehicleSwitcher';

const getOperationEditingColumns = (updateLocations: any, dispatch: any) => [
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: '35%',
    isEditable: true,
    render: (value: any, rowData: any) => {
      if (rowData.type === 'parent') {
        return (
          <div style={{ overflow: 'hidden' }}>
            {value} <Tag color={rowData.mapColor}>{rowData.children.length}</Tag>
          </div>
        );
      }
      return (
        <div style={{ overflow: 'hidden' }}>
          {value} {rowData.isStart && '(start)'}
        </div>
      );
    },
    editingElement: (value: any, data: any) => (
      <AddressPicker
        onChange={({ lng, lat, address }) => {
          updateLocations(data.key, lng, lat, 'N/A', 'N/A', address, true);
        }}
        value={value}
      />
    ),
  },
  {
    title: 'Location Id',
    dataIndex: 'locationId',
    key: 'locationId',
    width: '10%',
    isEditable: true,
    editingElement: (value: any, data: any) => (
      <LocationSearchById
        loading={false}
        value={value}
        onChange={(locationId: string, locationName: string, address: string, location: any) => {
          updateLocations(data.key, location.lng, location.lat, locationId, locationName, address, true);
        }}
      />
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    isEditable: true,
    editingElement: (value: any, data: any) => (
      <LocationSearchByName
        loading={false}
        value={value}
        onChange={(locationId: string, locationName: string, address: string, location: any) => {
          updateLocations(data.key, location.lng, location.lat, locationId, locationName, address, true);
        }}
      />
    ),
    width: '25%',
  },
  {
    title: 'Capacity',
    key: 'capacity',
    dataIndex: 'capacity',
    width: '10%',
    isEditable: true,
    onValueChange: (index: string, event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateGroupedDraftOperations({ index, key: 'capacity', data: event.target.value }));
    },
    render: (value: string, data: any) => {
      if (data.type === 'parent') {
        const error = value > data.parentCapacity;
        return (
          <div className={error ? 'highlight-error' : ''}>
            {value} / {data.parentCapacity}
          </div>
        );
      }
      return <>{value}</>;
    },
  },
  {
    title: 'Value',
    key: 'value',
    dataIndex: 'value',
    width: '10%',
    isEditable: true,
    onValueChange: (index: string, event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateGroupedDraftOperations({ index, key: 'value', data: event.target.value }));
    },
    render: (value: string, data: any) => <>{data.type === 'parent' ? `${value}` : value}</>,
  },
  {
    title: '',
    key: 'location',
    dataIndex: 'location',
    width: 50,
    className: 'location-btn',
    render: (data: any, rowValues: any) => <MapViewAndErrorTrigger data={data} rowValues={rowValues} />,
  },
  {
    title: '',
    key: 'key',
    dataIndex: 'key',
    width: 50,
    render: (data: any, rowValues: any) => (rowValues.type !== 'parent' ? <VehicleSwitcher data={rowValues} /> : <></>),
  },
];

export default getOperationEditingColumns;
