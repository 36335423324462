import { toast } from 'react-toastify';

import useAutoComplete, { TAutoCompleteOption } from './useAutoComplete';

// Required to use PlaceDetails Service
const mapObj = document.createElement('div');

// eslint-disable-next-line
const useGooglePlaces = () => {
  const autocompleteService = new window.google.maps.places.AutocompleteService();
  const placeService = new window.google.maps.places.PlacesService(mapObj);

  const { options, setQuery } = useAutoComplete(async (query: string) => {
    let places: TAutoCompleteOption[] = [];

    try {
      const { predictions } = await autocompleteService.getPlacePredictions({
        input: query,
        componentRestrictions: { country: 'lk' },
      });

      places = predictions.map((v) => ({
        label: v.description,
        value: v.place_id,
      }));

      return places;
    } catch (err) {
      return [];
    }
  });

  const getPlaceDetails = async (placeID: string): Promise<google.maps.places.PlaceResult | null> => {
    try {
      const placeDetails = await new Promise<google.maps.places.PlaceResult | null>((resolve, reject) => {
        placeService.getDetails({ placeId: placeID }, (place) => {
          if (place) resolve(place);
          else reject(null);
        });
      });

      return placeDetails;
    } catch (err) {
      toast.error('Google Place Details Fetch Failed !');
      return null;
    }
  };

  return { options, setQuery, getPlaceDetails };
};

export default useGooglePlaces;
