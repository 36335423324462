import React, { useEffect, useRef } from 'react';
import { Coordinates } from '../Types/DraftOperation';
import LatLng = google.maps.LatLng;

type MapProps = {
  markerPositions: Coordinates[];
};

const RZMap = ({ markerPositions }: MapProps): JSX.Element => {
  const mapRef = useRef(null);
  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const imports = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
      const { PinElement, AdvancedMarkerElement } = imports;

      if (mapRef.current) {
        const bound = new google.maps.LatLngBounds();

        const map = new window.google.maps.Map(mapRef.current!, {
          center: { lat: -34.397, lng: 150.644 },
          zoom: 8,
          mapId: 'DEMO_MAP',
        });
        markerPositions
          .filter((a) => !!a)
          .forEach(({ lat, lng, draggable, onDragEnd, mapColor }) => {
            const pinBackground = new PinElement({
              background: mapColor,
            });
            // eslint-disable-next-line no-new
            const marker = new AdvancedMarkerElement({
              position: { lat, lng },
              map,
              draggable,
              content: pinBackground.element,
            });
            if (onDragEnd) {
              marker.addListener('dragend', onDragEnd);
            }
            bound.extend(new LatLng({ lat, lng }));
          });

        map.fitBounds(bound);
        if (markerPositions.length === 1) {
          map.setZoom(1);
        }
      }
    })();
  }, []);

  return (
    <div className="map-component">
      <div style={{ height: `400px` }} ref={mapRef} />
    </div>
  );
};

export default RZMap;
