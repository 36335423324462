import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Input, Modal, Row, Space, Tooltip, DatePicker, Tabs, Form } from 'antd';
import moment from 'moment';
import { RightOutlined, SendOutlined, LeftOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons';
import noop from 'lodash.noop';
import { Link, useHistory } from 'react-router-dom';
import PageLayout from '../../SharedComponents/PageLayout';
import RSTable from '../../SharedComponents/RSTable';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showAddNewOperationModal, showUploadOperationCSVModal } from '../../store/app-ui.store';
import {
  copyOperations,
  fetchOperationSummary,
  fetchRouteDetails,
  fetchRoutes,
  getDraftOperations,
  getOperationSummary,
  getOperationSummaryLoading,
  getRoutes,
  getRoutesLoading,
  OperationSummary,
  sendSMS,
  updateRouteName,
} from '../../store/routes.store';
import { DEFAULT_PAGE_SIZE } from '../../Utils/constants';
import { useFeature } from '../../Utils/features';
import AnalyticsCard from '../../SharedComponents/AnalyticsCard';
import { getMinimalDrivers, getMinimalVehicles } from '../../store/drivers.store';
import MinimalDrivers from '../../SharedComponents/Forms/MinimalDrivers';
import MinimalVehicles from '../../SharedComponents/Forms/MinimalVehicles';

const { RangePicker } = DatePicker;

const getColumns = (
  onSendSms: (number: string, licenseNo: string, tripId: string) => Promise<any>,
  changeOperationInfo: (name: string, licenseNo: string, plateNumber: string, tripId: string) => Promise<any>,
  minimalDrivers: any[],
  minimalVehicles: any[],
  cloneOperation: (id: string) => Promise<any>,
) => [
  {
    title: 'Delivery ID',
    dataIndex: 'trip_id',
    rowKey: 'id',
  },
  { title: 'Name', dataIndex: 'trip_name', rowKey: 'trip_name' },
  {
    title: 'Start Time',
    dataIndex: 'start_time',
    render: (date: number) => moment(date * 1000).format('YYYY-MM-DD HH:mm'),
    rowKey: 'date',
  },
  {
    title: 'Vehicle Id',
    dataIndex: 'vehicle_number',
    rowKey: 'vehicle_number',
  },
  {
    title: 'Locations',
    dataIndex: 'number_of_locations',
    rowKey: 'location',
  },
  {
    title: 'License',
    dataIndex: 'license_no',
    rowKey: 'license_no',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    rowKey: 'tripStatus',
  },
  {
    title: '',
    rowKey: 'actions',
    render: (data: any, record: any) => (
      <Space size="middle">
        <Tooltip title="Send Routes to the mobile as SMS" placement="bottomRight">
          <Button
            icon={<SendOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              Modal.confirm({
                title: 'Enter your phone number to send the SMS',
                icon: <SendOutlined />,
                okText: 'Send',
                content: <Input id="phone-number" />,
                onOk: async () => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const input = document.getElementById('phone-number')! as HTMLInputElement;
                  await onSendSms(input.value, record.license_no, record.trip_id);
                },
              });
            }}
          />
        </Tooltip>
        <Tooltip title="Update Name for the Delivery" placement="bottomRight">
          <Button
            icon={<EditOutlined />}
            onClick={(e) => {
              let formRef: any = null;
              e.stopPropagation();
              Modal.confirm({
                title: 'Enter a name for the selected route',
                icon: <EditOutlined />,
                okText: 'Save',
                onCancel: noop,
                content: (
                  <Form
                    labelCol={{ span: 6 }}
                    ref={(r) => {
                      formRef = r;
                    }}
                    initialValues={{
                      name: record.trip_name,
                      driver: record.license_no,
                      vehicle: record.vehicle_number,
                    }}
                  >
                    <Form.Item label="Name" name="name">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Driver" name="driver">
                      <MinimalDrivers minimalDrivers={minimalDrivers} />
                    </Form.Item>
                    <Form.Item label="Vehicle" name="vehicle">
                      <MinimalVehicles minimalVehicle={minimalVehicles} />
                    </Form.Item>
                  </Form>
                ),
                onOk: async () => {
                  const { name, driver, vehicle } = formRef.getFieldsValue();
                  await changeOperationInfo(name, driver, vehicle, record.trip_id);
                },
              });
            }}
          />
        </Tooltip>
        {/* <Tooltip title="Clone Operation" placement="bottomRight"> */}
        {/*  <Button */}
        {/*    icon={<CopyOutlined />} */}
        {/*    onClick={(e) => { */}
        {/*      e.stopPropagation(); */}
        {/*      Modal.confirm({ */}
        {/*        title: 'Do you want to clone this operation?', */}
        {/*        content: 'If you have existing draft operation, it will be discarded. To continue press OK', */}
        {/*        onOk: async () => { */}
        {/*          await cloneOperation(record.trip_id); */}
        {/*        }, */}
        {/*      }); */}
        {/*    }} */}
        {/*  /> */}
        {/* </Tooltip> */}
      </Space>
    ),
  },
];

const Operations = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const routes = useAppSelector(getRoutes);
  const loading = useAppSelector(getRoutesLoading);

  const [filteredRoutes, setRoutes] = useState(routes);
  const [pageStart, setPageStart] = useState(0);
  const [filterLicense, setFilterLicense] = useState('');
  const [filterStart, setFilterStart] = useState('');
  const [filterEnd, setFilterEnd] = useState('');
  const [filterName, setFilterName] = useState('');

  const [showGoToEditing, setGoToEditing] = useState(false);
  const draftOperations = useAppSelector(getDraftOperations);

  const operationSummary = useAppSelector(getOperationSummary);
  const operationSummaryLoading = useAppSelector(getOperationSummaryLoading);

  const minimalDrivers = useAppSelector(getMinimalDrivers);
  const minimalVehicles = useAppSelector(getMinimalVehicles);
  const history = useHistory();

  const addOperation = useFeature('add_operation');

  useEffect(() => {
    if (draftOperations.length === 0) {
      const localDraftOperations = JSON.parse(localStorage.getItem('draft-operations') || '[]');
      if (localDraftOperations.length !== 0) {
        setGoToEditing(true);
      }
    } else {
      setGoToEditing(true);
    }
  }, []);

  useEffect(() => {
    setRoutes(routes);
  }, [routes]);

  useEffect(() => {
    dispatch(
      fetchOperationSummary({
        licenseNo: filterLicense,
        startDate: filterStart,
        endDate: filterEnd,
        tripName: filterName,
      }),
    );
    dispatch(
      fetchRoutes({
        start: pageStart,
        limit: DEFAULT_PAGE_SIZE,
        licenseNo: filterLicense,
        startDate: filterStart,
        endDate: filterEnd,
        tripName: filterName,
      }),
    );
  }, [pageStart, filterLicense, filterStart, filterEnd, filterName]);

  const handleAddNew = () => {
    dispatch(showAddNewOperationModal(true));
  };

  const handleUploadCSV = () => {
    dispatch(showUploadOperationCSVModal(true));
  };

  // const handleGoToEditing = () => {
  //   history.push('/dashboard/operations/edit-operation');
  // };

  const filter = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim()) {
      setRoutes(routes.filter((r) => String(r.trip_id).startsWith(e.target.value)));
    } else {
      setRoutes(routes);
    }
  };

  const sendSms = (number: string, licenseNo: string, tripId: string) =>
    dispatch(sendSMS({ telephone: number, licenseNo, tripId })).unwrap();

  const updateOperationInfo = (name: string, licenseNo: string, plateNumber: string, tripId: string) =>
    dispatch(updateRouteName({ name, licenseNo, plateNumber, tripId }));

  const cloneOperation = async (tripId: string) => {
    await dispatch(copyOperations({ tripId }));
    await history.push('/operations/edit-operation');
  };

  const changePage = (increment: boolean) => {
    if (!increment) {
      setPageStart(pageStart > 0 ? pageStart - DEFAULT_PAGE_SIZE : 0);
    } else {
      setPageStart(pageStart + DEFAULT_PAGE_SIZE);
    }
  };

  const filterElements = (
    <>
      <Col span={5}>
        <Input.Search placeholder="Route Name" onSearch={setFilterName} allowClear />
      </Col>
      <Col span={5}>
        <Input.Search placeholder="Driver License" onSearch={setFilterLicense} allowClear />
      </Col>
      <Col span={8}>
        <RangePicker
          showTime
          onCalendarChange={(dates, dateStrings: string[], info) => {
            if (info.range === 'end') {
              setFilterStart(dateStrings[0]);
              setFilterEnd(dateStrings[1]);
            }
          }}
        />
      </Col>
    </>
  );

  const getSummaryTileValue = (type: 'locations' | 'route', category: keyof OperationSummary): string | number => {
    if (operationSummary && operationSummary[type as 'locations' | 'route']) {
      return operationSummary[type as 'locations' | 'route']?.[category] || '0';
    }
    return 'N/A';
  };

  const getSummaryLocationOutput = () => (
    <div className="d-flex jc-space-around w-100">
      <AnalyticsCard
        value={getSummaryTileValue('locations', 'delivered')}
        description="Delivered"
        isLoading={operationSummaryLoading}
      />
      <AnalyticsCard
        value={getSummaryTileValue('locations', 'notDelivered')}
        description="Not Delivered"
        isLoading={operationSummaryLoading}
      />
      <AnalyticsCard
        value={getSummaryTileValue('locations', 'skipped')}
        description="Skipped"
        isLoading={operationSummaryLoading}
      />
    </div>
  );

  const getSummaryRouteOutput = () => (
    <div className="d-flex jc-space-around w-100">
      <AnalyticsCard
        value={getSummaryTileValue('route', 'complete')}
        description="Delivered"
        isLoading={operationSummaryLoading}
      />
      <AnalyticsCard
        value={getSummaryTileValue('route', 'inprogress')}
        description="Not Delivered"
        isLoading={operationSummaryLoading}
      />
      <AnalyticsCard
        value={getSummaryTileValue('route', 'notStarted')}
        description="Skipped"
        isLoading={operationSummaryLoading}
      />
    </div>
  );

  return (
    <PageLayout
      title="Trips"
      actions={
        addOperation
          ? [
              showGoToEditing ? (
                <Link to="operations/edit-operation">
                  <Button key="go-to-editing">Go To Editing</Button>
                </Link>
              ) : null,
              <Button type="primary" key="upload" onClick={handleUploadCSV}>
                Upload CSV
              </Button>,
            ]
          : []
      }
    >
      <Row>
        <Tabs tabPosition="left" className="w-100">
          <Tabs.TabPane key="routes" tab="Routes">
            {getSummaryRouteOutput()}
          </Tabs.TabPane>
          <Tabs.TabPane key="location" tab="Location">
            {getSummaryLocationOutput()}
          </Tabs.TabPane>
        </Tabs>
        <RSTable
          loading={loading}
          onSearch={filter}
          headerTitle="Trips Summary"
          columns={getColumns(sendSms, updateOperationInfo, minimalDrivers, minimalVehicles, cloneOperation)}
          filterElements={filterElements}
          dataSource={filteredRoutes}
          onRow={(record) => ({
            onClick: () => {
              dispatch(fetchRouteDetails({ tripID: record.trip_id, licenseNo: record.license_no }));
            },
          })}
          footer={(pageData) => (
            <Row justify="end">
              <Space size="small">
                <Button icon={<LeftOutlined />} onClick={() => changePage(false)} />
                <Button
                  icon={<RightOutlined />}
                  onClick={() => changePage(true)}
                  disabled={pageData.length < DEFAULT_PAGE_SIZE}
                />
              </Space>
            </Row>
          )}
          rowKey="trip_id"
        />
      </Row>
    </PageLayout>
  );
};

export default Operations;
