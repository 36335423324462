import React, { useEffect, useState } from 'react';
import { Modal, Form, Select, List, Button, Typography, Space, Card, InputNumber, Popconfirm } from 'antd';
import { CloseOutlined, DeleteFilled } from '@ant-design/icons';
import to from 'await-to-js';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getShowAddNewOperationModal, showAddNewOperationModal } from '../../store/app-ui.store';
import useGooglePlaces from '../../hooks/useGooglePlaces';
import { addRouteManually } from '../../store/routes.store';
import {
  fetchMinimalLicenseAndVehicle,
  getDriverLoading,
  getMinimalDrivers,
  getMinimalVehicles,
} from '../../store/drivers.store';
import SearchableSelector from '../Forms/SearchableSelector';

const { Text, Title } = Typography;
const { Option } = Select;

interface IAddLocationCard {
  onAddLocation: (location: TTripLocationData) => void;
}

const AddLocationCard: React.FC<IAddLocationCard> = ({ onAddLocation }) => {
  const { options, setQuery, getPlaceDetails } = useGooglePlaces();

  const [addLocationFormRef] = Form.useForm<TTripLocationData>();
  const [coords, setCoords] = useState<TTripLocationCoords>();
  const [loading, setLoading] = useState(false);

  const clearForm = () => {
    addLocationFormRef.resetFields();
    setCoords(undefined);
  };

  const handleAddLocation = () => {
    addLocationFormRef.submit();
  };

  const handleAddLocationSubmit = ({ address, Weight_delivered }: TTripLocationData) => {
    if (coords) {
      const latitude = parseFloat(coords.lat).toFixed(8);
      const longitude = parseFloat(coords.lng).toFixed(8);
      const locationData: TTripLocationData = {
        address,
        Weight_delivered,
        latitude,
        longitude,
      };
      onAddLocation(locationData);
      clearForm();
    }
  };

  return (
    <Card size="small">
      <Form
        name="add-location-form"
        form={addLocationFormRef}
        initialValues={addLocationinitValue}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleAddLocationSubmit}
      >
        <Form.Item name="address" label="Address" required rules={addLocationValidations.address}>
          <Select
            showSearch
            placeholder="Address"
            onSearch={setQuery}
            options={options}
            filterOption={false}
            className="w-100"
            onSelect={async (v: any, o: any) => {
              setLoading(true);
              const placeDetails = await getPlaceDetails(v?.toString() || '');

              if (placeDetails?.geometry) {
                const lat = String(placeDetails.geometry.location?.lat());
                const lng = String(placeDetails.geometry.location?.lng());
                setCoords({ lng, lat });
                addLocationFormRef.setFieldsValue({ address: o.label?.toString() });
              }
              setLoading(false);
            }}
            allowClear
            clearIcon={<CloseOutlined />}
            onClear={() => {
              setCoords(undefined);
            }}
          />
          {coords && (
            <Space>
              <Text type="secondary">Lat : {parseFloat(coords?.lat).toFixed(8)}</Text>
              <Text type="secondary">Lng : {parseFloat(coords?.lng).toFixed(8)}</Text>
            </Space>
          )}
        </Form.Item>
        <Form.Item
          name="Weight_delivered"
          label="Weight Delivered"
          required
          rules={addLocationValidations.Weight_delivered}
        >
          <InputNumber className="w-100" placeholder="Weight Delivered" addonAfter="KG" />
        </Form.Item>
      </Form>
      <Space className="w-100 justify-end">
        <Button disabled={coords === undefined} loading={loading} type="primary" onClick={handleAddLocation}>
          Add
        </Button>
      </Space>
    </Card>
  );
};

const AddNewOperation = (): JSX.Element => {
  const addNewOperationModalVisibility = useAppSelector(getShowAddNewOperationModal);
  const dispatch = useAppDispatch();
  const [formRef] = Form.useForm<TTripBasicData>();
  const [locations, setLocations] = useState<TTripLocationData[]>([]);
  const [loading, setLoading] = useState(false);

  const dataLoading = useAppSelector(getDriverLoading);
  const minimalVehicle = useAppSelector(getMinimalVehicles);
  const minimalDrivers = useAppSelector(getMinimalDrivers);

  const handleOk = () => {
    formRef.submit();
  };

  useEffect(() => {
    if (addNewOperationModalVisibility) {
      dispatch(fetchMinimalLicenseAndVehicle({}));
    }
  }, [addNewOperationModalVisibility]);

  const handleCancel = () => {
    dispatch(showAddNewOperationModal(false));
    setLocations([]);
    formRef.resetFields();
  };

  const handleSubmit = async (values: TTripBasicData) => {
    const tripData: TTripData = { ...values, locations };
    setLoading(true);
    const [err, data] = await to(dispatch(addRouteManually(tripData)).unwrap());
    if (!err && data) {
      dispatch(showAddNewOperationModal(false));
      formRef.resetFields();
    }
    setLoading(false);
  };

  const handleAddLocation = (newLocation: TTripLocationData) => {
    setLocations([...locations, newLocation]);
  };

  const handleDeleteLocation = (index: number) => {
    setLocations(locations.filter((v, i) => i !== index));
  };

  return (
    <Modal
      title="Add New Operation"
      visible={addNewOperationModalVisibility}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form
        form={formRef}
        name="login-form"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={handleSubmit}
        initialValues={initValue}
      >
        <Form.Item name="licenseNo" label="License No." required rules={validations.licenseNo}>
          <SearchableSelector loading={dataLoading} placeholder="Search to Select">
            {minimalDrivers.map(({ name, license_number }) => (
              <Option key={license_number} value={license_number}>
                {name}
              </Option>
            ))}
          </SearchableSelector>
        </Form.Item>
        <Form.Item name="vehicleNo" label="Vehicle No." required rules={validations.vehicleNo}>
          <SearchableSelector loading={dataLoading} placeholder="Search to Select">
            {minimalVehicle.map(({ plateNumber }) => (
              <Option key={plateNumber} value={plateNumber}>
                {plateNumber}
              </Option>
            ))}
          </SearchableSelector>
        </Form.Item>
      </Form>
      <Space direction="vertical" className="w-100">
        <AddLocationCard onAddLocation={handleAddLocation} />
        <List
          bordered
          style={{ height: 240, overflow: 'auto' }}
          dataSource={locations}
          size="small"
          renderItem={(item, index) => (
            <List.Item>
              <Space direction="vertical" size={1} className="w-100">
                <Space className="w-100 justify-space-between align-start">
                  <Title level={5}>{item.address}</Title>
                  <Title level={5}>{item.Weight_delivered}kg</Title>
                </Space>
                <Text type="secondary">
                  Longitude : {item.longitude}, Latitude : {item.latitude}
                </Text>
                <Space className="w-100 justify-space-between pd-t-5">
                  <Button
                    size="small"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://maps.google.com?q=${item.latitude},${item.longitude}`}
                    key={`show-map-button-${index}`}
                    type="link"
                  >
                    Show on Map
                  </Button>
                  <Popconfirm
                    title="Are you sure to delete this location ?"
                    onConfirm={() => handleDeleteLocation(index)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="small" type="primary" key={`edit-button-${index}`} icon={<DeleteFilled />} danger />
                  </Popconfirm>
                </Space>
              </Space>
            </List.Item>
          )}
        />
      </Space>
    </Modal>
  );
};

type TTripBasicData = {
  licenseNo: string;
  vehicleNo: string;
};

type TTripLocationCoords = {
  lat: string;
  lng: string;
};

type TTripLocationData = {
  address: string;
  Weight_delivered: number;
  longitude: string;
  latitude: string;
};

export type TTripData = TTripBasicData & {
  locations: TTripLocationData[];
};

const initValue: TTripData = {
  licenseNo: '',
  vehicleNo: '',
  locations: [],
};

const validations = {
  licenseNo: [{ required: true, message: 'Please enter the license number' }],
  vehicleNo: [{ required: true, message: 'Please enter the vehicle number' }],
};

const addLocationinitValue: TTripLocationData = {
  address: '',
  Weight_delivered: 0,
  latitude: '',
  longitude: '',
};

const addLocationValidations = {
  address: [{ required: true, message: 'Please enter the address' }],
  Weight_delivered: [{ required: true, message: 'Please enter the weight delivered' }],
};

export default AddNewOperation;
