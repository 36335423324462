import React, { useEffect } from 'react';
import { Input, Modal, Form, Select, Row, Col, FormInstance } from 'antd';
import { AppstoreAddOutlined, BranchesOutlined, NumberOutlined, StockOutlined } from '@ant-design/icons';
import to from 'await-to-js';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getShowAddNewVehicleModal,
  getShowEditVehicleModal,
  showAddNewVehicle,
  showEditVehicle,
} from '../../store/app-ui.store';
import Vehicle from '../../Types/Vehicle';
import { addNewVehicle, editVehicle } from '../../store/vehicle.store';

const VehicleForm = ({
  formRef,
  handleSubmit,
  isEditing,
}: {
  formRef: FormInstance;
  handleSubmit: (values: any) => void;
  isEditing: boolean;
}) => (
  <Form layout="vertical" form={formRef} name="login-form" onFinish={handleSubmit} initialValues={initValue}>
    <Form.Item label="Plate Number :" name="plateNumber" rules={validations.plateNumber}>
      <Input disabled={isEditing} placeholder="Plate Number" prefix={<NumberOutlined />} />
    </Form.Item>
    <Row justify="space-between">
      <Col span={10}>
        <Form.Item label="Type :" name="type" rules={validations.type}>
          <Select>
            <Select.Option value="2wheels">2 Wheels</Select.Option>
            <Select.Option value="3wheels">3 Wheels</Select.Option>
            <Select.Option value="4wheels-van">4 Wheels Van</Select.Option>
            <Select.Option value="4wheels-lorries">4 Wheels Lorry</Select.Option>
            <Select.Option value="carrier">Carrier</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item label="Fuel Type :" name="fuelType" rules={validations.fuelType}>
          <Select>
            <Select.Option value="petrol">Petrol</Select.Option>
            <Select.Option value="diesel">Diesel</Select.Option>
            <Select.Option value="electric">Electric</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>

    <Form.Item label="Millage :" name="mileage" rules={validations.mileage}>
      <Input placeholder="Mileage" prefix={<BranchesOutlined />} addonAfter="KM" />
    </Form.Item>
    <Form.Item label="Fuel Efficiency :" name="fuelEfficiency" rules={validations.fuelEfficiency}>
      <Input placeholder="Fuel Efficiency" prefix={<StockOutlined />} addonAfter="KM/L" />
    </Form.Item>

    <Row justify="space-between">
      <Col span={10}>
        <Form.Item label="Capacity :" name="capacity" rules={validations.capacity}>
          <Input placeholder="Capacity" prefix={<AppstoreAddOutlined />} addonAfter="KG" />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item label="Volume :" name="volume" rules={validations.volume}>
          <Input placeholder="Capacity" prefix={<AppstoreAddOutlined />} addonAfter="m^3" />
        </Form.Item>
      </Col>
    </Row>
  </Form>
);

const AddNewVehicle = (): JSX.Element => {
  const addNewVehicleModalVisibility = useAppSelector(getShowAddNewVehicleModal);
  const dispatch = useAppDispatch();
  const [formRef] = Form.useForm();

  const handleOk = () => {
    formRef.submit();
  };

  const handleCancel = () => {
    dispatch(showAddNewVehicle(false));
  };

  const handleSubmit = async (values: Vehicle) => {
    const [err] = await to(
      dispatch(
        addNewVehicle({
          type: values.type,
          plateNumber: values.plateNumber,
          capacity: parseFloat(values.capacity.toString()),
          fuelType: values.fuelType,
          mileage: parseFloat(values.mileage.toString()),
          fuelEfficiency: parseFloat(values.fuelEfficiency.toString()),
          volume: parseFloat(values.volume.toString()),
        }),
      ),
    );
    if (!err) {
      formRef.resetFields();
    }
  };

  return (
    <Modal
      visible={addNewVehicleModalVisibility}
      onOk={handleOk}
      title="Add New Vehicle"
      destroyOnClose
      onCancel={handleCancel}
    >
      <VehicleForm formRef={formRef} handleSubmit={handleSubmit} isEditing={false} />
    </Modal>
  );
};

export const EditVehicle = () => {
  const editVehicleData = useAppSelector(getShowEditVehicleModal);
  const dispatch = useAppDispatch();
  const [formRef] = Form.useForm();

  const handleOk = () => {
    formRef.submit();
  };

  const handleCancel = () => {
    dispatch(showEditVehicle(null));
  };

  const handleSubmit = async (values: Vehicle) => {
    const [err] = await to(
      dispatch(
        editVehicle({
          type: values.type,
          plateNumber: values.plateNumber,
          capacity: parseFloat(values.capacity.toString()),
          fuelType: values.fuelType,
          mileage: parseFloat(values.mileage.toString()),
          fuelEfficiency: parseFloat(values.fuelEfficiency.toString()),
          volume: parseFloat(values.volume.toString()),
        }),
      ),
    );
    if (!err) {
      formRef.resetFields();
    }
  };

  useEffect(() => {
    if (editVehicleData) {
      formRef.setFieldsValue(editVehicleData);
    }
  }, [editVehicleData]);

  return (
    <Modal visible={!!editVehicleData} onOk={handleOk} title="Edit Vehicle" destroyOnClose onCancel={handleCancel}>
      <VehicleForm formRef={formRef} handleSubmit={handleSubmit} isEditing />
    </Modal>
  );
};

const initValue: Vehicle = {
  type: '2wheels',
  fuelEfficiency: 0,
  fuelType: 'petrol',
  capacity: 0,
  mileage: 0,
  plateNumber: '',
  volume: 0,
};

const validations = {
  type: [{ required: true }],
  fuelEfficiency: [{ required: true }],
  fuelType: [{ required: true }],
  capacity: [],
  mileage: [],
  volume: [],
  plateNumber: [{ required: true }],
};

export default AddNewVehicle;
