import React, { ChangeEvent, useEffect, useRef, useState, useCallback } from 'react';
import { Avatar, Button, Col, Input, Row, Space, Form, Spin, Badge, Modal, Slider } from 'antd';
import { CameraOutlined, UserOutlined } from '@ant-design/icons';
import { RuleType } from 'rc-field-form/lib/interface';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import to from 'await-to-js';
import PageLayout from '../../SharedComponents/PageLayout';
import AuthUser from '../../Types/AuthUser';
import { BasicFormLayout, BasicFormTailLayout } from '../../Utils/FormLayouts';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
// import 'react-image-crop/dist/ReactCrop.css';

import {
  getProfile,
  getProfileLoading,
  getUserLogo,
  isUpdatingProfile,
  requestProfile,
  updateImage,
  updateProfile,
} from '../../store/auth.store';
import { useFeature } from '../../Utils/features';
import getCroppedImg from '../../Utils/images';

const Profile = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [formRef] = Form.useForm<AuthUser>();
  const loading = useAppSelector(getProfileLoading);
  const updating = useAppSelector(isUpdatingProfile);
  const profile = useAppSelector(getProfile);
  const userLogo = useAppSelector(getUserLogo);

  const [imageEditor, showImageEditor] = useState(false);
  const [image, setImage] = useState<string | null | ArrayBuffer>(null);
  const [cropper, setCropper] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<null | Area>(null);

  const imageRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(requestProfile());
  }, []);

  useEffect(() => {
    formRef.setFieldsValue(profile);
  }, [profile]);

  const onSubmit = (values: AuthUser) => {
    dispatch(updateProfile(values));
  };

  const handleUpdateImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImage(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      showImageEditor(true);
      setCropper(false);
      setTimeout(() => {
        setCropper(true);
      }, 100);
    }
  };

  const canUpdateImage = useFeature('upload_image');

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaP: Area) => {
    setCroppedAreaPixels(croppedAreaP);
  }, []);

  const onCancel = () => {
    setImage(null);
    showImageEditor(false);
    imageRef.current!.value = '';
  };

  const onOK = async () => {
    try {
      const dataURI = await getCroppedImg(image as string, croppedAreaPixels!, 0);
      if (dataURI) {
        const [err] = await to(dispatch(updateImage({ image: dataURI })).unwrap());
        if (!err) {
          showImageEditor(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <PageLayout title="Profile">
      <Modal title="Crop and Upload your image" visible={imageEditor} onCancel={onCancel} onOk={onOK} destroyOnClose>
        {image && cropper && (
          <div>
            <div style={{ height: 300, position: 'relative' }}>
              <Cropper
                image={image?.toString()}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <Slider min={1} step={0.2} max={7} onChange={setZoom} value={zoom} />
          </div>
        )}
      </Modal>

      <Spin spinning={loading} size="large">
        <Space direction="vertical" size={30} className="w-100">
          <Row justify="center">
            <Col>
              <label id="profile-picture-input" htmlFor="profile-picture-input-control">
                <Badge
                  count={canUpdateImage ? <CameraOutlined style={{ fontSize: '20px', color: '#fff' }} /> : 0}
                  title="Upload a new image"
                  offset={[-25, 145]}
                  dot={!canUpdateImage}
                  style={{
                    backgroundColor: '#1890ff',
                    padding: '10px',
                    borderRadius: 999,
                    boxShadow: '0px 2px 4px #33333344',
                  }}
                >
                  <Avatar size={160} icon={<UserOutlined />} src={userLogo} />
                </Badge>
                {canUpdateImage && (
                  <input
                    id="profile-picture-input-control"
                    accept="image/*"
                    style={{ display: 'none' }}
                    type="file"
                    ref={imageRef}
                    multiple={false}
                    onChange={handleUpdateImage}
                  />
                )}
              </label>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 12 }}>
              <Form form={formRef} initialValues={initValue} onFinish={onSubmit} {...BasicFormLayout}>
                <Form.Item label="Name" name="name" rules={validations.name}>
                  <Input />
                </Form.Item>
                <Form.Item label="E-Mail" name="email" rules={validations.email}>
                  <Input />
                </Form.Item>
                <Form.Item label="Phone" name="tele" rules={validations.telephone}>
                  <Input />
                </Form.Item>
                <Form.Item label="House / Apartment No." name="addressNO" rules={validations.addressNO}>
                  <Input />
                </Form.Item>
                <Form.Item label="Street Address" name="addressStreet" rules={validations.addressStreet}>
                  <Input />
                </Form.Item>
                <Form.Item label="Town" name="addressTown" rules={validations.addressTown}>
                  <Input />
                </Form.Item>
                <Form.Item {...BasicFormTailLayout}>
                  <Button loading={updating} htmlType="submit" type="primary">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Space>
      </Spin>
    </PageLayout>
  );
};

const initValue: AuthUser = {
  name: '',
  email: '',
  addressNO: '',
  addressStreet: '',
  addressTown: '',
  tele: '',
};

const validations = {
  name: [{ required: true }],
  email: [{ required: true }, { type: 'email' as RuleType }],
  addressNO: [{ required: true }],
  addressStreet: [{ required: true }],
  addressTown: [{ required: true }],
  telephone: [{ required: true }],
};

export default Profile;
