import React from 'react';
import AddNewOperation from './AddNewOperation';
import UploadOperationCsv from './UploadOperationCSV';
import RouteDetails from './RouteDetails';
import AddNewDriver, { EditDriver } from './AddNewDriver';
import AddNewVehicle, { EditVehicle } from './AddNewVehicle';
import AddNewUser from './AddNewUser';
import AddNewCustomer from './AddNewCustomer';
import TOCModal from './TOCModal';
import MapView from './MapView';
import SelectVehicleModal from './SelectVehicleModal';
import DraftOperationModal from './DraftOperationModal';
import AddNewLocation from './AddNewLocation';
import LocationPicker from './LocationPicker';
import SelectDriverModal from './SelectDriversModal';

const ModalsContainer = (): JSX.Element => (
  <>
    <TOCModal />
    <AddNewOperation />
    <UploadOperationCsv />
    <RouteDetails />
    <AddNewDriver />
    <AddNewVehicle />
    <AddNewUser />
    <AddNewCustomer />
    <MapView />
    <SelectVehicleModal />
    <SelectDriverModal />
    <DraftOperationModal />
    <AddNewLocation />
    <LocationPicker />
    <EditDriver />
    <EditVehicle />
  </>
);

export default ModalsContainer;
