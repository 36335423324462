import React, { useEffect, useState } from 'react';
import { Button, Col, message, Modal, Row, Space, Spin, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import to from 'await-to-js';
import PageLayout from '../../SharedComponents/PageLayout';
import AntEditingTable from '../../SharedComponents/RSEditableTable/AntEditingTable';
import AnalyticsCard from '../../SharedComponents/AnalyticsCard';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  clearDraftOperations,
  fetchGeoCodingForDraftOperations,
  getDraftOperations,
  getDraftOperationSummary,
  getOperationSubmitLoading,
  isGeoCodingFetching,
  removeDraftOperation,
  submitDraftOperations,
  updateDraftOperation,
} from '../../store/routes.store';
import { showAddDraftOperationModal, showMapView, showSelectVehicleModal } from '../../store/app-ui.store';
import AddressPicker from '../../SharedComponents/Forms/AddressPicker';
import { LocationSearchById, LocationSearchByName } from '../../SharedComponents/Forms/LocationSearch';
import {
  clearSelectedDriversAndVehicles,
  fetchMinimalVehicle,
  getSelectedVehicles,
  getSelectedVehiclesCapacity,
} from '../../store/drivers.store';
import { fetchMinimalLocations } from '../../store/location.store';
import MapViewAndErrorTrigger from '../../SharedComponents/MapViewAndErrorTrigger';

const OperationEditingPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [startRow, setStartRow] = useState<string | number>();
  const [showErrorsOnly, setShowErrorsOnly] = useState(false);

  const geoCodeLoading = useAppSelector(isGeoCodingFetching);
  const submitLoading = useAppSelector(getOperationSubmitLoading);

  useEffect(() => {
    dispatch(fetchGeoCodingForDraftOperations(0));
    dispatch(fetchMinimalLocations(0));
    dispatch(fetchMinimalVehicle(0));
  }, []);

  const updateLocations = (
    key: number,
    lng: number,
    lat: number,
    locationId: string,
    name: string,
    address: string,
    clearErrors?: boolean,
  ) => {
    dispatch(updateDraftOperation({ index: key, key: 'location', data: { lng: Number(lng), lat: Number(lat) } }));
    dispatch(updateDraftOperation({ index: key, key: 'address', data: address }));
    dispatch(updateDraftOperation({ index: key, key: 'locationId', data: locationId }));
    dispatch(updateDraftOperation({ index: key, key: 'name', data: name, clearErrors }));
  };

  const columns = [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '30%',
      isEditable: true,
      editingElement: (value: any, data: any) => (
        <AddressPicker
          onChange={({ lng, lat, address }) => {
            updateLocations(data.key, lng, lat, 'N/A', 'N/A', address, true);
          }}
          value={value}
        />
      ),
    },
    {
      title: 'Location Id',
      dataIndex: 'locationId',
      key: 'locationId',
      width: '10%',
      isEditable: true,
      editingElement: (value: any, data: any) => (
        <LocationSearchById
          loading={false}
          value={value}
          onChange={(locationId: string, locationName: string, address: string, location: any) => {
            updateLocations(data.key, location.lng, location.lat, locationId, locationName, address, true);
          }}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      isEditable: true,
      editingElement: (value: any, data: any) => (
        <LocationSearchByName
          loading={false}
          value={value}
          onChange={(locationId: string, locationName: string, address: string, location: any) => {
            updateLocations(data.key, location.lng, location.lat, locationId, locationName, address, true);
          }}
        />
      ),
      width: '25%',
    },
    {
      title: 'Capacity',
      key: 'capacity',
      dataIndex: 'capacity',
      width: '10%',
      isEditable: true,
      onValueChange: (index: string, event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateDraftOperation({ index, key: 'capacity', data: event.target.value }));
      },
    },
    {
      title: 'Value',
      key: 'value',
      dataIndex: 'value',
      width: '10%',
      isEditable: true,
      onValueChange: (index: string, event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateDraftOperation({ index, key: 'value', data: event.target.value }));
      },
    },
    {
      title: '',
      key: 'location',
      dataIndex: 'location',
      width: 50,
      className: 'location-btn',
      render: (data: any, rowValues: any) => <MapViewAndErrorTrigger data={data} rowValues={rowValues} />,
    },
  ];

  const data = useAppSelector(getDraftOperations);
  const summary = useAppSelector(getDraftOperationSummary);
  const selectedVehicleCap = useAppSelector(getSelectedVehiclesCapacity);

  const selectedVehicles = useAppSelector(getSelectedVehicles);

  useEffect(() => {
    localStorage.setItem('draft-operations', JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    if (summary.errors === 0) {
      setShowErrorsOnly(false);
    }
  }, [summary.errors]);

  const openAddDraftOperation = () => {
    dispatch(showAddDraftOperationModal(true));
  };

  const openViewAllLocationMap = () => {
    dispatch(
      showMapView({
        show: true,
        locations: data.map((a) => a.location).filter((a) => a && a.lat && a.lng),
      }),
    );
  };
  const openSelectVehicleModal = () => {
    dispatch(showSelectVehicleModal(true));
  };

  const submitLocations = async () => {
    const [err] = await to(dispatch(submitDraftOperations(startRow)).unwrap());
    if (err) {
      return Modal.error({ title: 'Failed to add operations', content: err.message });
    }
    message.success('Validation Success');
    return history.push('/operations/edit-operation-with-grouping');
  };

  const discardEditing = () => {
    Modal.warn({
      title: 'Discard Changes?',
      content: 'Are you sure you want to discard the changes?',
      onOk: () => {
        localStorage.removeItem('draft-operations');
        dispatch(clearDraftOperations());
        dispatch(clearSelectedDriversAndVehicles());
        history.goBack();
      },
      okCancel: true,
    });
  };

  const renderSubmitButton = () => {
    let tooltip = '';
    if (summary.errors > 0) {
      tooltip = 'You have to clear all the error rows';
    } else if (Object.keys(selectedVehicles).length === 0) {
      tooltip = 'You have to select at least one vehicle';
    } else if (!startRow) {
      tooltip = 'Please select a starting location';
    }
    return (
      <Row>
        <Space>
          {tooltip ? (
            <Tooltip title={tooltip}>
              <Button type="primary" block disabled>
                Submit
              </Button>
            </Tooltip>
          ) : (
            <Button type="primary" block onClick={submitLocations}>
              Submit
            </Button>
          )}
        </Space>
      </Row>
    );
  };

  return (
    <PageLayout
      title="Update Draft Operation"
      actions={[
        <Button key="2" onClick={discardEditing} danger>
          Discard
        </Button>,
        <Button key="1" onClick={() => history.goBack()}>
          Back
        </Button>,
      ]}
    >
      <Spin
        spinning={geoCodeLoading || submitLoading}
        tip={submitLoading ? 'Please wait, we are processing...' : 'Geo Codes Loading'}
      >
        <Row>
          <div className="d-flex jc-space-around w-100 pd-b-10">
            <Col>
              <Row>
                <AnalyticsCard smaller value={`${summary.locations}`} description="Total Locations" />
                <AnalyticsCard smaller value={`${summary.capacity}`} description="Total Capacity" />
                <AnalyticsCard smaller value={startRow || 'N/A'} description="Start Row" />
              </Row>
              <Row>
                <AnalyticsCard smaller value={`${selectedVehicleCap}`} description="Vehicle Capacity" />
                <AnalyticsCard smaller value={`${summary.errors}`} description="Error Rows" type="error" />
              </Row>
            </Col>

            <div className="d-flex f-col jc-space-around">
              <Space direction="horizontal" className="jc-space-between">
                <Button onClick={openViewAllLocationMap} block>
                  Show All Locations
                </Button>
                <Button onClick={openSelectVehicleModal} block>
                  Select Vehicle
                </Button>
                {/* <Button onClick={openSelectDriverModal} block> */}
                {/*  Select Driver */}
                {/* </Button> */}
              </Space>
              <div className="d-flex jc-center">
                <Space direction="horizontal">
                  <Button block onClick={() => setShowErrorsOnly(!showErrorsOnly)} disabled={summary.errors === 0}>
                    {showErrorsOnly ? 'Show All Rows' : 'Show only Error Rows'}
                  </Button>
                  <Button block onClick={openAddDraftOperation}>
                    Add Operation
                  </Button>
                </Space>
              </div>
            </div>
            {renderSubmitButton()}
          </div>
          <AntEditingTable
            extraButtons={(key: string) => (
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={() => {
                  if (key === startRow) {
                    setStartRow('');
                  }
                  dispatch(removeDraftOperation(key));
                }}
              />
            )}
            columns={columns}
            dataSource={showErrorsOnly ? data.filter((a) => a.error) : data}
            scroll={{ y: 480 }}
            styles={{}}
            rowSelection={{
              selectedRowKeys: startRow ? [startRow] : [],
              type: 'radio',
              onChange: (selectedRowKeys: React.Key[]) => {
                setStartRow(selectedRowKeys[0]);
              },
            }}
          />
        </Row>
      </Spin>
    </PageLayout>
  );
};

export default OperationEditingPage;
