import React from 'react';
import { Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useGooglePlaces from '../../hooks/useGooglePlaces';

type Props = {
  value: string;
  onChange: (value: any) => void;
};

const AddressPicker = ({ value, onChange }: Props) => {
  const { options, setQuery, getPlaceDetails } = useGooglePlaces();

  const onSelect = async (v: any, o: any) => {
    const placeDetails = await getPlaceDetails(v?.toString() || '');

    if (placeDetails?.geometry) {
      const lat = String(placeDetails.geometry.location?.lat());
      const lng = String(placeDetails.geometry.location?.lng());
      onChange({ lng, lat, address: o.label });
    }
  };

  return (
    <Select
      showSearch
      placeholder="Address"
      onSearch={setQuery}
      options={options}
      value={value}
      filterOption={false}
      className="w-100"
      onSelect={onSelect}
      allowClear
      clearIcon={<CloseOutlined />}
      onClear={() => {
        // setCoords(undefined);
      }}
    />
  );
};

export default AddressPicker;
