import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import to from 'await-to-js';
import { toast } from 'react-toastify';
import { RootState } from './store';
import APIService from '../Utils/APIService';
import { showAddNewVehicle, showEditVehicle } from './app-ui.store';
import { DEFAULT_PAGE_SIZE } from '../Utils/constants';
import Vehicle from '../Types/Vehicle';

export interface VehicleState {
  loading: boolean;
  error: boolean;
  vehicles: Vehicle[];
}

const initialState = {
  vehicles: [],
  loading: false,
  error: false,
} as VehicleState;

export const getAllVehicles = createAsyncThunk<any, { pageStart: number; limit: number }>(
  'vehicles/get-all',
  async (arg, { rejectWithValue }) => {
    const [err, data] = await to(APIService.get('user/vehicle', { params: arg }));
    if (err) {
      toast.error('Something went wrong!. Could not fetch the vehicles');
      return rejectWithValue(err);
    }
    return data?.data;
  },
);

export const addNewVehicle = createAsyncThunk<any, Vehicle>(
  'vehicles/create-new',
  async (vehicle, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.post('/user/vehicle', vehicle));
    if (err) {
      toast.error(`${err.message || 'Something went wrong!'}. Could not create the vehicle`);
      return rejectWithValue(err);
    }
    toast.success('Successfully added the vehicle');
    dispatch(showAddNewVehicle(false));
    dispatch(getAllVehicles({ pageStart: 0, limit: DEFAULT_PAGE_SIZE }));
    return data?.data;
  },
);

export const editVehicle = createAsyncThunk<any, Vehicle>(
  'vehicles/edit-vehicle',
  async (vehicle, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.put('/user/vehicle', vehicle));
    if (err) {
      toast.error(`${err.message || 'Something went wrong!'}. Could not update the vehicle`);
      return rejectWithValue(err);
    }
    toast.success('Successfully edited the vehicle');
    dispatch(showEditVehicle(null));
    dispatch(getAllVehicles({ pageStart: 0, limit: DEFAULT_PAGE_SIZE }));
    return data?.data;
  },
);

export const updateVehicleStatus = createAsyncThunk<any, { plateNumber: string; status: string }>(
  'vehicles/update-status',
  async (updates, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.put('/user/vehicle/change-status', updates));
    if (err) {
      toast.error(`${err.message || err}. Could not update the status`);
      return rejectWithValue(err);
    }
    toast.success('Successfully updated');
    dispatch(getAllVehicles({ pageStart: 0, limit: DEFAULT_PAGE_SIZE }));
    return data?.data;
  },
);

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllVehicles.pending, (store) => {
      store.loading = true;
      store.error = false;
    });
    builder.addCase(getAllVehicles.rejected, (store) => {
      store.loading = false;
      store.error = true;
    });
    builder.addCase(getAllVehicles.fulfilled, (store, { payload }) => {
      store.loading = false;
      store.vehicles = payload;
    });
    builder.addCase(updateVehicleStatus.pending, (store) => {
      store.loading = true;
    });
    builder.addCase(updateVehicleStatus.rejected, (store) => {
      store.loading = false;
    });
  },
});

// export const {  } = vehicleSlice.actions;

export const vehicleStore = (store: RootState): VehicleState => store.vehicle;

export const getVehicleLoading = createSelector(vehicleStore, (store) => store.loading);
export const getVehicles = createSelector(vehicleStore, (store) => store.vehicles);

export default vehicleSlice.reducer;
