import React from 'react';
import { Descriptions, Modal, Spin, Timeline } from 'antd';
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getShowRouteDetailsModal, showRouteDetailsModal } from '../../store/app-ui.store';
import { getSelectedRouteDetails, getSelectedRouteDetailsLoading } from '../../store/routes.store';

const RouteDetails = (): JSX.Element => {
  const showModal = useAppSelector(getShowRouteDetailsModal);
  const routeDetails = useAppSelector(getSelectedRouteDetails);
  const loading = useAppSelector(getSelectedRouteDetailsLoading);

  const dispatch = useAppDispatch();
  const close = () => {
    dispatch(showRouteDetailsModal(false));
  };

  const nonEmptyLocations = routeDetails.locations?.filter((location: any) => !!location.address) || [];
  if (nonEmptyLocations.length) {
    nonEmptyLocations.unshift(nonEmptyLocations[nonEmptyLocations.length - 1]);
  }

  return (
    <Modal visible={showModal} onOk={close} destroyOnClose onCancel={close} width={800}>
      <Spin spinning={loading}>
        <Descriptions title="Route Information" bordered column={2}>
          <Descriptions.Item label="Date">{moment(routeDetails.date * 1000).format('DD/MM/YYYY')}</Descriptions.Item>
          <Descriptions.Item label="Vehicle Number">{routeDetails.vehicle_number}</Descriptions.Item>
          <Descriptions.Item label="Locations">{routeDetails.number_of_locations}</Descriptions.Item>
          <Descriptions.Item label="Estimated Distance">{routeDetails.estimated_distance} Km</Descriptions.Item>
          <Descriptions.Item label="Optimized Distance">{routeDetails.optimized_distance} Km</Descriptions.Item>
          <Descriptions.Item label="Fuel Consumption">{routeDetails.fuel_consumption} L</Descriptions.Item>
          <Descriptions.Item label="Carbon Saving">{routeDetails.carbon_savings?.toFixed(2)} Kg</Descriptions.Item>
          <Descriptions.Item label="Optimized Carbon Output">
            {routeDetails.optimized_carbon_output?.toFixed(2)} Kg
          </Descriptions.Item>
        </Descriptions>

        <div className="mg-t-20">
          <h1>Locations: </h1>
          <Timeline mode="left" className="mg-l-20 pd-5" style={{ maxHeight: 300, overflow: 'auto' }}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*  @ts-ignore */}
            {nonEmptyLocations.map((location: any, index: number) => {
              const isEnd = index === nonEmptyLocations.length - 1;
              const isStart = index === 0;
              return (
                <Timeline.Item
                  key={location.address}
                  dot={isStart || isEnd ? <CheckCircleOutlined /> : null}
                  color={isStart || isEnd ? 'green' : undefined}
                  style={{ fontWeight: isStart || isEnd ? 'bold' : 'normal' }}
                >
                  {isStart && 'Start: '}
                  {isEnd && 'End: '}
                  {location.address}
                </Timeline.Item>
              );
            })}
          </Timeline>
        </div>
      </Spin>
    </Modal>
  );
};

export default RouteDetails;
