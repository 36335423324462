import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { AimOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { showMapView } from '../store/app-ui.store';
import { useAppDispatch } from '../store/hooks';
import { clearError } from '../store/routes.store';

type MapViewAndErrorTriggerProps = {
  data: any;
  rowValues: any;
};

const MapViewAndErrorTrigger = ({ data, rowValues }: MapViewAndErrorTriggerProps) => {
  const dispatch = useAppDispatch();

  let isLocationAvailable = data && data.lng && data.lat;

  if (rowValues.type === 'parent') {
    isLocationAvailable = true;
  }
  if (rowValues.status === 'outside of country') {
    isLocationAvailable = false;
  }

  const confirm = () => {
    dispatch(clearError({ index: rowValues.key }));
  };

  return (
    <>
      <Button
        icon={<AimOutlined />}
        onClick={() => {
          const locations = [];
          if (rowValues.type === 'parent') {
            locations.push(...rowValues.children.map((c: any) => c.location).filter((c: any) => c && c.lng && c.lat));
          } else {
            locations.push(data);
          }
          dispatch(showMapView({ show: true, locations }));
        }}
        disabled={!isLocationAvailable}
      />
      {rowValues.error && (
        <>
          {isLocationAvailable ? (
            <Popconfirm
              trigger="hover"
              title={
                <div>
                  This Row has an error. <br />
                  Do you want to ignore it?
                </div>
              }
              onConfirm={confirm}
              okText="Yes"
              cancelButtonProps={{ hidden: true }}
            >
              <Button icon={<InfoCircleOutlined />} />
            </Popconfirm>
          ) : (
            <Tooltip title={rowValues.status}>
              <Button icon={<InfoCircleOutlined />} />
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

export default MapViewAndErrorTrigger;
