import React, { useEffect, useState } from 'react';
import { Modal, Switch, Table } from 'antd';
import Search from 'antd/es/input/Search';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getShowSelectVehicleModal, showSelectVehicleModal } from '../../store/app-ui.store';
import {
  fetchMinimalLicenseAndVehicle,
  getMinimalVehicles,
  getSelectedVehicles,
  setSelectedVehicle,
} from '../../store/drivers.store';

const SelectVehicleModal = () => {
  const showModal = useAppSelector(getShowSelectVehicleModal);
  const allVehicles = useAppSelector(getMinimalVehicles);
  const selectedVehicles = useAppSelector(getSelectedVehicles);
  const [filter, setFilter] = useState<string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMinimalLicenseAndVehicle(0));
  }, []);

  const handleOK = () => {
    dispatch(showSelectVehicleModal(false));
  };

  if (!showModal) {
    return <div />;
  }

  const dataSource = filter
    ? allVehicles.filter((value) => value.plateNumber && value.plateNumber.toLowerCase().includes(filter.toLowerCase()))
    : allVehicles;

  return (
    <Modal visible={showModal} title="Select Vehicles" onOk={handleOK} onCancel={handleOK} cancelText={() => null}>
      <Search value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="Search Vehicle" />
      <Table
        columns={[
          { title: 'Name', dataIndex: 'plateNumber' },
          { title: 'Capacity', dataIndex: 'vehicleCapacity' },
          {
            title: 'Select',
            dataIndex: 'select',
            render: (v, d) => (
              <Switch
                checked={!!selectedVehicles[d.plateNumber]}
                onChange={(checked) => {
                  dispatch(setSelectedVehicle({ id: d.plateNumber, selected: checked }));
                }}
              />
            ),
          },
        ]}
        dataSource={dataSource}
      />
    </Modal>
  );
};

export default SelectVehicleModal;
