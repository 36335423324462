import React, { useEffect } from 'react';
import to from 'await-to-js';
import { Button, Card, Col, Input, Row, Form } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { MailOutlined, FileProtectOutlined } from '@ant-design/icons';

import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getAuthLoading, requestLogin, requestLogout } from '../store/auth.store';

type LoginForm = {
  username: string;
  password: string;
};

const LoginPage = (): JSX.Element => {
  const [formRef] = Form.useForm();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const loading = useAppSelector(getAuthLoading);

  const onSubmit = async (values: LoginForm) => {
    const [err] = await to(dispatch(requestLogin(values)).unwrap());

    if (err) {
      if (err.message.toLowerCase().includes('too many')) {
        toast.error('Too Many Requests');
      }
      return;
    }

    window.location.assign('/dashboard');
  };

  useEffect(() => {
    if (location.state && (location.state as any).fromAPIService) {
      dispatch(requestLogout());
      setTimeout(() => {
        toast.dismiss();
      }, 100);
    }
  }, [location]);

  return (
    <Row className="h-100 login-container" justify="center" align="middle">
      <Col xs={{ span: 24 }} md={{ span: 18 }} lg={{ span: 12 }} xl={{ span: 10 }}>
        <Card title={<h1 className="ta-center mg-b-0">Welcome to Route Sonar</h1>} hoverable>
          <Form form={formRef} name="login-form" onFinish={onSubmit} initialValues={initValue}>
            <Form.Item name="username" rules={validations.username}>
              <Input placeholder="Email / Username" prefix={<MailOutlined />} />
            </Form.Item>
            <Form.Item name="password" rules={validations.password}>
              <Input.Password placeholder="Password" prefix={<FileProtectOutlined />} />
            </Form.Item>
            <div className="d-flex jc-space-between">
              <Button htmlType="submit" type="primary" loading={loading}>
                Login
              </Button>
              <Link to="/terms-of-use">Terms of Use</Link>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

const initValue: LoginForm = {
  username: '',
  password: '',
};

const validations = {
  username: [{ required: true, message: 'Please enter the email' }],
  password: [{ required: true, message: 'Please enter the password' }],
};

export default LoginPage;
