import { Card, Col, Collapse, Descriptions, Row, Statistic } from 'antd';
import React from 'react';
import AnalyticsCard from './index';

const { Panel } = Collapse;

type SummeryProp = {
  data: {
    carbonFootprint: number;
    carbon_by_vehicle: any;
    carbon_foot_print_if_unoptimized: number;
    carbon_savings: number;
    fuelConsumption: number;
    totalDistance: number;
    vehicle_composition: any;
    fuelSaving: number;
    distanceSaved: number;
  };
  locations: {
    numberOfLocations: number;
    numberOfRoutes: number;
  };
};

type DataPointProps = {
  title: string;
  value: string | number;
  color?: string;
  suffix?: string;
};

const DataPoint = ({ title, value, color = '', suffix = '' }: DataPointProps): JSX.Element => (
  <Col span={12} lg={{ span: 6 }} className="ta-center">
    <Statistic title={title} value={value} valueStyle={{ color }} suffix={suffix} />
  </Col>
);

DataPoint.defaultProps = {
  color: '',
  suffix: '',
};

const Summery = ({
  data: {
    carbonFootprint,
    carbon_by_vehicle,
    carbon_foot_print_if_unoptimized,
    carbon_savings,
    fuelConsumption,
    totalDistance,
    vehicle_composition,
    fuelSaving,
    distanceSaved,
  },
  locations: { numberOfLocations, numberOfRoutes },
}: SummeryProp): JSX.Element => {
  const avgLocPerRoute = numberOfLocations / numberOfRoutes;
  const avgDisPerRoute = totalDistance / numberOfRoutes;
  const avgDisBetweenLocs = totalDistance / numberOfLocations;

  return (
    <Card>
      <div className="d-flex jc-space-around">
        <AnalyticsCard value={`${numberOfRoutes}`} description="Routes" />
        <AnalyticsCard value={`${numberOfLocations}`} description="Locations" />
        <AnalyticsCard value={`${fuelSaving.toFixed(2)} L`} description="Fuel Saving" />
        <AnalyticsCard value={`${carbonFootprint.toFixed(2)} Kg`} description="Carbon Footprint" />
      </div>
      <Collapse className="mg-t-20" defaultActiveKey={[1, 2, 3, 4]}>
        <Panel key={1} header="Operations">
          <Row justify="center" className="mg-t-20">
            <DataPoint title="Total Routes Handled" value={numberOfRoutes} />
            <DataPoint title="Total Locations Handled" value={numberOfLocations} />
            <DataPoint title="Total Distance" suffix="KM" value={totalDistance.toFixed(2)} />
            <DataPoint title="Total Saved Distance" suffix="KM" value={distanceSaved.toFixed(2)} color="#3f8600" />
          </Row>
          <Row justify="center" className="mg-t-20">
            <DataPoint title="Fuel Usage" suffix="L" value={fuelConsumption.toFixed(2)} />
            <DataPoint title="Fuel Saving" suffix="L" value={fuelSaving.toFixed(2)} color="#3f8600" />
          </Row>
          <Row justify="center" className="mg-t-20">
            <DataPoint
              title="Avg Locations per Routes"
              value={Number.isNaN(avgLocPerRoute) ? '-' : avgLocPerRoute.toFixed(0)}
            />
            <DataPoint
              title="Avg Distance per Routes"
              suffix={Number.isNaN(avgDisPerRoute) ? '' : 'Km'}
              value={Number.isNaN(avgDisPerRoute) ? '-' : avgDisPerRoute.toFixed(2)}
            />
            <DataPoint
              title="Avg Distance between locations"
              suffix={Number.isNaN(avgDisPerRoute) ? '' : 'Km'}
              value={Number.isNaN(avgDisBetweenLocs) ? '-' : avgDisBetweenLocs.toFixed(2)}
            />
          </Row>
        </Panel>
        <Panel key={2} header="Fleet Summary">
          <Row justify="space-around">
            <Col span={10}>
              <div className="mg-t-20">
                <Descriptions title="Carbon By Vehicle" bordered column={1}>
                  <Descriptions.Item label="Two Wheel">
                    {carbon_by_vehicle.carbon_twoWheel.toFixed(2)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Three Wheel">
                    {carbon_by_vehicle.carbon_threeWheel.toFixed(2)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Four Wheel">
                    {carbon_by_vehicle.carbon_fourWheel.toFixed(2)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Four Wheel">
                    {carbon_by_vehicle.carbon_fourWheel_lorry.toFixed(2)}
                  </Descriptions.Item>
                  <Descriptions.Item label="HDV">{carbon_by_vehicle.carbon_hdv.toFixed(2)}</Descriptions.Item>
                </Descriptions>
              </div>
            </Col>
            <Col span={10}>
              <div className="mg-t-20">
                <Descriptions title="Vehicle Composition" bordered column={1}>
                  <Descriptions.Item label="Two Wheel">
                    {vehicle_composition ? vehicle_composition['2W_vehicles'] : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Three Wheel">
                    {vehicle_composition ? vehicle_composition['3W_vehicles'] : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Four Wheel">
                    {vehicle_composition ? vehicle_composition['4W_vehicles'] : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Four Wheel">
                    {vehicle_composition ? vehicle_composition['4W_lorry_vehicles'] : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="HDV">
                    {vehicle_composition ? vehicle_composition.HDV : ''}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </Col>
          </Row>
        </Panel>
        <Panel key={3} header="Sustainability Indicators">
          <Row justify="space-around" className="mg-t-20">
            <Col span={12} lg={{ span: 6 }}>
              <Statistic
                title="Unoptimized Carbon Emission"
                value={carbon_foot_print_if_unoptimized.toFixed(2)}
                suffix="KG"
                valueStyle={{ color: '#ad0000' }}
              />
            </Col>
            <Col span={12} lg={{ span: 6 }}>
              <Statistic
                title="Saved Carbon Emission"
                value={carbon_savings.toFixed(2)}
                suffix="KG"
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Card>
  );
};

export default Summery;
