import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import to from 'await-to-js';
import { toast } from 'react-toastify';
import { RootState } from './store';
import APIService from '../Utils/APIService';
import { BillHistory, BillSummary } from '../Types/BillData';

interface BillingState {
  billSummary: BillSummary;
  billSummaryLoading: boolean;
  billHistory: BillHistory[];
  billHistoryLoading: boolean;
}

const initialState = {
  billSummary: { by_location: {}, by_vehicle: {}, customer_details: {} },
  billSummaryLoading: false,
  billHistory: [],
  billHistoryLoading: false,
} as BillingState;

export const fetchBillingSummary = createAsyncThunk<any, any>(
  'billing/get-summary',
  async (arg, { rejectWithValue }) => {
    const [err, data] = await to(APIService.get('/customer/bill-summary'));
    if (err) {
      toast.error('Something went wrong!. Could not fetch the Billing Summary');
      return rejectWithValue(err);
    }
    return data?.data;
  },
);

export const fetchBillingHistory = createAsyncThunk<any, any>('billing/get-history', async (_, { rejectWithValue }) => {
  const [err, data] = await to(APIService.get('/customer/billing-history'));
  if (err) {
    toast.error('Something went wrong!. Could not fetch the Billing History');
    return rejectWithValue(err);
  }
  return data?.data;
});

export const subscribeByVehicle = createAsyncThunk<any, { vehicleAssignment: any[] | undefined }>(
  'billing/subscribe-vehicle',
  async ({ vehicleAssignment }, { rejectWithValue }) => {
    const [err, data] = await to(
      APIService.post('/customer/subscribe-vehicles', {
        vehicleAssignment,
      }),
    );
    if (err) {
      toast.error('Something went wrong!.');
      return rejectWithValue(err);
    }
    return data?.data;
  },
);

export const subscribeByLocation = createAsyncThunk<any, { numberOfLocations: number }>(
  'billing/subscribe-location',
  async ({ numberOfLocations }, { rejectWithValue }) => {
    const [err, data] = await to(
      APIService.post('/customer/subscribe-locations', {
        numberOfLocations,
      }),
    );
    if (err) {
      toast.error('Something went wrong!.');
      return rejectWithValue(err);
    }
    return data?.data;
  },
);

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBillingSummary.pending, (state) => {
      state.billSummaryLoading = true;
      state.billSummary = initialState.billSummary;
    });
    builder.addCase(fetchBillingSummary.rejected, (state) => {
      state.billSummaryLoading = false;
    });
    builder.addCase(fetchBillingSummary.fulfilled, (store, { payload }) => {
      store.billSummaryLoading = false;
      store.billSummary = payload;
    });
    builder.addCase(fetchBillingHistory.pending, (state) => {
      state.billHistoryLoading = true;
      state.billHistory = [];
    });
    builder.addCase(fetchBillingHistory.rejected, (state) => {
      state.billHistoryLoading = false;
    });
    builder.addCase(fetchBillingHistory.fulfilled, (store, { payload }) => {
      store.billHistoryLoading = false;
      store.billHistory = payload;
    });
  },
});

export const billingStore = (store: RootState): BillingState => store.billing;

export const getBillSummaryLoading = createSelector(billingStore, (store) => store.billSummaryLoading);
export const getBillHistoryLoading = createSelector(billingStore, (store) => store.billHistoryLoading);
export const getBillSummary = createSelector(billingStore, (store) => store.billSummary);
export const getBillHistory = createSelector(billingStore, (store) =>
  store.billHistory.map((a) => ({ ...a.billing, ...a.payment })),
);

export default billingSlice.reducer;
