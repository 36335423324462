import React, { useState } from 'react';
import { Button, Modal, Select } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getOptimizedVehicles, updateGroupedDraftOperations } from '../store/routes.store';

const { Option } = Select;

const VehicleSwitcher = ({ data }: { data: any }) => {
  const [show, setShow] = useState(false);
  const [selectedVehicle, setVehicle] = useState(data.plateNumber);
  const vehicles = useAppSelector(getOptimizedVehicles);

  const dispatch = useAppDispatch();

  const handleOK = () => {
    dispatch(updateGroupedDraftOperations({ index: data.key, key: 'plateNumber', data: selectedVehicle }));
    setShow(false);
  };

  return (
    <div>
      <Modal title="Select Vehicel" visible={show} onCancel={() => setShow(false)} onOk={handleOK} destroyOnClose>
        <Select value={selectedVehicle} className="w-100" onChange={(value) => setVehicle(value)}>
          {vehicles.map((a) => (
            <Option value={a} key={a}>
              {a}
            </Option>
          ))}
        </Select>
      </Modal>
      <Button icon={<SwapOutlined />} onClick={() => setShow(true)} disabled={data.isStart} />
    </div>
  );
};

export default VehicleSwitcher;
