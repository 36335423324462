import React from 'react';
import { Col, Descriptions, Row } from 'antd';
import PageLayout from '../../SharedComponents/PageLayout';

const ContactSupport = (): JSX.Element => (
  <PageLayout title="Contact Support" actions={[]}>
    <Row justify="center">
      <Col span={16} className="contact-support">
        <h1>How can we help you?</h1>
        <Descriptions title="" bordered column={1}>
          <Descriptions.Item label="Hotline">(+94) 71 824 6882</Descriptions.Item>
          <Descriptions.Item label="Email">
            <a href="mailto:hello@routesonar.com">hello@routesonar.com</a>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Useful links" bordered column={1} className="mg-t-20">
          <Descriptions.Item label="Terms of Use">
            <a target="_blank" href="https://www.routesonar.com" rel="noreferrer">
              Go to Terms Of Use
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Frequently asked questions">
            <a target="_blank" href="https://www.routesonar.com" rel="noreferrer">
              Go to FAQ&#39;s
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Watch how it works">
            <a target="_blank" href="https://www.routesonar.com" rel="noreferrer">
              Go to Video
            </a>
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  </PageLayout>
);

export default ContactSupport;
