import axios, { AxiosError, AxiosResponse } from 'axios';
import history from '../Utils/history';

const instance = axios.create({
  baseURL: 'https://api.routesonar.com/routesonar/v1/',
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token && config && config.headers) {
      config.headers['X-Auth'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const isLoginCall = error.request.responseURL.includes('Login');
    if (error.response && error.response.status === 401 && !isLoginCall) {
      history.replace('/login', { fromAPIService: true });
      throw error;
    }
    return Promise.reject(error.response?.data);
  },
);

export default instance;
