import React from 'react';
import { ToastContainer } from 'react-toastify';

import './styles/index.scss';
import AppRouter from './Pages/AppRouter';

function App(): JSX.Element {
  return (
    <div className="App">
      <AppRouter />
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
