import React from 'react';
import { Avatar, Button, Space } from 'antd';
import { LeftSquareOutlined, RightSquareOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getSidebarCollapsed, toggleSidebarCollapsed } from '../../store/app-ui.store';
import { getCustomerName, getUserLogo, getUsername, getUserType } from '../../store/auth.store';

const AuthInfo = (): JSX.Element => {
  const sidebarCollapsed = useAppSelector(getSidebarCollapsed);
  const dispatch = useAppDispatch();
  const onCollapse = () => {
    dispatch(toggleSidebarCollapsed());
  };
  const userName = useAppSelector(getUsername);
  const userLogo = useAppSelector(getUserLogo);
  let customerName = useAppSelector(getCustomerName);
  const userType = useAppSelector(getUserType);

  if (userType === 'customer') {
    customerName = 'Admin';
  }

  return (
    <div id="sidemenu-profile">
      <Button
        id="sidemenu-collapse-button"
        icon={sidebarCollapsed ? <RightSquareOutlined /> : <LeftSquareOutlined />}
        onClick={onCollapse}
      />
      <Space direction="vertical" style={{ marginTop: 48 }}>
        <Link to="/profile">
          <Space direction="vertical" align="center" className="w-100">
            {sidebarCollapsed ? (
              <>
                <Avatar size={48} icon={<UserOutlined />} src={userLogo} />
              </>
            ) : (
              <>
                <Avatar size={100} icon={<UserOutlined />} src={userLogo} />
                <h3 className="fw-600 mg-t-10 white-text ta-center">
                  {customerName} <br /> {userName}
                </h3>
              </>
            )}
          </Space>
        </Link>
      </Space>
    </div>
  );
};

export default AuthInfo;
