import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AppLayout from '../../SharedComponents/AppLayout';
import Analytics from './Analytics';
import Billing from './Billing';
import Operations from './Operations';
import RealTimeMapView from './RealTimeMapView';
import DriversPage from './DriversPage';
import FleetPage from './FleetPage';
import SideMenu from '../../SharedComponents/SideMenu/SideMenu';
import Profile from './Profile';
import UsersManagement from './UsersManagement';
import CustomersManagement from './CustomersManagement';
import Subscription from './Subscription';
import { useFeature } from '../../Utils/features';
import ContactSupport from './ContactSupport';
import OperationEditingPage from './OperationEditingPage';
import Locations from './Locations';
import OperationEditingWithVehiclePage from './OperationEditingWithVehiclePage';

const DashboardRoute = (): JSX.Element => {
  const billing = useFeature('billing_page');
  const profile = useFeature('update_profile');

  return (
    <BrowserRouter basename="/dashboard">
      <AppLayout sideMenu={<SideMenu />}>
        <Switch>
          {profile && <Route path="/profile" component={Profile} />}
          <Route path="/analytics" component={Analytics} />
          <Route path="/billing/subscriptions" component={() => (billing ? <Subscription /> : <div />)} />
          <Route path="/billing" component={() => (billing ? <Billing /> : <div />)} />
          <Route path="/operations/edit-operation" component={OperationEditingPage} />
          <Route path="/operations/edit-operation-with-grouping" component={OperationEditingWithVehiclePage} />
          <Route path="/operations" component={Operations} />
          <Route path="/operations/realtime-view" component={RealTimeMapView} />
          <Route path="/drivers" component={DriversPage} />
          <Route path="/support" component={ContactSupport} />
          <Route path="/fleets" component={FleetPage} />
          <Route path="/users-management" component={UsersManagement} />
          <Route path="/customers-management" component={CustomersManagement} />
          <Route path="/locations" component={Locations} />
          <Redirect from="/" to="/analytics" />
        </Switch>
      </AppLayout>
    </BrowserRouter>
  );
};

export default DashboardRoute;
