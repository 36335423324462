import React from 'react';
import { Button, Col, message, Modal, Row, Space, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import AnalyticsCard from '../../SharedComponents/AnalyticsCard';
import AntEditingTable from '../../SharedComponents/RSEditableTable/AntEditingTable';
import {
  getGroupedDraftOperations,
  removeGroup,
  removeGroupedDraftOperation,
  submitGroupedOperations,
  updateGroupedDraftOperations,
} from '../../store/routes.store';
import PageLayout from '../../SharedComponents/PageLayout';
import getOperationEditingColumns from '../../SharedComponents/operation-editing-columns';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showAddDraftOperationModal, showMapView } from '../../store/app-ui.store';

const OperationEditingWithVehiclePage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const data = useAppSelector(getGroupedDraftOperations);

  const submitLoading = false;
  const summary = {
    locations: data.reduce((p, c) => p + c.children.length, 0),
    capacity: data.reduce((p, c) => p + c.capacity, 0),
    totalVehicleCapacity: data.reduce((p, c) => p + c.parentCapacity, 0),
  };

  const openViewAllLocationMap = () => {
    const locations = data.reduce((p: any[], c) => {
      p.push(...c.children.map((loc) => ({ ...loc.location, mapColor: c.mapColor })));
      return p;
    }, []);
    dispatch(showMapView({ show: true, locations }));
  };
  const openAddDraftOperation = () => {
    dispatch(showAddDraftOperationModal(true));
  };

  const onSubmit = async () => {
    const [err] = await to(dispatch(submitGroupedOperations(0)));

    if (err) {
      return Modal.error({
        content: err.message,
        title: 'Something went wrong',
      });
    }
    history.push('/dashboard/operations');
    return message.success('Success');
  };

  const updateLocations = (
    key: number,
    lng: number,
    lat: number,
    locationId: string,
    name: string,
    address: string,
    clearErrors?: boolean,
  ) => {
    dispatch(
      updateGroupedDraftOperations({ index: key, key: 'location', data: { lng: Number(lng), lat: Number(lat) } }),
    );
    dispatch(updateGroupedDraftOperations({ index: key, key: 'address', data: address }));
    dispatch(updateGroupedDraftOperations({ index: key, key: 'locationId', data: locationId }));
    dispatch(updateGroupedDraftOperations({ index: key, key: 'name', data: name, clearErrors }));
  };

  return (
    <PageLayout title="Update Location Allocations for Vehicles">
      <Spin spinning={submitLoading} tip={submitLoading && 'Please wait, we are processing...'}>
        <Row>
          <div className="d-flex jc-space-around w-100 pd-b-10">
            <Col>
              <Row>
                <AnalyticsCard smaller value={`${summary.totalVehicleCapacity}`} description="Total Vehicle Capacity" />
                <AnalyticsCard smaller value={`${summary.locations}`} description="Total Locations" />
                <AnalyticsCard smaller value={`${summary.capacity}`} description="Total Capacity" />
              </Row>
            </Col>

            <Space direction="horizontal">
              <Button onClick={openViewAllLocationMap} block>
                Show All Locations
              </Button>
              <Button onClick={openAddDraftOperation}>Add Operation</Button>
              <Button type="primary" onClick={onSubmit}>
                Submit
              </Button>
            </Space>
          </div>
          <AntEditingTable
            className="grouped-table"
            rowClassName={(record) => (record.type === 'parent' ? 'vehicle' : '')}
            extraButtons={(key: string, rowData) => (
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={() => {
                  if (rowData.type === 'parent') {
                    dispatch(removeGroup(rowData.key));
                  } else {
                    dispatch(removeGroupedDraftOperation(key));
                  }
                }}
                disabled={rowData.isStart}
              />
            )}
            columns={getOperationEditingColumns(updateLocations, dispatch)}
            dataSource={data}
            scroll={{ y: 480 }}
            styles={{}}
          />
        </Row>
      </Spin>
    </PageLayout>
  );
};

export default OperationEditingWithVehiclePage;
