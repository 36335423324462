import React from 'react';
import { Input, Modal, Form, Row, Col } from 'antd';
import {
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  SafetyCertificateOutlined,
  UserOutlined,
} from '@ant-design/icons';
import to from 'await-to-js';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getShowAddNewUserModal, showAddNewUser } from '../../store/app-ui.store';
import { addNewUser } from '../../store/users.store';
import { User } from '../../Types/UsersAndCustomer';

const AddNewUser = (): JSX.Element => {
  const addNewUserModalVisibility = useAppSelector(getShowAddNewUserModal);
  const dispatch = useAppDispatch();
  const [formRef] = Form.useForm();

  const handleOk = () => {
    formRef.submit();
  };

  const handleCancel = () => {
    dispatch(showAddNewUser(false));
  };

  const handleSubmit = async (values: User) => {
    const [err] = await to(dispatch(addNewUser(values)));
    if (!err) {
      formRef.resetFields();
    }
  };

  return (
    <Modal
      visible={addNewUserModalVisibility}
      onOk={handleOk}
      title="Add New User"
      destroyOnClose
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={formRef} name="login-form" onFinish={handleSubmit} initialValues={initValue}>
        <Form.Item
          label="Username (This would be the user's login name): "
          name="username"
          rules={validations.username}
        >
          <Input placeholder="User Name" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item label="Email :" name="email" rules={validations.email}>
          <Input placeholder="sample@email.com" prefix={<MailOutlined />} />
        </Form.Item>
        <Row justify="space-between">
          <Col>
            <Form.Item label="First Name :" name="fname" rules={validations.fname}>
              <Input placeholder="First Name" prefix={<UserOutlined />} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Last Name :" name="lname" rules={validations.lname}>
              <Input placeholder="Last Name" prefix={<UserOutlined />} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <Form.Item label="Address No :" name="addressNO">
              <Input placeholder="Address Number" prefix={<IdcardOutlined />} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Street :" name="addressStreet">
              <Input placeholder="Street" prefix={<IdcardOutlined />} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="City :" name="addressTown">
          <Input placeholder="City" prefix={<IdcardOutlined />} />
        </Form.Item>

        <Form.Item label="Telephone :" name="tele" rules={validations.tele}>
          <Input placeholder="94xxxxxxxxx" prefix={<PhoneOutlined />} />
        </Form.Item>
        <Form.Item
          label="Password (This would be the user's login password):"
          name="password"
          rules={validations.password}
        >
          <Input.Password placeholder="Password" prefix={<SafetyCertificateOutlined />} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const initValue: User = {
  email: '',
  password: '',
  fname: '',
  lname: '',
  tele: '',
  addressStreet: '',
  addressTown: '',
  addressNO: '',
  username: '',
};

const validations = {
  username: [{ required: true, message: 'Please enter the Username' }],
  email: [{ required: true, message: 'Please enter the Email' }],
  password: [
    { required: true, message: 'Please enter the Password' },
    { min: 5, message: 'Password should have at least 5 chars' },
  ],
  fname: [{ required: true, message: 'Please enter the First Name' }],
  lname: [{ required: true, message: 'Please enter the Last Name' }],
  tele: [
    { required: true, message: 'Please enter the Telephone Number' },
    { pattern: /^([0-9]{1,3}|0)[0-9]{3}( )?[0-9]{7,8}\b/, message: 'Please enter valid phone number' },
  ],
};

export default AddNewUser;
