import React, { useState } from 'react';
import { Modal, Switch, Table } from 'antd';
import Search from 'antd/es/input/Search';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getShowSelectDriversModal, showSelectDriversModal } from '../../store/app-ui.store';
import { getMinimalDrivers, getSelectedDrivers, setSelectedDrivers } from '../../store/drivers.store';

const SelectDriverModal = () => {
  const showModal = useAppSelector(getShowSelectDriversModal);
  const allDrivers = useAppSelector(getMinimalDrivers);
  const selectedDrivers = useAppSelector(getSelectedDrivers);
  const [filter, setFilter] = useState<string>();
  const dispatch = useAppDispatch();

  const handleOK = () => {
    dispatch(showSelectDriversModal(false));
  };

  if (!showModal) {
    return <div />;
  }

  const dataSource = filter
    ? allDrivers.filter(
        ({ name, license_number }) =>
          (name && name.toLowerCase().includes(filter.toLowerCase())) ||
          (license_number && license_number.toLowerCase().includes(filter.toLowerCase())),
      )
    : allDrivers;

  return (
    <Modal visible={showModal} title="Select Drivers" onOk={handleOK} onCancel={handleOK} cancelText={() => null}>
      <Search value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="Search Driver" />
      <Table
        columns={[
          { title: 'Name', dataIndex: 'name' },
          { title: 'License Number', dataIndex: 'license_number' },
          {
            title: 'Select',
            dataIndex: 'select',
            render: (v, d) => (
              <Switch
                checked={!!selectedDrivers[d.license_number]}
                onChange={(checked) => {
                  dispatch(setSelectedDrivers({ id: d.license_number, selected: checked }));
                }}
              />
            ),
          },
        ]}
        dataSource={dataSource}
      />
    </Modal>
  );
};

export default SelectDriverModal;
