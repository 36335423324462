import React from 'react';
import { Space } from 'antd';

type PageLayoutProps = {
  children: React.ReactNode;
  title: string;
  actions?: React.ReactNode[];
};

const PageLayout = ({ title, actions, children }: PageLayoutProps): JSX.Element => (
  <div className="page-layout-comp h-100">
    <div className="header d-flex row jc-space-between ai-center pd-10 ">
      <div className="title">
        <h1 className="white-text">{title}</h1>
      </div>
      <div className="actions">
        <Space>{actions}</Space>
      </div>
    </div>
    <div id="rounded-corner">
      <div />
    </div>
    <div className="content pd-10">{children}</div>
  </div>
);

PageLayout.defaultProps = {
  actions: [],
};

export default PageLayout;
