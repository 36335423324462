import React from 'react';
import { Menu, Modal } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  FundProjectionScreenOutlined,
  LogoutOutlined,
  RadarChartOutlined,
  CarOutlined,
  TeamOutlined,
  UserOutlined,
  DollarOutlined,
  ContactsFilled,
  PushpinOutlined,
} from '@ant-design/icons';
import AuthInfo from './AuthInfo';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getSidebarCollapsed } from '../../store/app-ui.store';
import { requestLogout } from '../../store/auth.store';

import logo from '../../assets/images/white-logo.png';
import { useFeature } from '../../Utils/features';

const SideMenu = (): JSX.Element => {
  const location = useLocation();
  const sidebarCollapsed = useAppSelector(getSidebarCollapsed);
  const dispatch = useAppDispatch();

  const selectedPathKey = location.pathname.replace('/', '');
  const billing = useFeature('billing_page');

  const userManagement = useFeature('user_management');
  const customerManagement = useFeature('customer_management');

  const fleetManagement = useFeature('fleet_management');
  const driverManagment = useFeature('driver_management');
  const operationDashboard = useFeature('operation_dashboard');
  const locationManagement = useFeature('location_management');

  const logout = () => {
    Modal.confirm({
      icon: <LogoutOutlined />,
      title: 'Are you sure you want to logout ?',
      maskClosable: true,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        await dispatch(requestLogout());
      },
    });
  };

  return (
    <div className="d-flex f-col h-100">
      <AuthInfo />
      <Menu selectedKeys={[selectedPathKey]} mode="inline" inlineCollapsed={sidebarCollapsed} className="flex">
        <Menu.Item key="analytics" icon={<FundProjectionScreenOutlined />}>
          <Link to="/analytics">Analytics Dashboard</Link>
        </Menu.Item>
        {operationDashboard && (
          <Menu.Item key="operations" icon={<RadarChartOutlined />}>
            <Link to="/operations">Operations Dashboard</Link>
          </Menu.Item>
        )}
        {driverManagment && (
          <Menu.Item key="drivers" icon={<TeamOutlined />}>
            <Link to="/drivers">Driver Management</Link>
          </Menu.Item>
        )}
        {fleetManagement && (
          <Menu.Item key="fleets" icon={<CarOutlined />}>
            <Link to="/fleets">Fleet Management</Link>
          </Menu.Item>
        )}
        {userManagement && (
          <Menu.Item key="users-management" icon={<UserOutlined />}>
            <Link to="/users-management">User Management</Link>
          </Menu.Item>
        )}
        {customerManagement && (
          <Menu.Item key="customers-management" icon={<UserOutlined />}>
            <Link to="/customers-management">Customer Management</Link>
          </Menu.Item>
        )}
        {billing && (
          <Menu.Item key="billing" icon={<DollarOutlined />}>
            <Link to="/billing">Billing</Link>
          </Menu.Item>
        )}
        {locationManagement && (
          <Menu.Item key="locations" icon={<PushpinOutlined />}>
            <Link to="/locations">Locations</Link>
          </Menu.Item>
        )}
      </Menu>

      <Menu theme="dark" selectable={false} inlineCollapsed={sidebarCollapsed}>
        <Menu.Item key="support" icon={<ContactsFilled />} style={{ paddingLeft: '24px' }}>
          <Link to="/support">Contact Support</Link>
        </Menu.Item>
        <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout} style={{ paddingLeft: '24px' }}>
          Logout
        </Menu.Item>

        <div id={sidebarCollapsed ? 'powered-logo-collapsed' : 'powered-logo'}>
          <div>Powered by</div>
          <img alt="RouteSonar" src={logo} />
        </div>
      </Menu>
    </div>
  );
};

export default SideMenu;
