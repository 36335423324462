import React, { useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { AimOutlined, CloseOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getShowAddDraftOperationModal, showAddDraftOperationModal, showMapView } from '../../store/app-ui.store';
import DraftOperation from '../../Types/DraftOperation';
import useGooglePlaces from '../../hooks/useGooglePlaces';
import { LocationSearchById, LocationSearchByName } from '../Forms/LocationSearch';
import { addDraftOperation, addGroupedDraftOperation, getOptimizedVehicles } from '../../store/routes.store';

const { Option } = Select;

type LocationInfo = {
  name: string;
  locationId: string;
  location: any;
  address: string;
};

const DraftOperationModal = () => {
  const { options, setQuery, getPlaceDetails } = useGooglePlaces();
  const showModal = useAppSelector(getShowAddDraftOperationModal);
  const dispatch = useAppDispatch();
  const [formRef] = Form.useForm();
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationInfo, setLocation] = useState<LocationInfo | null>(null);

  const optimizedVehicles = useAppSelector(getOptimizedVehicles);

  const handleOk = () => {
    formRef.submit();
  };

  const handleClose = () => {
    dispatch(showAddDraftOperationModal(false));
  };

  const handleSubmit = (values: DraftOperation) => {
    if (values.plateNumber) {
      dispatch(addGroupedDraftOperation({ ...values, location: locationInfo!.location }));
    } else {
      dispatch(addDraftOperation({ ...values, location: locationInfo!.location }));
    }
    formRef.resetFields();
    dispatch(showAddDraftOperationModal(false));
  };

  return (
    <Modal visible={showModal} onCancel={handleClose} title="Add Operation" onOk={handleOk}>
      <Form layout="vertical" form={formRef} name="login-form" onFinish={handleSubmit} initialValues={initValues}>
        <Form.Item label="Address :" name="address">
          <Select
            showSearch
            placeholder="Address"
            onSearch={setQuery}
            options={options}
            filterOption={false}
            loading={locationLoading}
            className="w-100"
            onSelect={async (v: any, o: any) => {
              setLocationLoading(true);
              const placeDetails = await getPlaceDetails(v?.toString() || '');

              if (placeDetails?.geometry) {
                const lat = String(placeDetails.geometry.location?.lat());
                const lng = String(placeDetails.geometry.location?.lng());
                formRef.setFieldsValue({
                  address: o.label?.toString(),
                  location: { lat, lng },
                  locationId: 'N/A',
                  name: 'N/A',
                });
                setLocation({
                  locationId: 'N/A',
                  name: 'N/A',
                  location: { lat: Number(lat), lng: Number(lng) },
                  address: o.label?.toString(),
                });
              }
              setLocationLoading(false);
            }}
            allowClear
            clearIcon={<CloseOutlined />}
            onClear={() => {
              formRef.setFieldsValue({ address: '', location: { lat: null, lng: null } });
            }}
          />
        </Form.Item>
        <Row>
          <Col span={20}>
            <Form.Item label="LocationId :" name="locationId">
              <LocationSearchById
                loading={false}
                value={locationInfo?.locationId || ''}
                onChange={(locationId: string, name: string, address: string, location: any) => {
                  setLocation({ locationId, name, location, address });
                  formRef.setFieldsValue({
                    locationId,
                    name,
                    address,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2} offset={2}>
            <Form.Item label="Map">
              <Button
                icon={<AimOutlined />}
                onClick={() =>
                  dispatch(
                    showMapView({
                      show: true,
                      locations: [locationInfo?.location],
                    }),
                  )
                }
                disabled={!locationInfo?.location}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Location Name :" name="name">
          <LocationSearchByName
            loading={false}
            value={locationInfo?.locationId || ''}
            onChange={(locationId: string, name: string, address: string, location: any) => {
              setLocation({ locationId, name, location, address });
              formRef.setFieldsValue({
                locationId,
                name,
                address,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Value :" name="value">
          <Input placeholder="value" />
        </Form.Item>
        <Form.Item label="Capacity :" name="capacity">
          <Input placeholder="capacity" />
        </Form.Item>
        {optimizedVehicles.length !== 0 && (
          <Form.Item label="Vehicle :" name="plateNumber">
            <Select placeholder="Vehicle">
              {optimizedVehicles.map((a) => (
                <Option value={a} key={a}>
                  {a}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {/* <Row justify="space-between"> */}
        {/*  <Col> */}
        {/*    <Form.Item label="Min Window:" name="minWindow"> */}
        {/*      <Input placeholder="Min Window" prefix={<UserOutlined />} /> */}
        {/*    </Form.Item> */}
        {/*  </Col> */}
        {/*  <Col> */}
        {/*    <Form.Item label="Max Window:" name="maxWindow"> */}
        {/*      <Input placeholder="Max Window" prefix={<UserOutlined />} /> */}
        {/*    </Form.Item> */}
        {/*  </Col> */}
        {/* </Row> */}
      </Form>
    </Modal>
  );
};

const initValues = {
  address: '',
  capacity: '',
  minWindow: '',
  maxWindow: '',
} as DraftOperation;

export default DraftOperationModal;
