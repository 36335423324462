import React from 'react';
import { Modal } from 'antd';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getShowLocationPicker, showLocationPicker } from '../../store/app-ui.store';
import { getLocationCoords, setLocationCoords } from '../../store/location.store';
import RZMap from '../RZMap';

const LocationPicker = () => {
  const dispatch = useAppDispatch();
  const { lat, lng } = useAppSelector(getLocationCoords) || { lat: 0, lng: 0 };
  const showModal = useAppSelector(getShowLocationPicker);

  const handleClose = () => {
    dispatch(showLocationPicker({ show: false }));
  };

  const onChangeCoordsHandler = (e: any) => {
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();
    dispatch(setLocationCoords({ lat: latitude, lng: longitude }));
  };

  return (
    <Modal visible={showModal} title="Locations" onCancel={handleClose} onOk={handleClose} destroyOnClose>
      <div style={{ height: 500, width: '100%' }}>
        <div>
          <RZMap markerPositions={[{ lat, lng, draggable: true, onDragEnd: (e) => onChangeCoordsHandler(e) }]} />
        </div>
      </div>
    </Modal>
  );
};

export default LocationPicker;
