import React from 'react';
import { Spin, Typography } from 'antd';
import './style.scss';

type AnalyticsCardProps = {
  value: string | number;
  description: string;
  smaller?: boolean;
  type?: string;
  isLoading?: boolean;
};

const AnalyticsCard = ({ description, value, smaller, type, isLoading }: AnalyticsCardProps): JSX.Element => (
  <Spin spinning={isLoading}>
    <div className={`analytics-card pd-5 ${smaller ? 'smaller' : ''} ${type}`}>
      <Typography.Title className="value">{value}</Typography.Title>
      <Typography.Text className="description">{description}</Typography.Text>
    </div>
  </Spin>
);

AnalyticsCard.defaultProps = {
  smaller: false,
  type: '',
  isLoading: false,
};

export default AnalyticsCard;
