import React, { useEffect } from 'react';
import { Alert, Button, Col, Descriptions, Row, Spin } from 'antd';
import { Link } from 'react-router-dom';

import moment from 'moment';
import PageLayout from '../../SharedComponents/PageLayout';
import RSTable from '../../SharedComponents/RSTable';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  fetchBillingHistory,
  fetchBillingSummary,
  getBillHistory,
  getBillHistoryLoading,
  getBillSummary,
  getBillSummaryLoading,
} from '../../store/billing.store';
import AnalyticsCard from '../../SharedComponents/AnalyticsCard';

// type Props = {
//   data: ComponentProps<typeof PieChart>['data'];
// };

// const UsageChart = ({ data }: Props) => (
//   <PieChart
//     data={data}
//     style={{
//       fontFamily: '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
//       fontSize: '12px',
//     }}
//     radius={PieChart.defaultProps.radius - 6}
//     lineWidth={40}
//     segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
//     segmentsShift={2}
//     animate
//     label={({ dataEntry }) => dataEntry.value}
//     labelPosition={75}
//     labelStyle={{
//       fill: '#fff',
//       opacity: 0.75,
//       pointerEvents: 'none',
//     }}
//   />
// );
// const ChartView = ({ used, unUsed }: { used: number; unUsed: number }) => (
//   <Row justify="center" className="mg-t-20">
//     <Col span={4}>
//       <UsageChart
//         data={[
//           { title: 'USED', value: used, color: 'green' },
//           { title: 'Unused', value: unUsed, color: 'red' },
//         ]}
//       />
//     </Col>
//   </Row>
// );

const Billing = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchBillingHistory(0));
    dispatch(fetchBillingSummary(0));
  }, []);

  const billSummary = useAppSelector(getBillSummary);
  const isBillSummaryLoading = useAppSelector(getBillSummaryLoading);
  const billHistory = useAppSelector(getBillHistory);
  const isBillHistoryLoading = useAppSelector(getBillHistoryLoading);

  const type = billSummary.customer_details.subscription_type;

  return (
    <PageLayout
      title="Analytics Dashboard"
      actions={[
        <Link key="change plan" to="/billing/subscriptions">
          Change Plan
        </Link>,
        <Button key="make-payment">Make Payment</Button>,
      ]}
    >
      <Spin spinning={isBillSummaryLoading}>
        <Row justify="center">
          <Col span={20}>
            {type === 'by_locations' && (
              <>
                <h1>You have subscribed to a Location Based Plane</h1>
                <Row justify="center">
                  <Col>
                    <AnalyticsCard value={billSummary.by_location.locations_remaining!} description="Remaining" />
                  </Col>
                </Row>
                <Row className="mg-t-20">
                  <Descriptions bordered>
                    <Descriptions.Item label="Last Subscriptions">
                      {billSummary.by_location.last_subscription_locations}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last Subscriptions Amount">
                      Rs. {billSummary.by_location.last_subscription_amount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last Subscriptions Date">
                      {moment(billSummary.by_location.last_subscription_date_locations! * 1000).format('YYYY-MM-DD')}
                    </Descriptions.Item>
                  </Descriptions>
                </Row>
              </>
            )}
            {type === 'by_vehicles' && (
              <>
                <h1>You have subscribed to a Vehicle Based Plane</h1>
                <Row justify="center">
                  <Col>
                    <AnalyticsCard
                      value={billSummary.by_vehicle.number_of_vehicles_subscribed!}
                      description="Vehicles"
                    />
                  </Col>
                </Row>
                <Row className="mg-t-20">
                  <Descriptions bordered>
                    <Descriptions.Item label="Active Vehicle Count">
                      Rs. {billSummary.by_vehicle.number_of_active_vehicles}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last Subscriptions Amount">
                      Rs. {billSummary.by_vehicle.subscription_amount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last Subscriptions Date">
                      {moment(billSummary.by_vehicle.subscription_date! * 1000).format('YYYY-MM-DD')}
                    </Descriptions.Item>
                  </Descriptions>
                </Row>
              </>
            )}
            <div className="mg-t-20">
              {type === 'error' && <Alert message="You don't have any subscribed plan" />}
              <Row justify="center">
                <Col span={10}>
                  {billSummary.by_location.status_on_locations_remaining === 'On_credit' && (
                    <Alert message="Your subscription is on credit. please recharge" type="warning" showIcon />
                  )}
                  {billSummary.by_location.status_on_locations_remaining === 'Serivece_stopped' && (
                    <Alert message="Your Service is stopped due to due payments" type="error" showIcon />
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Spin>
      <Row>
        <RSTable
          headerTitle="Billing History"
          columns={[
            { title: 'Invoice Date', dataIndex: 'billing_date' },
            {
              title: 'Bill Amount',
              dataIndex: 'bill_amount',
              render: (amount) => <div>${amount}</div>,
            },
            {
              title: 'Details',
              dataIndex: 'amount',
              render: (amount, fullRow) => (
                <div>
                  locations: {fullRow.number_of_locations_subscribed}
                  <br />
                  vehicle: {fullRow.number_of_vehicles_subscribed}
                </div>
              ),
            },
            {
              render: () => <Button type="link">Download PDF</Button>,
            },
          ]}
          loading={isBillHistoryLoading}
          dataSource={billHistory}
        />
      </Row>
    </PageLayout>
  );
};

export default Billing;
