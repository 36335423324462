import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import to from 'await-to-js';
import { toast } from 'react-toastify';
import { RootState } from './store';
import Driver from '../Types/Driver';
import APIService from '../Utils/APIService';
import { showAddNewDriver, showEditDriver } from './app-ui.store';
import { getUserType } from './auth.store';
import { URLConfig } from '../Utils/features';
import { DEFAULT_PAGE_SIZE } from '../Utils/constants';

export interface DriverState {
  loading: boolean;
  error: boolean;
  drivers: Driver[];
  minimalDrivers: any[];
  minimalVehicles: any[];
  availableVehicles: any[];
  selectedVehicles: any;
  selectedDrivers: any;
}

const initialState = {
  drivers: [],
  loading: false,
  error: false,
  minimalDrivers: [],
  minimalVehicles: [],
  selectedVehicles: {},
  availableVehicles: [],
  selectedDrivers: {},
} as DriverState;

export const getAllDrivers = createAsyncThunk<any, { pageStart: number; limit: number }>(
  'drivers/get-all',
  async (arg, { rejectWithValue, getState }) => {
    const userType = getUserType(getState() as RootState);
    const url = URLConfig.get_drivers[userType];
    const [err, data] = await to(APIService.get(url, { params: arg }));
    if (err) {
      toast.error('Something went wrong!. Could not fetch the drivers');
      return rejectWithValue(err);
    }
    return data?.data;
  },
);

export const addNewDriver = createAsyncThunk<any, Driver>(
  'drivers/create-new',
  async (driver, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.post('/user/driver', driver));
    if (err) {
      toast.error(`${err.message || 'Something went wrong!'}. Could not create the driver account`);
      return rejectWithValue(err);
    }
    toast.success('Successfully added the driver account');
    dispatch(showAddNewDriver(false));
    dispatch(getAllDrivers({ pageStart: 0, limit: DEFAULT_PAGE_SIZE }));
    return data?.data;
  },
);

export const editDriver = createAsyncThunk<any, Driver>(
  'driver/edit',
  async (driver, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.put('/user/driver', { ...driver, licenseNo: driver.licenseNumber }));
    if (err) {
      toast.error(`${err.message || 'Something went wrong!'}. Could not update the driver account`);
      return rejectWithValue(err);
    }
    toast.success('Successfully update the driver account');
    dispatch(showEditDriver(null));
    dispatch(getAllDrivers({ pageStart: 0, limit: DEFAULT_PAGE_SIZE }));
    return data?.data;
  },
);

export const fetchMinimalLicenseAndVehicle = createAsyncThunk<any, any>(
  'driver-and-vehicles/get-minimals',
  async (_, { getState }) => {
    const userType = getUserType(getState() as RootState);
    const driverUrl = URLConfig.getMinimalDrivers[userType];
    const vehicleUrl = URLConfig.getMinimalVehicle[userType];
    const [err, data] = await to(Promise.all([APIService.get(driverUrl), APIService.get(vehicleUrl)]));
    if (err) {
      toast.error(`${err.message || 'Something went wrong!'}. Could not fetch License and Vehicle Details`);
    }
    const [drivers, vehicles] = data!;
    return {
      drivers: drivers.data,
      vehicles: vehicles.data,
    };
  },
);

export const fetchMinimalVehicle = createAsyncThunk<any, any>('driver/get-minimals', async (_, { getState }) => {
  const userType = getUserType(getState() as RootState);
  const vehicleUrl = URLConfig.getMinimalVehicle[userType];
  const [err, data] = await to(APIService.get(vehicleUrl));
  if (err) {
    toast.error(`${err.message || 'Something went wrong!'}. Could not fetch License and Vehicle Details`);
  }
  return data!.data;
});

export const updateDriverStatus = createAsyncThunk<any, { licenseNumber: string; status: string }>(
  'driver/update-status',
  async (updates, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.put('/user/driver/change-status', updates));
    if (err) {
      toast.error(`${err.message || 'Something went wrong!'}. Could not update the status`);
      return rejectWithValue(err);
    }
    toast.success('Successfully updated');
    dispatch(getAllDrivers({ pageStart: 0, limit: DEFAULT_PAGE_SIZE }));
    return data?.data;
  },
);

export const driverSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    setSelectedVehicle: (state, { payload }) => {
      state.selectedVehicles[payload.id] = !!payload.selected;
    },
    setSelectedDrivers: (state, { payload }) => {
      state.selectedDrivers[payload.id] = !!payload.selected;
    },
    clearSelectedDriversAndVehicles: (state) => {
      state.selectedVehicles = {};
      state.selectedDrivers = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDrivers.pending, (store) => {
      store.loading = true;
      store.error = false;
    });
    builder.addCase(getAllDrivers.rejected, (store) => {
      store.loading = false;
      store.error = true;
    });
    builder.addCase(getAllDrivers.fulfilled, (store, { payload }) => {
      store.loading = false;
      store.drivers = payload;
    });

    builder.addCase(fetchMinimalLicenseAndVehicle.pending, (store) => {
      store.loading = true;
    });
    builder.addCase(fetchMinimalLicenseAndVehicle.rejected, (store) => {
      store.loading = false;
    });
    builder.addCase(fetchMinimalLicenseAndVehicle.fulfilled, (store, { payload }) => {
      store.loading = false;
      store.minimalDrivers = payload.drivers;
      store.minimalVehicles = payload.vehicles;
    });
    builder.addCase(fetchMinimalVehicle.pending, (store) => {
      store.loading = true;
    });
    builder.addCase(fetchMinimalVehicle.rejected, (store) => {
      store.loading = false;
    });
    builder.addCase(fetchMinimalVehicle.fulfilled, (store, { payload }) => {
      store.loading = false;
      store.availableVehicles = payload;
    });
  },
});

export const { setSelectedVehicle, setSelectedDrivers, clearSelectedDriversAndVehicles } = driverSlice.actions;

export const driverStore = (store: RootState): DriverState => store.driver;

export const getDriverLoading = createSelector(driverStore, (store) => store.loading);
export const getDrivers = createSelector(driverStore, (store) => store.drivers);
export const getMinimalDrivers = createSelector(driverStore, (store) => store.minimalDrivers);
export const getMinimalVehicles = createSelector(driverStore, (store) => store.minimalVehicles);
export const getSelectedVehicles = createSelector(driverStore, (store) => store.selectedVehicles);
export const getSelectedDrivers = createSelector(driverStore, (store) => store.selectedDrivers);
export const getAvailableVehicles = createSelector(driverStore, (store) => store.availableVehicles);
export const getSelectedVehiclesCapacity = createSelector(
  getSelectedVehicles,
  getMinimalVehicles,
  (vehicles, avaVehicle) =>
    avaVehicle.reduce(
      (p, { vehicleCapacity, plateNumber }) => (vehicles[plateNumber] ? Number(vehicleCapacity) + p : p),
      0,
    ),
);

export default driverSlice.reducer;
