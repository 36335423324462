import React, { Dispatch, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Space, Switch, Tooltip } from 'antd';
import { EditOutlined, EyeOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import PageLayout from '../../SharedComponents/PageLayout';
import RSTable from '../../SharedComponents/RSTable';
import { DEFAULT_PAGE_SIZE } from '../../Utils/constants';
import { useAppSelector } from '../../store/hooks';
import {
  fetchLocations,
  getLocations,
  getLocationsLoading,
  getPageStart,
  setEditingLocation,
  setLocationCoords,
  setPageStart,
} from '../../store/location.store';
import { showAddNewLocationModal, showMapView } from '../../store/app-ui.store';

const getColumns = (startIndex: number, dispatch: Dispatch<any>) => [
  {
    title: 'Location ID',
    dataIndex: 'location_id',
    rowKey: 'location_id',
  },
  { title: 'Name', dataIndex: 'location_name', rowKey: 'location_name' },

  {
    title: 'Address',
    dataIndex: 'address',
    rowKey: 'address',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    rowKey: 'status',
  },
  {
    title: '',
    rowKey: 'actions',
    render: (data: any, record: any) => (
      <Space size="middle">
        <Tooltip title="View Location" placement="bottomRight">
          <Button
            icon={<EyeOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                showMapView({
                  show: true,
                  locations: [{ lng: record.longitude, lat: record.latitude }],
                }),
              );
            }}
          />
        </Tooltip>
        <Tooltip title="Edit Location" placement="bottomRight">
          <Button
            icon={<EditOutlined />}
            onClick={async (e) => {
              e.stopPropagation();
              await new Promise<void>((resolve) => {
                dispatch(setLocationCoords({ lng: record.longitude, lat: record.latitude }));
                dispatch(setEditingLocation({ ...record, locationName: record.location_name }));
                resolve();
              });

              dispatch(showAddNewLocationModal(true));
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];

const Locations = (): JSX.Element => {
  const dispatch = useDispatch();
  const locations = useAppSelector(getLocations);
  const loading = useAppSelector(getLocationsLoading);
  const pageStart = useAppSelector(getPageStart);

  const [filteredLocations, setLocations] = useState(locations);
  const [locationNameFilter, setLocationNameFilter] = useState('');
  const [activeOnly, setActiveOnly] = useState(false);

  useEffect(() => {
    setLocations(locations);
  }, [locations]);

  useEffect(() => {
    dispatch(
      fetchLocations({
        start: pageStart,
        locationName: locationNameFilter,
        limit: DEFAULT_PAGE_SIZE,
        activeOnly,
      }),
    );
  }, [pageStart, locationNameFilter, activeOnly]);

  const handleAddNew = () => {
    dispatch(showAddNewLocationModal(true));
  };

  const setPageStartValue = (value: number) => {
    dispatch(setPageStart(value));
  };

  const changePage = (increment: boolean) => {
    if (!increment) {
      setPageStartValue(pageStart > 0 ? pageStart - DEFAULT_PAGE_SIZE : 0);
    } else {
      setPageStartValue(pageStart + DEFAULT_PAGE_SIZE);
    }
  };

  const filterElements = (
    <>
      <Col span={5}>
        Active Only:
        <Switch checked={activeOnly} onChange={(e) => setActiveOnly(e)} />
      </Col>
      <Col span={5}>
        <Input.Search
          placeholder="Location Name"
          onSearch={(value) => {
            setLocationNameFilter(value);
            setPageStartValue(0);
          }}
          allowClear
        />
      </Col>
    </>
  );

  return (
    <PageLayout
      title="Locations"
      actions={[
        <Button key="add" type="primary" onClick={handleAddNew}>
          Add new
        </Button>,
      ]}
    >
      <Row>
        <RSTable
          loading={loading}
          headerTitle="Locations"
          filterElements={filterElements}
          columns={getColumns(pageStart, dispatch)}
          dataSource={filteredLocations}
          footer={(pageData) => (
            <Row justify="end">
              <Space size="small">
                <Button icon={<LeftOutlined />} onClick={() => changePage(false)} />
                <Button
                  icon={<RightOutlined />}
                  onClick={() => changePage(true)}
                  disabled={pageData.length < DEFAULT_PAGE_SIZE}
                />
              </Space>
            </Row>
          )}
          rowKey="locationId"
        />
      </Row>
    </PageLayout>
  );
};

export default Locations;
