import { createSlice, createSelector, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import to from 'await-to-js';
import { toast } from 'react-toastify';
import { RootState } from './store';
import APIService from '../Utils/APIService';
import { FeaturesType, fromFeatureMatrix, URLConfig } from '../Utils/features';
import AuthUser, { MinimalUsersInfo } from '../Types/AuthUser';

const UserLogoutAction = createAction('USER_LOGOUT');

export const LS_KEYS = {
  AUTH_TOKEN: 'authToken',
  REFRESH_TOKEN: 'refreshToken',
  AUTH_TYPE: 'authType',
  DISPLAY_NAME: 'displayName',
  LOGO: 'user-logo',
  CUSTOMER_NAME: 'customerName',
  AGREED_TOC: 'agreed-toc',
};

export interface AuthState {
  tokens: {
    authToken: string | null;
    refreshToken: string | null;
  };
  auth: {
    username: string;
    email: string;
    type: 'admin' | 'customer' | 'user';
    logo: string;
    customerName: string;
  };
  profile: AuthUser;
  loadingProfile: boolean;
  updatingProfile: boolean;
  loading: boolean;
  userAccountsForCustomer: MinimalUsersInfo[];
  userAccountsForCustomerLoading: boolean;
}

const initialState: AuthState = {
  tokens: {
    authToken: localStorage.getItem(LS_KEYS.AUTH_TOKEN),
    refreshToken: localStorage.getItem(LS_KEYS.REFRESH_TOKEN),
  },
  auth: {
    username: localStorage.getItem(LS_KEYS.DISPLAY_NAME) || '',
    email: '',
    type: localStorage.getItem(LS_KEYS.AUTH_TYPE) || 'user',
    logo: localStorage.getItem(LS_KEYS.LOGO),
    customerName: localStorage.getItem(LS_KEYS.CUSTOMER_NAME),
  },
  profile: { name: '', email: '', addressNO: '', addressStreet: '', addressTown: '', tele: '' },
  loadingProfile: false,
  updatingProfile: false,
  loading: false,
  userAccountsForCustomer: [],
  userAccountsForCustomerLoading: false,
} as AuthState;

export const requestLogin = createAsyncThunk<any, { username: string; password: string }>(
  'auth/login',
  async (params) => {
    const [err, data] = await to(APIService.post('Login', params));
    if (err) {
      toast.error(err.message);
      throw err;
    }
    return data?.data;
  },
);

export const requestLogout = createAsyncThunk<any>('auth/logout', async (_, { dispatch }) => {
  await to(
    APIService.post('logout', null, { headers: { refreshToken: localStorage.getItem(LS_KEYS.REFRESH_TOKEN) || '' } }),
  );
  // no error handling due to usage of token in header
  localStorage.removeItem(LS_KEYS.AUTH_TOKEN);
  localStorage.removeItem(LS_KEYS.REFRESH_TOKEN);
  localStorage.removeItem(LS_KEYS.AUTH_TYPE);
  localStorage.removeItem(LS_KEYS.LOGO);
  localStorage.removeItem(LS_KEYS.CUSTOMER_NAME);
  localStorage.removeItem(LS_KEYS.DISPLAY_NAME);
  localStorage.removeItem(LS_KEYS.AGREED_TOC);
  dispatch(UserLogoutAction());
  // history.replace('/login');
  window.location.assign('/login');
  return 1;
});

export const requestProfile = createAsyncThunk<AuthUser>('user/profile', async (_, { getState }) => {
  const userType = getUserType(getState() as RootState);
  const url = URLConfig.getUserProfile[userType];
  const [err, data] = await to(APIService.get(url));

  if (err) {
    toast.error(err.message);
    throw err;
  }
  return data?.data;
});

export const updateProfile = createAsyncThunk<any, AuthUser>('user/update-profile', async (user, { getState }) => {
  const userType = getUserType(getState() as RootState);
  const url = URLConfig.getUserProfile[userType];
  const [err, data] = await to(APIService.put<any, any, AuthUser>(url, user));

  if (err) {
    toast.error(err.message);
    throw err;
  }
  return data?.data;
});

export const updateImage = createAsyncThunk<any, { image: any }>(
  'user/update-image',
  async ({ image }, { rejectWithValue }) => {
    const ref = toast.loading('Uploading...');
    if (image) {
      const [err, data] = await to(APIService.put('/customer/upload-logo', { image }));
      toast.dismiss(ref);
      if (err) {
        toast.error("Something went wrong. couldn't save the image");
        return rejectWithValue({});
      }
      if (data) {
        toast.success('Updated!');
        return data?.data;
      }
      toast.error("Something went wrong. couldn't save the image, may be file is too large");
      return rejectWithValue({});
    }
    return rejectWithValue({});
  },
);

export const fetchMinimalUserAccountsForCustomer = createAsyncThunk<any, any>(
  'user/all-minimal-users',
  async (_, { getState, rejectWithValue }) => {
    const userType = getUserType(getState() as RootState);
    let err;
    let data;
    if (userType === 'customer') {
      [err, data] = await to(APIService.get('customer/user/all-users/minimal'));
    } else if (userType === 'admin') {
      [err, data] = await to(APIService.get('admin/customers/minimal'));
    }
    if (err) {
      toast.error('User Accounts are not loaded. Please try again!');
      rejectWithValue(err);
    }
    return data?.data;
  },
);

export const agreedToTermsAndConditions = createAsyncThunk<any, any>(
  'user/agree-tac',
  async (_, { rejectWithValue }) => {
    const [err] = await to(APIService.post('customer/agreed-to-terms-and-conditions', null));
    if (err) {
      toast.error('Could not update. Please retry');
      return rejectWithValue(err);
    }
    return {};
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSomething: (state) => {
      state.tokens.authToken = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestLogin.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(requestLogin.fulfilled, (state, { payload }) => {
      const { customerUsername, username, role, logo, agreedToTermsAndConditions: TOC } = payload;
      state.loading = false;
      state.tokens = {
        authToken: payload.authToken.access_token,
        refreshToken: payload.authToken.refresh_token,
      };
      state.auth.type = role;
      state.auth.username = username;
      state.auth.customerName = customerUsername;
      state.auth.logo = logo;
      localStorage.setItem(LS_KEYS.AUTH_TOKEN, payload.authToken.access_token);
      localStorage.setItem(LS_KEYS.REFRESH_TOKEN, payload.authToken.refresh_token);
      localStorage.setItem(LS_KEYS.AUTH_TYPE, role);
      localStorage.setItem(LS_KEYS.DISPLAY_NAME, username);
      localStorage.setItem(LS_KEYS.LOGO, logo);
      localStorage.setItem(LS_KEYS.CUSTOMER_NAME, customerUsername);
      if (TOC === 'Not accepted' && role === 'customer') {
        localStorage.setItem(LS_KEYS.AGREED_TOC, 'no');
      }
    });
    builder.addCase(requestProfile.pending, (state) => {
      state.loadingProfile = true;
    });
    builder.addCase(requestProfile.fulfilled, (state, { payload }) => {
      state.loadingProfile = false;
      state.profile = payload;
    });
    builder.addCase(requestProfile.rejected, (state) => {
      state.loadingProfile = false;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.updatingProfile = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.updatingProfile = false;
      state.profile = payload;
    });
    builder.addCase(updateProfile.rejected, (state) => {
      state.updatingProfile = false;
    });
    builder.addCase(fetchMinimalUserAccountsForCustomer.pending, (state) => {
      state.userAccountsForCustomerLoading = true;
    });
    builder.addCase(fetchMinimalUserAccountsForCustomer.rejected, (state) => {
      state.userAccountsForCustomerLoading = false;
    });
    builder.addCase(fetchMinimalUserAccountsForCustomer.fulfilled, (state, { payload }) => {
      state.userAccountsForCustomerLoading = false;
      state.userAccountsForCustomer = payload;
    });
    builder.addCase(updateImage.fulfilled, (store, { payload }) => {
      store.auth.logo = payload.uri;
      localStorage.setItem(LS_KEYS.LOGO, `${payload.uri}?t=${Date.now()}`);
    });
  },
});

export const { setSomething } = authSlice.actions;

export const authStore = (store: RootState): AuthState => store.auth;

export const getAuthLoading = createSelector(authStore, (auth) => auth.loading);
export const getProfileLoading = createSelector(authStore, (auth) => auth.loadingProfile);
export const isUpdatingProfile = createSelector(authStore, (auth) => auth.updatingProfile);
export const getProfile = createSelector(authStore, (auth) => auth.profile);
export const getUserType = createSelector(authStore, (auth) => auth.auth.type);
export const getUsername = createSelector(authStore, (auth) => auth.auth.username);
export const getUserLogo = createSelector(authStore, (auth) => auth.auth.logo);
export const getCustomerName = createSelector(authStore, (auth) => auth.auth.customerName);
export const isFeatureEnabled = createSelector(
  getUserType,
  (type) => (key: FeaturesType) => fromFeatureMatrix(type, key),
);
export const getUserAccountForCustomers = createSelector(authStore, (auth) => auth.userAccountsForCustomer);
export const getUserAccountForCustomersLoading = createSelector(
  authStore,
  (auth) => auth.userAccountsForCustomerLoading,
);
export default authSlice.reducer;
