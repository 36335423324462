import React from 'react';
import { Redirect, Route, Switch, Router } from 'react-router-dom';

import LoginPage from './LoginPage';
import ForgotPasswordPage from './ForgotPasswordPage';
// import AdminRouter from './admin';
import DashboardRoute from './dashboard';

import history from '../Utils/history';
import { LS_KEYS } from '../store/auth.store';

const AppRouter = (): JSX.Element => {
  const loggedLocal = Boolean(localStorage.getItem(LS_KEYS.AUTH_TOKEN));

  const loggedRouter = (
    <Router history={history}>
      <Switch>
        <Route path="/dashboard" component={DashboardRoute} />
        {/* <Route path="/admin" component={AdminRouter} /> */}
        <Redirect from="*" to="/dashboard" />
      </Switch>
    </Router>
  );

  const guestRouter = (
    <Router history={history}>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Redirect from="*" to="/login" />
      </Switch>
    </Router>
  );

  if (loggedLocal) {
    return <div className="app-container">{loggedRouter}</div>;
  }

  return <div className="app-container">{guestRouter}</div>;
};

export default AppRouter;
