import React from 'react';
import { Select } from 'antd';
import noop from 'lodash.noop';
import SearchableSelector from './SearchableSelector';

const { Option } = Select;

const MinimalDrivers = ({
  minimalDrivers,
  value,
  onChange,
}: {
  minimalDrivers: any[];
  value?: any;
  onChange?: any;
}) => (
  <SearchableSelector loading={false} placeholder="Search to Select" value={value} onChange={onChange}>
    {minimalDrivers.map(({ name, license_number }) => (
      <Option key={license_number} value={license_number}>
        {name}
      </Option>
    ))}
  </SearchableSelector>
);

MinimalDrivers.defaultProps = {
  value: null,
  onChange: noop,
};

export default MinimalDrivers;
